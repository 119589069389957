export interface RegistrationsRegisterResponse {
  data: {
    resendToken: string;
  };
}

export interface RegistrationsResendTokenResponse {
  data: {
    success: boolean;
  };
}

export interface RegistrationsVerifyResponse {
  data: {
    access_token: string;
  };
}

export interface RegistrationsVerifyChangeEmailResponse {
  data: {
    success: boolean;
  };
}

export interface RegistrationsIsVerifiedResponse {
  data: {
    success: boolean;
  };
}

// Onboarding

export enum FormTemplates {
  ROOFING = 'roofing',
  CLEANING = 'cleaning',
  GARAGE_DOOR = 'garage-door',
  HVAC = 'hvac',
  LANDSCAPING = 'landscaping',
  SOLAR = 'solar',
  LAWNCARE = 'lawncare',
  FLOOR_CLEANING = 'floor-cleaning',
  PAINTING = 'painting',
  PLUMBING = 'plumbing',
  JUNK_REMOVAL = 'junk-removal',
  LOCKSMITH = 'locksmith',
  CONCRETE = 'concrete',
  PEST_CONTROL = 'pest-control',
  POOL_SERVICES = 'pool-services',
}

export interface RegistrationsOnboardingOneResponse {
  data: {
    success: boolean;
  };
}

export interface RegistrationsOnboardingTwoResponse {
  data: {
    success: boolean;
  };
}

export interface RegistrationsOnboardingThreeResponse {
  data: {
    success: boolean;
    formSlug: string;
  };
}

export interface RegistrationsOnboardingFourResponse {
  data: {
    success: boolean;
  };
}

export interface RegistrationsOnboardingThreeRequest {
  template?: FormTemplates;
  ai?: {
    business: string;
    services: string[];
  };
}

import './index.scss';
import React from 'react';

export interface NewBadgeProps {
  className?: string;
}

function NewBadge({ className }: NewBadgeProps) {
  return (
    <div className={`new-badge-component-container ${className || ''}`}>
      New
    </div>
  );
}

export default NewBadge;

import React from 'react';
import { motion } from 'framer-motion';
import './index.scss';
import FormTemplateCard from '../../../components/FormTemplateCard';
import { useBodyHeight } from '../../../hooks/useBodyHeight';
import TapformLogoWithLabel from '../../../components/TapformLogoWithLabel';
import {
  FormTemplateScheduleLabel,
  ScheduleCallOnboardingThreeBackgroundGraphic,
} from '../../../assets/images/svg';
import { openCalendly } from '../../../constants/functions';
import { Popup } from '../../../components/Popup';
import FormTemplatePopup from '../../../components/FormTemplatePopup';
import { useUserState } from '../../../store/selectors';
import APIService from '../../../services/api/index.api';
import { message } from 'antd';
import {
  actionLoadDefaultForm,
  actionLoadOnboardingThree,
  actionStartWaitingScreen,
  actionEndWaitingScreen,
} from '../../../store/actions/user.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../../router/paths.d';
import { FormTemplates } from '../../../services/api/types/registrations.response.d';
import TemplateAICard from '../../../components/TemplateAICard';

const FORM_TEMPLATES_LIST: FormTemplates[] = [
  FormTemplates.ROOFING,
  FormTemplates.HVAC,
  FormTemplates.GARAGE_DOOR,
  FormTemplates.PAINTING,
  FormTemplates.LANDSCAPING,
  FormTemplates.FLOOR_CLEANING,
  FormTemplates.POOL_SERVICES,
  FormTemplates.CONCRETE,
  FormTemplates.SOLAR,
];

function OnboardingThreePage() {
  useBodyHeight();
  const [templateSelected, setTemplateSelected] =
    React.useState<FormTemplates | null>(null);

  const { token, userData, isLoggedIn } = useSelector(useUserState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = React.useCallback(async () => {
    if (token && templateSelected) {
      try {
        dispatch(actionStartWaitingScreen(5000));
        const res = await APIService.registrationsService.onboardingThree(
          { template: templateSelected },
          token,
        );
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
        if (res && res?.data?.success) {
          const responseForms = await APIService.formsService.getMyForms(token);
          if (responseForms.data.forms[0]) {
            dispatch(
              actionLoadDefaultForm({
                formId: responseForms.data.forms[0].id,
                form: responseForms.data.forms[0],
                availableForms: responseForms.data.forms,
              }),
            );
          }
          dispatch(actionLoadOnboardingThree());
          setTimeout(() => {
            navigate(RouterPaths.ONBOARDING_FOUR);
          }, 0);
        } else {
          dispatch(actionEndWaitingScreen());
        }
      } catch (e: any) {
        message.error(e.message);
        dispatch(actionEndWaitingScreen());
      }
    }
  }, [token, templateSelected]);

  React.useEffect(() => {
    if (isLoggedIn) {
      if (userData?.onboardingStep !== 3) {
        navigate(RouterPaths.ONBOARDING_FOUR);
      } else {
        window.scrollTo({ top: 0 });
      }
    } else {
      navigate(RouterPaths.ONBOARDING_FOUR);
    }
  }, [userData, isLoggedIn]);

  return (
    <div className="onboarding-three-page-container">
      <TapformLogoWithLabel label="Onboarding" />
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{
          y: -100,
          opacity: 0,
          transition: { duration: 0.3, ease: 'anticipate' },
        }}
        transition={{
          delay: 0.1,
          duration: 0.55,
          ease: 'anticipate',
        }}
        className="onboarding-three-page-content"
      >
        <TemplateAICard
          onClick={() => {
            navigate(RouterPaths.ONBOARDING_AI_ONE);
          }}
          motionDelay={0.25}
        />
        <span className="onboarding-three-page-content-label">
          Ready templates
        </span>
        <h1 className="onboarding-three-page-content-title">
          Choose template crafted specifically for businesses like yours
        </h1>
        <div className="onboarding-three-page-content-templates">
          {FORM_TEMPLATES_LIST.map((formTemplate, ind) => (
            <FormTemplateCard
              key={formTemplate}
              template={formTemplate}
              className="onboarding-three-page-content-templates-template"
              motionDelay={0.45 + (ind * 0.25 - ind * 0.18)}
              onClick={() => {
                setTemplateSelected(formTemplate);
              }}
            />
          ))}
        </div>
        {/* <div className="onboarding-three-page-content-schedule">
          <div className="onboarding-three-page-content-schedule-label">
            <FormTemplateScheduleLabel className="onboarding-three-page-content-schedule-label-image" />
            <span className="onboarding-three-page-content-schedule-label-text">
              Schedule a call with us
            </span>
          </div>
          <span className="onboarding-three-page-content-schedule-title">
            Can’t find your industry? <br></br>
            We’ll build a custom form just for you!
          </span>
          <button
            className="onboarding-three-page-content-schedule-button"
            onClick={openCalendly}
          >
            Schedule a call{' '}
            <span className="onboarding-three-page-content-schedule-button-icon">
              📅
            </span>
          </button>
          <ScheduleCallOnboardingThreeBackgroundGraphic className="onboarding-three-page-content-schedule-background" />
        </div> */}
      </motion.div>
      <Popup
        active={!!templateSelected}
        onExit={() => {
          setTemplateSelected(null);
        }}
      >
        <FormTemplatePopup
          template={templateSelected}
          onProceed={() => {
            setTemplateSelected(null);
            onSubmit();
          }}
          onExit={() => {
            setTemplateSelected(null);
          }}
        />
      </Popup>
    </div>
  );
}

export default OnboardingThreePage;

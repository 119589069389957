import { PopupCloseIcon } from '../../assets/images/svg';
import './index.scss';
import React from 'react';

interface PopupCloseButtonProps {
  onClick?: () => void;
  className?: string;
}

function PopupCloseButton({ onClick, className = '' }: PopupCloseButtonProps) {
  return (
    <button
      className={`popup-close-button-component-container ${className}`}
      onClick={onClick}
    >
      <PopupCloseIcon className="popup-close-button-component-icon" />
    </button>
  );
}

export default PopupCloseButton;

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { RouterPaths } from './paths.d';
import LoginPage from '../pages/Login';
import React from 'react';
import SignupPage from '../pages/SignupPage';
import LeadsPage from '../pages/Leads';
import SingleLeadPage from '../pages/SingleLead';
import SettingsPage from '../pages/Settings';
import VerifyPage from '../pages/VerifyPage';
import { useUserState } from '../store/selectors';
import { useSelector } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import OnboardingOnePage from '../pages/Onboarding/One';
import HeaderProgressBar, {
  PROGRESS_LOCATIONS_VISIBLE,
} from '../components/HeaderProgressBar';
import OnboardingTwoPage from '../pages/Onboarding/Two';
import OnboardingThreePage from '../pages/Onboarding/Three';
import OnboardingFourPage from '../pages/Onboarding/Four';
import { useDeskMobileSelector } from '../hooks/use-desk-mobile-selector';
// import UseDeskPage from '../pages/UseDeskPage';
import WelcomePage from '../pages/Welcome';
import Header, { HEADER_LOCATIONS_NOT_VISIBLE } from '../components/Header';
import WalkthroughBlur from '../components/WalkthroughBlur';
import MobileFloater, {
  MOBILE_FLOTAER_LOCATIONS_NOT_VISIBLE,
} from '../components/MobileFloater';
import VerifyChangeEmailPage from '../pages/VerifyChangeEmail';
import OnboardingAIOne from '../pages/Onboarding/AI/One';
import RequestChangesPage from '../pages/RequestChanges';
import AuthCalendly from '../pages/AuthCalendly';
import ResetPasswordRequestPage from '../pages/ResetPasswordRequest';
import ResetPasswordPage from '../pages/ResetPassword';
import HubSpotIntegrationWrapper from '../Containers/HubSpotIntegration';
import LoginGooglePage from '../pages/LoginGoogle';

function RoutesAnimated() {
  const location = useLocation();

  const { visitorType, userData, welcomeScreen, walkthrough } =
    useSelector(useUserState);
  const device = useDeskMobileSelector();

  // Manual send GTAG pageview event
  React.useEffect(() => {
    try {
      window.dataLayer.push({
        event: 'spa-page-view',
        pagePath: location.pathname,
      });
    } catch (e) {}
  }, [location]);

  return (
    <>
      <AnimatePresence mode="wait">
        {PROGRESS_LOCATIONS_VISIBLE.includes(location.pathname as any) ? (
          <HeaderProgressBar />
        ) : null}
        {HEADER_LOCATIONS_NOT_VISIBLE.includes(location.pathname as any) ||
        visitorType !== 'user' ? null : (
          <Header />
        )}
        {walkthrough !== null ? <WalkthroughBlur /> : null}
      </AnimatePresence>
      <AnimatePresence mode="wait">
        <Routes key={location.pathname} location={location}>
          <Route path={RouterPaths.LOGIN} element={<LoginPage />} />
          <Route
            path={RouterPaths.LOGIN_GOOGLE}
            element={<LoginGooglePage />}
          />
          <Route path={RouterPaths.SIGNUP} element={<SignupPage />} />
          <Route
            path={RouterPaths.RESET_PASSWORD_REQUEST}
            element={<ResetPasswordRequestPage />}
          />

          <Route
            path={RouterPaths.RESET_PASSWORD}
            element={<ResetPasswordPage />}
          />
          <Route path={RouterPaths.LEADS} element={<LeadsPage />} />
          <Route path={RouterPaths.SINGLE_LEAD} element={<SingleLeadPage />} />
          <Route path={RouterPaths.SETTINGS} element={<SettingsPage />} />
          <Route path={RouterPaths.VERIFY} element={<VerifyPage />} />
          <Route
            path={RouterPaths.REQUEST_CHANGES}
            element={<RequestChangesPage />}
          />
          <Route
            path={RouterPaths.ONBOARDING_ONE}
            element={<OnboardingOnePage />}
          />
          <Route
            path={RouterPaths.ONBOARDING_TWO}
            element={<OnboardingTwoPage />}
          />
          <Route
            path={RouterPaths.ONBOARDING_THREE}
            element={<OnboardingThreePage />}
          />
          <Route
            path={RouterPaths.ONBOARDING_AI_ONE}
            element={<OnboardingAIOne />}
          />
          <Route
            path={RouterPaths.ONBOARDING_FOUR}
            element={<OnboardingFourPage />}
          />
          <Route
            path={RouterPaths.INTEGRATION_PATHS_HUBSPOT}
            element={
              <HubSpotIntegrationWrapper>
                <WelcomePage />
              </HubSpotIntegrationWrapper>
            }
          />
          <Route path={RouterPaths.WELCOME} element={<WelcomePage />} />
          <Route
            path={RouterPaths.VERIFY_CHANGE_EMAIL}
            element={<VerifyChangeEmailPage />}
          />
          <Route path={RouterPaths.AUTH_CALENDLY} element={<AuthCalendly />} />
          <Route
            path={RouterPaths.ALL}
            element={
              welcomeScreen ? (
                <Navigate to={RouterPaths.WELCOME} />
              ) : visitorType === 'user' ? (
                <Navigate to={RouterPaths.LEADS} />
              ) : visitorType === 'verify' ? (
                <Navigate to={RouterPaths.VERIFY} />
              ) : visitorType === 'onboarding' ? (
                userData && userData.onboardingStep === 2 ? (
                  <Navigate to={RouterPaths.ONBOARDING_TWO} />
                ) : userData && userData.onboardingStep === 3 ? (
                  <Navigate to={RouterPaths.ONBOARDING_THREE} />
                ) : userData && userData.onboardingStep === 4 ? (
                  <Navigate to={RouterPaths.ONBOARDING_FOUR} />
                ) : (
                  <Navigate to={RouterPaths.ONBOARDING_ONE} />
                )
              ) : (
                <Navigate to={RouterPaths.LOGIN} />
              )
            }
          />
        </Routes>
      </AnimatePresence>
      <AnimatePresence mode="wait">
        {MOBILE_FLOTAER_LOCATIONS_NOT_VISIBLE.includes(
          location.pathname as any,
        ) ||
        window.location?.pathname?.includes('/leads/') ||
        visitorType !== 'user' ? null : (
          <MobileFloater />
        )}
      </AnimatePresence>
    </>
  );
}

export default RoutesAnimated;

import createStore from './store/store';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import reportWebVitals from './reportWebVitals';
import AppRouter from './router/router';
import './root.scss';
import './colors.scss';
import './animations.scss';
import TagManager from 'react-gtm-module';
import { GoogleOAuthProvider } from '@react-oauth/google';

declare global {
  interface Window {
    dataLayer?: any;
    FB?: any;
    grecaptcha?: any;
  }
}

TagManager.initialize({
  gtmId: 'GTM-PQKFF8R',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const { store, persistor } = createStore();

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
      >
        <AppRouter />
      </GoogleOAuthProvider>
    </PersistGate>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

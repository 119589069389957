import React from 'react';
import { motion } from 'framer-motion';
import './index.scss';
import { SIMPLE_FRAMER_TRANSITION } from '../../constants/framer';
import { useDispatch } from 'react-redux';
import {
  actionFinishWalkthrough,
  actionIncrementWalkthrough,
} from '../../store/actions/user.actions';
import { useDeskMobileSelector } from '../../hooks/use-desk-mobile-selector';

function WalkthroughBlur() {
  const dispatch = useDispatch();
  const device = useDeskMobileSelector();

  React.useEffect(() => {
    if (device === 'mobile') {
      dispatch(actionFinishWalkthrough());
    }
  }, [device]);

  return (
    <motion.div
      className="walkthrough-blur-component"
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.38 }}
      exit={{ opacity: 0 }}
      transition={SIMPLE_FRAMER_TRANSITION({ duration: 0.5 })}
      onClick={() => {
        dispatch(actionIncrementWalkthrough());
      }}
    ></motion.div>
  );
}

export default WalkthroughBlur;

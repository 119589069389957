import { ApiBaseService } from './base.api';
import {
  LeadsCreateNoteRequest,
  LeadsCreateNoteResponse,
  LeadsDeleteNoteRequest,
  LeadsDeleteNoteResponse,
  LeadsGetUserLeadResponse,
  LeadsListNotesResponse,
  LeadsMyLeadsResponse,
  LeadsRemoveNewLeadRespose,
} from './types/leads.response.d';

export class ApiLeadsService extends ApiBaseService {
  static async getMyLeads(
    token: string,
    formId: number,
    offset?: number,
  ): Promise<LeadsMyLeadsResponse> {
    const url = new URL(
      `${ApiBaseService.apiUrl}/api/leads/my-leads?formId=${formId}`,
    );
    if (offset) {
      url.searchParams.append('offset', offset.toString());
    }
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      return { data: { leads: [], total: 0 } };
    }
    return (await response.json()) as LeadsMyLeadsResponse;
  }

  static async export(token: string, formId: number, offset?: number) {
    const url = new URL(
      `${ApiBaseService.apiUrl}/api/leads/export?formId=${formId}`,
    );
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const blob = await response.blob();
    const filename = response.headers
      .get('Content-Disposition')
      ?.split(';')?.[1]
      ?.trim();

    const downloadLink = document.createElement('a');
    document.body.append(downloadLink);
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = 'tapform-leads.csv';
    downloadLink.click();
    downloadLink.remove();
  }

  static async removeNewLead(
    token: string,
    leadId: number,
    formId?: number,
  ): Promise<LeadsRemoveNewLeadRespose> {
    const url = new URL(`${ApiBaseService.apiUrl}/api/leads/remove-new-lead`);
    url.searchParams.append('leadId', leadId.toString());
    if (formId) {
      url.searchParams.append('formId', formId.toString());
    }
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      return { data: { success: false } };
    }
    return (await response.json()) as LeadsRemoveNewLeadRespose;
  }

  static async getUserLead(
    token: string,
    leadId: number,
    formId?: number,
  ): Promise<LeadsGetUserLeadResponse | null> {
    const url = new URL(`${ApiBaseService.apiUrl}/api/leads?leadId=${leadId}`);
    if (formId) {
      url.searchParams.append('formId', formId.toString());
    }
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      return null;
    }
    return (await response.json()) as LeadsGetUserLeadResponse;
  }

  static async listNotes(
    token: string,
    leadId: number,
  ): Promise<LeadsListNotesResponse | null> {
    const url = new URL(
      `${ApiBaseService.apiUrl}/api/leads/list-notes?leadId=${leadId}`,
    );
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      return null;
    }
    return (await response.json()) as LeadsListNotesResponse;
  }

  static async createNote(
    token: string,
    data: LeadsCreateNoteRequest,
  ): Promise<LeadsCreateNoteResponse | null> {
    const url = new URL(`${ApiBaseService.apiUrl}/api/leads/create-note`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (response.status !== 201) {
      return null;
    }
    return (await response.json()) as LeadsCreateNoteResponse;
  }

  static async deleteNote(
    token: string,
    data: LeadsDeleteNoteRequest,
  ): Promise<LeadsDeleteNoteResponse | null> {
    const url = new URL(`${ApiBaseService.apiUrl}/api/leads/delete-note`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (response.status !== 201) {
      return null;
    }
    return (await response.json()) as LeadsDeleteNoteResponse;
  }
}

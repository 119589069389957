import CleaningImage from '../images/cleaning.png';
import RoofingImage from '../images/roofing.png';
import GarageDoorImage from '../images/garage-door.png';
import HVACImage from '../images/hvac.png';
import LandscapingImage from '../images/landscaping.png';
import SolarImage from '../images/solar.png';
import PaintingImage from '../images/painting.png';
import FloorCleaningImage from '../images/floor-cleaning.png';
import PoolServicesImage from '../images/pool-services.png';
import ConcreteImage from '../images/concrete.png';
import { FormTemplates } from '../../../services/api/types/registrations.response.d';

export const FormTemplateData: Record<
  FormTemplates,
  { title: string; label: string; image: string }
> = {
  [FormTemplates.CLEANING]: {
    title: 'Understand better your visitor’s cleaning needs',
    label: 'cleaning',
    image: CleaningImage,
  },
  [FormTemplates.ROOFING]: {
    title: 'Understand better your visitor’s roofing project',
    label: 'roofing',
    image: RoofingImage,
  },
  [FormTemplates.GARAGE_DOOR]: {
    title: 'Understand better your visitor’s garage door project',
    label: 'garage door',
    image: GarageDoorImage,
  },
  [FormTemplates.HVAC]: {
    title: 'Understand better your visitor’s HVAC project',
    label: 'HVAC',
    image: HVACImage,
  },
  [FormTemplates.LANDSCAPING]: {
    title: 'Understand better your visitor’s landscaping project',
    label: 'landscaping',
    image: LandscapingImage,
  },
  [FormTemplates.SOLAR]: {
    title: 'Understand better your visitor’s solar project',
    label: 'solar',
    image: SolarImage,
  },
  [FormTemplates.LAWNCARE]: {
    title: 'Understand better your visitor’s lawncare project',
    label: 'lawncare',
    image: SolarImage,
  },
  [FormTemplates.FLOOR_CLEANING]: {
    title: 'Understand better your visitor’s floor cleaning project',
    label: 'floor cleaning',
    image: FloorCleaningImage,
  },
  [FormTemplates.PAINTING]: {
    title: 'Understand better your visitor’s painting project',
    label: 'painting',
    image: PaintingImage,
  },
  [FormTemplates.PLUMBING]: {
    title: 'Understand better your visitor’s plumbing project',
    label: 'plumbing',
    image: SolarImage,
  },
  [FormTemplates.JUNK_REMOVAL]: {
    title: 'Understand better your visitor’s junk removal needs',
    label: 'junk removal',
    image: SolarImage,
  },
  [FormTemplates.LOCKSMITH]: {
    title: 'Understand better your visitor’s locksmith job',
    label: 'locksmith',
    image: SolarImage,
  },
  [FormTemplates.CONCRETE]: {
    title: 'Understand better your visitor’s concrete project',
    label: 'concrete',
    image: ConcreteImage,
  },
  [FormTemplates.PEST_CONTROL]: {
    title: 'Understand better your visitor’s pest control needs',
    label: 'pest control',
    image: SolarImage,
  },
  [FormTemplates.POOL_SERVICES]: {
    title: 'Understand better your visitor’s pool service requirements',
    label: 'pool services',
    image: PoolServicesImage,
  },
};

import React from 'react';
import { motion } from 'framer-motion';
import {
  FieldValues,
  UseControllerProps,
  UseFormReturn,
  useController,
} from 'react-hook-form';
import { Tooltip, message } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { useUserState } from '../../store/selectors';
import { useSelector } from 'react-redux';
import APIService from '../../services/api/index.api';
import { ImageUploadResponse } from '../../services/api/types/images.response';
import bytes from 'bytes';
import Spinner from '../Spinner';
import { FileUploadInputIcon } from '../../assets/images/svg';
import './index.scss';

export interface InputProps<FormValues extends FieldValues>
  extends UseControllerProps<FormValues> {
  className?: string;
  style?: React.CSSProperties;
  motionDelay?: number;
  motionDuration?: number;
  defaultSize?: number;
  autoComplete?: string;
  form: UseFormReturn<FormValues>;
  onUpload?: (imageId: number) => void;
}

function FileUploadInput<FormValues extends FieldValues>(
  props: InputProps<FormValues>,
) {
  const {
    className = '',
    name,
    style,
    defaultSize = 0,
    motionDelay = 0,
    motionDuration = 0.45,
    form,
    rules = {
      required: 'Logo is required!',
    },
    onUpload,
  } = props;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [size, setSize] = React.useState<number>(defaultSize);
  const { userData, token } = useSelector(useUserState);

  const { field, fieldState } = useController({ ...props, rules });

  const status: 'no' | 'uploading' | 'done' = isLoading
    ? 'uploading'
    : !field.value
    ? 'no'
    : 'done';

  return (
    <Tooltip
      open={!!fieldState.error?.message}
      title={fieldState.error?.message || 'Invalid'}
      placement="topRight"
    >
      <motion.div
        className={`file-input-container ${className}`}
        initial={{ opacity: 0, y: '30px' }}
        animate={{ opacity: 1, y: '0%' }}
        exit={{ opacity: 0, transition: { delay: 0 } }}
        transition={{
          duration: motionDuration,
          delay: motionDelay,
          ease: 'anticipate',
        }}
        style={style}
      >
        <Dragger
          className={`file-input-holder ${status} ${
            fieldState.error?.message ? 'error' : ''
          }`}
          multiple={false}
          accept={'.jpg,.png'}
          action={APIService.imageService.createImageUploadUrl(userData?.id)}
          showUploadList={false}
          onChange={(info) => {
            const { status } = info.file;
            if (status === 'uploading') {
              form.clearErrors(name);
              setIsLoading(true);
            }
            if (status === 'done') {
              form.clearErrors(name);
              const response: ImageUploadResponse = info.file.response;
              if (form) {
                form.setValue(name, response.data.id.toString() as any);
              }
              if (onUpload) {
                onUpload(response.data.id);
              }
              setTimeout(() => {
                setSize(parseInt(response.data.size, 10));
                setIsLoading(false);
                message.success(
                  `${info.file.name} file uploaded successfully.`,
                );
              }, 1000);
            } else if (status === 'error') {
              setTimeout(() => {
                setIsLoading(false);
                message.error(`${info.file.name} file upload failed.`);
              }, 1000);
            }
          }}
          headers={{
            Authorization: `Bearer ${token as string}`,
          }}
        >
          <div className="file-input-left">
            <div className="file-input-left-status">
              {status === 'done' || status === 'no'
                ? 'Upload Logo'
                : 'Uploading...'}
            </div>
            <div className="file-input-left-size">
              {status === 'done' ? bytes(size) : 'Supports: PNG & JPG'}
            </div>
          </div>
          <div className="file-input-right">
            <div className={`file-input-right-status ${status}`}>
              {status === 'done' ? (
                <img
                  src={APIService.imageService.createImageUrl(
                    field.value as number,
                  )}
                  className="file-input-right-image"
                />
              ) : status === 'uploading' ? (
                <Spinner
                  variant="circle"
                  className="file-input-right-spinner"
                />
              ) : (
                <FileUploadInputIcon className="file-input-right-icon" />
              )}
            </div>
          </div>
        </Dragger>
      </motion.div>
    </Tooltip>
  );
}

export default FileUploadInput;

import { ApiBaseService } from './base.api';
import {
  UserRequestPasswordResetResponse,
  UserResetPasswordResponse,
  UsersChangePasswordResponse,
  UsersGetUserDetailsResponse,
  UsersSetLogoResponse,
} from './types/users.response';

export class ApiUsersService extends ApiBaseService {
  static async getUserDetails(token: string, formId?: number) {
    const url = new URL(`${ApiBaseService.apiUrl}/api/users/get-user-details`);
    if (formId) {
      url.searchParams.append('formId', formId.toString());
    }
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return (await response.json()) as UsersGetUserDetailsResponse;
  }

  static async changeUserPassword(
    token: string,
    oldPassword: string,
    newPassword: string,
    userId: number,
  ) {
    const url = new URL(`${ApiBaseService.apiUrl}/api/users/change-password`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ oldPassword, newPassword, userId }),
    });
    return (await response.json()) as UsersChangePasswordResponse;
  }

  static async setLogo(token: string, imageId: number) {
    const url = new URL(
      `${ApiBaseService.apiUrl}/api/users/set-logo?imageId=${imageId}`,
    );
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return (await response.json()) as UsersSetLogoResponse;
  }

  static async resetPasswordRequest(email: string, captcha: string) {
    const url = new URL(
      `${ApiBaseService.apiUrl}/api/users/request-password-reset`,
    );
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, captcha }),
    });
    return (await response.json()) as UserRequestPasswordResetResponse;
  }

  static async resetPassword(token: string, newPassword: string) {
    const url = new URL(`${ApiBaseService.apiUrl}/api/users/reset-password`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token, newPassword }),
    });
    return (await response.json()) as UserResetPasswordResponse;
  }
}

import { ApiBaseService } from './base.api';

export class ApiImagesService extends ApiBaseService {
  static createImageUrl(id: number): string {
    return new URL(`${this.apiUrl}/api/images/download?imageId=${id}`).href;
  }

  static createImageUploadUrl(userId?: number): string {
    return new URL(`${this.apiUrl}/api/images/upload?userId=${userId || ''}`)
      .href;
  }
}

import React from 'react';

export function useDeskMobileSelector() {
  const [state, setState] = React.useState<'mobile' | 'desk'>(
    window.innerWidth >= 1024 ? 'desk' : 'mobile',
  );

  React.useEffect(() => {
    const listener = () => {
      if (state === 'mobile' && window.innerWidth >= 1024) {
        setState('desk');
      } else if (state === 'desk' && window.innerWidth < 1024) {
        setState('mobile');
      }
    };

    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [state]);

  return state;
}

import React, { ReactElement } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import APIService from '../../services/api/index.api';
import { useSelector } from 'react-redux';
import { useUserState } from '../../store/selectors';
import { message } from 'antd';

function HubSpotIntegrationWrapper({
  children,
}: React.PropsWithChildren): ReactElement | null {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { token, visitorType, defaultForm } = useSelector(useUserState);

  React.useEffect(() => {
    if (token && defaultForm) {
      (async () => {
        const code = searchParams.get('code');
        if (code) {
          const res = await APIService.hubspotService.authorizeApp(token, {
            code,
            formId: defaultForm,
          });

          if (res.success) {
            message.success('HubSpot integration successful!');
          } else {
            message.error('HubSpot integration failed!');
          }

          navigate('/');
        }
      })();
    }
  }, [searchParams, token, defaultForm]);

  React.useEffect(() => {
    if (visitorType !== 'user') {
      navigate('/');
    }
  }, []);

  return <>children</>;
}

export default HubSpotIntegrationWrapper;

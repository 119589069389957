import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import './index.scss';
import Spinner from '../Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useUserState } from '../../store/selectors';
import { actionEndWaitingScreen } from '../../store/actions/user.actions';
import { SIMPLE_FRAMER_TRANSITION } from '../../constants/framer';

function WaitingScreen() {
  const { waitingScreen } = useSelector(useUserState);
  const dispatch = useDispatch();

  React.useEffect(() => {
    let timeout: any = null;
    if (waitingScreen !== null) {
      timeout = setTimeout(() => {
        dispatch(actionEndWaitingScreen());
      }, waitingScreen);
    }
    return () => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
    };
  }, [waitingScreen]);

  return (
    <AnimatePresence>
      {waitingScreen ? (
        <motion.div
          initial={{ x: '100%' }}
          animate={{ x: '0' }}
          exit={{ x: '-100%' }}
          transition={SIMPLE_FRAMER_TRANSITION({ duration: 0.5, delay: 0 })}
          className="waiting-screen-component-container"
        >
          <div className="waiting-screen-component-content">
            <div className="waiting-screen-component-label">Please wait</div>
            <span className="waiting-screen-component-title">
              Hold on, we are setting up your dashboard…
            </span>
            <Spinner className="waiting-screen-component-icon" />
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

export default WaitingScreen;

/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { motion } from 'framer-motion';
import './index.scss';
import FormInput from '../../components/FormInput';
import { useForm } from 'react-hook-form';
import { ResetPasswordFormValues } from './types';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../router/paths.d';
import { validateEmail } from '../../validators/index.validator';
import APIService from '../../services/api/index.api';
import { useSelector } from 'react-redux';
import { message } from 'antd';
import { useUserState } from '../../store/selectors';
import TapformLogoWithLabel from '../../components/TapformLogoWithLabel';
import Spinner from '../../components/Spinner';

function ResetPasswordRequestPage() {
  const { handleSubmit, control } = useForm<ResetPasswordFormValues>({
    defaultValues: {},
    reValidateMode: 'onChange',
  });
  const navigate = useNavigate();
  const { visitorType } = useSelector(useUserState);
  const captchaRef = React.useRef();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (visitorType !== 'guest') {
      navigate('/');
    }
  }, []);

  const onSubmit = React.useCallback(async (data: ResetPasswordFormValues) => {
    setLoading(true);
    if (!window?.grecaptcha) {
      message.error('Please refresh the page and try again');
      return;
    }
    try {
      window?.grecaptcha?.ready(function () {
        window?.grecaptcha
          ?.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
            action: 'signup',
          })
          .then(async function (token: string) {
            try {
              const res = await APIService.usersService.resetPasswordRequest(
                data.email,
                token,
              );
              // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
              if (res?.data?.success) {
                navigate('/login');
                message.success('Check your email for reset password link.');
              } else {
                const err = JSON.parse((res as any).message);
                control.setError(
                  'email',
                  { message: err },
                  { shouldFocus: true },
                );
                message.error(err);
              }
              if (captchaRef?.current) {
                (captchaRef.current as any).reset();
              }
              setLoading(false);
            } catch (e: any) {
              control.setError(
                'email',
                { message: e.message },
                { shouldFocus: true },
              );
              message.error(e.message);
              setLoading(false);
            }
          });
      });
    } catch (e: any) {
      message.error('Captcha error. Please refresh the page and try again.');
      message.error(e.message);
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    if (visitorType !== 'guest') {
      navigate('/');
    } else {
      setTimeout(() => {
        window.scrollTo({ top: 0 });
      }, 100);
    }
  }, []);

  return (
    <div className="reset-password-page-container">
      <TapformLogoWithLabel label="Reset Password" />
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{
          y: -100,
          opacity: 0,
          transition: { duration: 0.3, ease: 'anticipate' },
        }}
        transition={{
          delay: 0.2,
          duration: 0.55,
          ease: 'anticipate',
        }}
        className="reset-password-page-holder"
      >
        <h1 className="reset-password-page-holder-title">
          Let's reset your password.
        </h1>
        <p className="reset-password-page-holder-description">
          Please enter your user login email address and we will send you a link
          to your email address to reset your password.
        </p>
        <form
          className="reset-password-page-holder-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormInput
            className="reset-password-page-holder-form-input"
            control={control}
            label="Email"
            type="email"
            name="email"
            autoComplete="email"
            rules={{
              validate: (v) => validateEmail(v) || 'Must be valid email!',
              required: 'Must be valid email address!',
            }}
            motionDelay={0.35}
          />
          <div
            className="reset-password-page-holder-form-captcha"
            // initial={{ opacity: 0, y: '30px' }}
            // animate={{ opacity: 1, y: '0%' }}
            // exit={{ opacity: 0, transition: { delay: 0 } }}
            // transition={{
            //   duration: 0.45,
            //   delay: 0.55,
            //   ease: 'anticipate',
            // }}
          ></div>
          <Button
            text={
              !loading ? (
                'Reset Password'
              ) : (
                <Spinner className="reset-password-page-holder-form-submit-loader" />
              )
            }
            type="submit"
            className="reset-password-page-holder-form-submit"
            // disabled={!formState.isValid}
            disabled={loading}
          />
        </form>
        <span className="reset-password-page-holder-login-holder">
          Don&apos;t have an account?{' '}
          <span
            className="reset-password-page-holder-login-link"
            onClick={() => {
              navigate(RouterPaths.SIGNUP);
            }}
          >
            Sign Up Today!
          </span>
        </span>
      </motion.div>
    </div>
  );
}

export default ResetPasswordRequestPage;

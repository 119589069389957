import React from 'react';
import Graphic from './stats.svg';
import './index.scss';
import { FeedbackType } from '../../constants';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

export interface FeedbackStatsProps {
  numberOfLeads: number;
  numberOfCalls: number;
  onContinue: () => void;
  type: FeedbackType;
}

function FeedbackStats({
  numberOfLeads,
  numberOfCalls,
  onContinue,
  type,
}: FeedbackStatsProps) {
  const scrollableRef =
    React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const meta = React.useMemo(() => {
    switch (type) {
      case FeedbackType.FIRST_EXPERIENCE:
        return {
          title: 'Awesome news!',
          description: 'Since the last time you have received',
        };
      case FeedbackType.EVERY_DAY_USAGE:
        return {
          title: 'More than a month of success!',
          description: 'We Want to Hear About Your Journey!',
        };
      case FeedbackType.FIRST_IMPRESSION:
        return {
          title: 'Wow, What a start!',
          description: 'Since using the Tapform you have received',
        };
    }
  }, [type]);

  React.useEffect(() => {
    disablePageScroll(scrollableRef.current);
    return () => {
      enablePageScroll(scrollableRef.current);
    };
  }, []);

  return (
    <div ref={scrollableRef} style={{ overflowY: 'auto' }}>
      <img className="feedback-stats-graphic" src={Graphic} />
      <div className="feedback-stats-label">{meta.title}</div>
      <div className="feedback-stats-title">{meta.description}</div>
      <div className="feedback-stats-card-holder">
        <div className="feedback-stats-card">
          <div className="feedback-stats-card-icon">💸</div>
          <div className="feedback-stats-card-value">{numberOfLeads}</div>
          <div className="feedback-stats-card-label">New leads</div>
        </div>
        <div className="feedback-stats-card">
          <div className="feedback-stats-card-icon">☎️</div>
          <div className="feedback-stats-card-value">{numberOfCalls}</div>
          <div className="feedback-stats-card-label">Phone calls</div>
        </div>
      </div>
      <button onClick={onContinue} className="feedback-stats-continue">
        Continue
      </button>
    </div>
  );
}

export default FeedbackStats;

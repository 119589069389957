import React from 'react';
import './index.scss';

export interface MoneyAmountProps {
  currency?: 'USD';
  cents: number;
  onlyText?: boolean;
}

function MoneyAmount({
  currency = 'USD',
  cents,
  onlyText = false,
}: MoneyAmountProps) {
  const amount = React.useMemo(
    () =>
      new Intl.NumberFormat('en-us', { style: 'currency', currency }).format(
        cents / 100,
      ),
    [cents],
  );

  if (onlyText) {
    return <>{amount}</>;
  }

  return <div className="money-amount-component">{amount}</div>;
}

export default MoneyAmount;

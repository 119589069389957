import APIService from '../../services/api/index.api';
import { useUserState } from '../../store/selectors';
import { message } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import FeedbackFirstExperience from './children/FirstExperience';
import FeedbackSubmitted from './children/Submitted';
import { FeedbackType } from './constants';
import { FeedbackAnswers } from './types';
import './index.scss';
import { FeedbacksGetActiveFeedbackResponse } from '../../services/api/types/feedbacks.response';
import FeedbackFirstImpression from './children/FirstImpression';
import FeedbackEveryDayUsage from './children/EveryDayUsage';

function Feedbacks() {
  const [currentFeedback, setCurrentFeedback] =
    React.useState<FeedbacksGetActiveFeedbackResponse | null>(null);
  const { token, isLoggedIn, visitorType } = useSelector(useUserState);
  const sentOnce = React.useRef(false);
  const [submitted, setSubmitted] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      if (isLoggedIn && token && visitorType === 'user') {
        if (!sentOnce.current) {
          // Load active popup if any
          const res = await APIService.feedbacksService.getActiveFeedbacks(
            token,
          );
          if (res?.data !== null) {
            setCurrentFeedback(res);
          }
          sentOnce.current = true;
        }
      } else {
        sentOnce.current = false;
      }
    })();
  }, [token, isLoggedIn, visitorType]);

  const submitFeedback = React.useCallback(
    async (answers: FeedbackAnswers) => {
      if (token && currentFeedback) {
        try {
          const res = await APIService.feedbacksService.submitFeedback(token, {
            feedbackId: currentFeedback.data.id,
            answers,
          });
          if (res?.data?.success) {
            setSubmitted(true);
            setCurrentFeedback(null);
          } else {
            message.error('Could not submit feedback! Please try again...');
            setSubmitted(false);
            setCurrentFeedback(null);
          }
        } catch (e) {
          message.error('Could not submit feedback! Please try again...');
        }
      }
    },
    [currentFeedback, token],
  );

  const close = React.useCallback(() => {
    setCurrentFeedback(null);
    setSubmitted(false);
  }, []);

  if (
    !isLoggedIn ||
    visitorType !== 'user' ||
    (!currentFeedback && !submitted)
  ) {
    return null;
  }

  if (currentFeedback || submitted) {
    return (
      <div className="feedback-holder">
        <div className="feedback-background" />
        {submitted ? (
          <FeedbackSubmitted onClose={close} />
        ) : currentFeedback?.data.type === FeedbackType.FIRST_EXPERIENCE ? (
          <FeedbackFirstExperience
            onSubmit={submitFeedback}
            numberOfCalls={currentFeedback?.data.callsFromLastFeedback}
            numberOfLeads={currentFeedback?.data.leadsFromLastFeedback}
          />
        ) : currentFeedback?.data.type === FeedbackType.FIRST_IMPRESSION ? (
          <FeedbackFirstImpression
            onSubmit={submitFeedback}
            numberOfCalls={currentFeedback?.data.callsFromLastFeedback}
            numberOfLeads={currentFeedback?.data.leadsFromLastFeedback}
          />
        ) : currentFeedback?.data.type === FeedbackType.EVERY_DAY_USAGE ? (
          <FeedbackEveryDayUsage
            onSubmit={submitFeedback}
            numberOfCalls={currentFeedback?.data.callsFromLastFeedback}
            numberOfLeads={currentFeedback?.data.leadsFromLastFeedback}
          />
        ) : null}
      </div>
    );
  }

  return null;
}

export default Feedbacks;

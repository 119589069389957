import React from 'react';
import { motion } from 'framer-motion';
import './index.scss';
import TapformLogoWithLabel from '../../components/TapformLogoWithLabel';
import Button from '../../components/Button';
import ActionImage from './action.png';
import { ExternalLinkIcon } from '../../assets/images/svg';
import { useBodyHeight } from '../../hooks/useBodyHeight';
import TapformCodeSnippet from '../../components/TapformCodeSnippet';
import { openCalendly } from '../../constants/functions';
import { useDispatch, useSelector } from 'react-redux';
import { useUserState } from '../../store/selectors';
import { actionEndWelcomeScreen } from '../../store/actions/user.actions';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../router/paths.d';
import { TAPFORM_DEVELOPER_GUIDE_URL } from '../../constants/links';

function WelcomePage() {
  useBodyHeight();

  const { form } = useSelector(useUserState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = React.useCallback(() => {
    dispatch(actionEndWelcomeScreen());
    navigate(RouterPaths.LEADS);
  }, [dispatch]);

  React.useEffect(() => {
    if (window.innerWidth <= 1024) {
      dispatch(actionEndWelcomeScreen());
      navigate(RouterPaths.LEADS);
    }
  }, []);

  return (
    <div className="welcome-page-container">
      <TapformLogoWithLabel label="Onboarding" />
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{
          y: -100,
          opacity: 0,
          transition: { duration: 0.3, ease: 'anticipate' },
        }}
        transition={{
          delay: 0.1,
          duration: 0.55,
          ease: 'anticipate',
        }}
        className="welcome-page-content"
      >
        <div className="welcome-page-left">
          <h1 className="welcome-page-left-title">
            Everything is setup, Integrate Tapform and start collecting leads!
          </h1>
          <span className="welcome-page-left-description">
            Simplify integration with Tapform.io using our provided code. Copy
            and paste the integration code onto your website effortlessly,
            enabling seamless data collection and management.
          </span>
          <div className="welcome-page-left-faq">
            <span className="welcome-page-left-faq-item">
              <span className="welcome-page-left-faq-item-icon">📝</span>
              <span className="welcome-page-left-faq-item-text">
                How to integrate?{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={TAPFORM_DEVELOPER_GUIDE_URL}
                >
                  Check our document
                </a>
              </span>
            </span>
            <span className="welcome-page-left-faq-item">
              <span className="welcome-page-left-faq-item-icon">☎️</span>
              <span className="welcome-page-left-faq-item-text">
                Need a help with integration?{' '}
                <a onClick={openCalendly}>Schedule a call</a>
              </span>
            </span>
          </div>
          <div className="welcome-page-left-actions">
            <Button
              className="welcome-page-left-actions-submit"
              text="Continue"
              onClick={onSubmit}
            />
            <button
              className="welcome-page-left-actions-skip"
              onClick={onSubmit}
            >
              I’ll do it later
            </button>
          </div>
        </div>
        <div className="welcome-page-right">
          <div className="welcome-page-right-source">
            <TapformCodeSnippet />
          </div>
          <div className="welcome-page-right-action">
            <div className="welcome-page-right-action-left">
              <span className="welcome-page-right-action-left-title">
                Try out your form live
              </span>
              <span className="welcome-page-right-action-left-description">
                Unlock potential of your website and increase number of leads
              </span>
              <button
                className="welcome-page-right-action-left-button"
                onClick={() => {
                  window.open(
                    `${
                      process.env.REACT_APP_FORM_DOMAIN_URL ||
                      'http://localhost:3004'
                    }${form?.slug || ''}`,
                    '_blank',
                  );
                }}
              >
                Try your form
                <ExternalLinkIcon className="welcome-page-right-action-left-button-icon" />
              </button>
            </div>
            <img
              className="welcome-page-right-action-image"
              src={ActionImage}
            />
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default WelcomePage;

import React from 'react';
import { motion } from 'framer-motion';
import './index.scss';
import TapformLogoWithLabel from '../../../components/TapformLogoWithLabel';
import { useDispatch, useSelector } from 'react-redux';
import { useUserState } from '../../../store/selectors';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import { useForm } from 'react-hook-form';
import FileUploadInput from '../../../components/FileUploadInput';
import ColorInput from '../../../components/ColorInput';
import {
  PreviewFormIcon,
  PreviewInfoIcon,
  PreviewPopupIcon,
  PreviewWidgetIcon,
} from '../../../assets/images/svg';
import { FormProvider } from 'antd/es/form/context';
import APIService from '../../../services/api/index.api';
import { Tooltip, message } from 'antd';
import TapformWidget from '../../../components/TapformWidget';
import { SIMPLE_FRAMER_TRANSITION } from '../../../constants/framer';
import {
  actionLoadLogoImage,
  actionLoadOnboardingFour,
} from '../../../store/actions/user.actions';
import { useBodyHeight } from '../../../hooks/useBodyHeight';
import { RouterPaths } from '../../../router/paths.d';
import TapformPopup from '../../../components/TapformPopup';
import TapformForm from '../../../components/TapformForm';
import { InputType } from '../../../services/api/types/answers.response.d';
import BackgroundImage from '../AI/One/assets/ai-background.webp';

const DEFAULT_BRAND_COLOR = '#1a9ed9';

interface OnboardingFourFormValues {
  brandColor: string;
  logoId: string;
}

function OnboardingFourPage() {
  useBodyHeight();

  const { userData, form, token, welcomeScreen, isLoggedIn, onboardedWithAI } =
    useSelector(useUserState);
  const formMethods = useForm<OnboardingFourFormValues>();
  const itemContainerRef =
    React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const { handleSubmit, control, watch } = formMethods;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [backgroundLoaded, setBackgroundLoaded] =
    React.useState<boolean>(false);

  const formValues = watch();

  const [selectedPreview, setSelectedPreview] = React.useState<
    'widget' | 'form' | 'popup'
  >('widget');

  React.useEffect(() => {
    if (welcomeScreen) {
      navigate(RouterPaths.WELCOME);
    } else if (isLoggedIn) {
      if (userData?.onboardingStep !== 4) {
        navigate('/');
      }
    } else {
      navigate('/');
    }
  }, [userData, welcomeScreen]);

  async function onSubmit(values: OnboardingFourFormValues) {
    if (token && values.brandColor && values.logoId) {
      const brandColor = values.brandColor.slice(1);
      const logoId = parseInt(values.logoId, 10);

      const res = await APIService.registrationsService.onboardingFour(
        token,
        brandColor,
        logoId,
      );

      if (res.data.success) {
        dispatch(actionLoadOnboardingFour());
        navigate(RouterPaths.WELCOME);
      }
    }
  }

  async function skip() {
    if (token) {
      const res = await APIService.registrationsService.onboardingFour(
        token,
        DEFAULT_BRAND_COLOR.slice(1),
      );

      if (res.data.success) {
        dispatch(actionLoadOnboardingFour());
        navigate(RouterPaths.WELCOME);
      }
    }
  }

  const setLogo = React.useCallback(
    async (imageId: number) => {
      if (token && form && form.style?.logoImage) {
        try {
          await APIService.usersService.setLogo(token, imageId);
          dispatch(
            actionLoadLogoImage({
              image: {
                ...form.style?.logoImage,
                id: imageId,
                systemImage: null,
              },
            }),
          );
        } catch (e) {
          message.error('Something went wrong, please contact support!');
        }
      }
    },
    [token, form],
  );

  function getItemsLeftValue() {
    if (itemContainerRef.current) {
      const itemWidth = itemContainerRef.current.clientWidth;
      switch (selectedPreview) {
        case 'widget': {
          return 0 * itemWidth * -1;
        }
        case 'popup': {
          return 1 * itemWidth * -1;
        }
        case 'form': {
          return 2 * itemWidth * -1;
        }
      }
    }
    return 0;
  }

  const firstFormStep = form?.steps.sort((a, b) => a.order - b.order)?.[0];

  return (
    <div
      className={`onboarding-four-page-container ${
        onboardedWithAI ? 'ai-background' : ''
      }`}
    >
      {onboardedWithAI ? (
        <img
          src={BackgroundImage}
          className={`onboarding-four-page-container-ai-background-stars ${
            backgroundLoaded ? 'loaded' : ''
          }`}
          onLoad={() => {
            setBackgroundLoaded(true);
          }}
        />
      ) : null}
      <TapformLogoWithLabel
        label="Onboarding"
        color={onboardedWithAI ? 'white' : 'black'}
      />
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{
          y: -100,
          opacity: 0,
          transition: { duration: 0.3, ease: 'anticipate' },
        }}
        transition={{
          delay: 0.1,
          duration: 0.55,
          ease: 'anticipate',
        }}
        className="onboarding-four-page-content"
      >
        <div className="onboarding-four-page-content-left">
          <h1 className="onboarding-four-page-content-left-title">
            Hey, {userData?.firstName}! Give a personal touch to your widget and
            form
          </h1>
          <p className="onboarding-four-page-content-left-description">
            Make your forms and widgets reflect your brand identity. Upload your
            logo and choose your branding color to ensure a cohesive look and
            feel.
          </p>
          <FormProvider {...formMethods}>
            <form
              className="onboarding-four-page-content-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <FileUploadInput
                defaultValue={
                  form?.style?.logoImage?.id &&
                  !form?.style?.logoImage?.systemImage
                    ? form?.style?.logoImage?.id.toString()
                    : undefined
                }
                defaultSize={
                  form?.style?.logoImage?.id &&
                  !form?.style?.logoImage?.systemImage
                    ? parseInt(form.style?.logoImage.size, 10)
                    : undefined
                }
                name="logoId"
                control={control}
                form={formMethods}
                onUpload={setLogo}
                className="onboarding-four-page-content-form-input-logo"
              />
              <ColorInput
                className="onboarding-four-page-content-form-input-color"
                name="brandColor"
                control={control}
                form={formMethods}
                defaultValue={DEFAULT_BRAND_COLOR}
              />
              <div className="onboarding-four-page-content-form-actions">
                <Button
                  className="onboarding-four-page-content-form-submit"
                  type="submit"
                  text="Continue"
                />
                <button
                  className="onboarding-four-page-content-form-skip"
                  onClick={skip}
                >
                  Skip for now
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
        <div className="onboarding-four-page-content-right">
          <span className="onboarding-four-page-content-right-label">
            Preview{' '}
            <Tooltip title="This is just a preview of how Tapform elements will look on your website">
              <PreviewInfoIcon className="onboarding-four-page-content-right-label-info" />
            </Tooltip>
          </span>
          <div
            className="onboarding-four-page-content-right-items"
            ref={itemContainerRef}
          >
            <motion.div
              animate={{ x: getItemsLeftValue() }}
              transition={SIMPLE_FRAMER_TRANSITION({ duration: 0.4 })}
              className="onboarding-four-page-content-right-items-holder"
            >
              <div className="onboarding-four-page-content-right-item">
                <TapformWidget
                  label="Takes up to 2 minutes"
                  title="Get estimation for free"
                  buttonText="Start estimation"
                  brandColor={formValues.brandColor || DEFAULT_BRAND_COLOR}
                  logoId={form?.style?.logoImage?.id as number}
                />
              </div>
              <div className="onboarding-four-page-content-right-item">
                <TapformPopup
                  logoId={form?.style?.logoImage?.id as number}
                  label={'It takes up to a minute'}
                  title="Wait, You still didn’t Get Your Free Estimate?"
                  buttonText="Get free estimate"
                  brandColor={formValues.brandColor || DEFAULT_BRAND_COLOR}
                  disclaimer="To ensure you are getting the best offer, Tapform and our providers you select may need to contact you by text/call. By clicking button"
                />
              </div>
              <div className="onboarding-four-page-content-right-item">
                <TapformForm
                  logoId={form?.style?.logoImage?.id as number}
                  question={firstFormStep?.title as string}
                  inputType={
                    firstFormStep?.inputs?.find(
                      (inp) => inp.inputType !== InputType.TEXT_INPUT,
                    )?.inputType
                  }
                  inputDesignType={
                    firstFormStep?.inputs?.find(
                      (inp) => inp.inputType !== InputType.TEXT_INPUT,
                    )?.designType
                  }
                  inputViewType={
                    firstFormStep?.inputs?.find(
                      (inp) => inp.inputType !== InputType.TEXT_INPUT,
                    )?.viewType
                  }
                  answers={
                    firstFormStep?.inputs
                      ?.find((inp) => inp.inputType !== InputType.TEXT_INPUT)
                      ?.options?.map((opt) => ({
                        id: opt.id,
                        order: opt.order,
                        text: opt.value,
                        imageId: opt.image?.id,
                      })) || ([] as any[])
                  }
                  phoneTextOne={
                    form?.translations['phone-header-cta-label-1'] as string
                  }
                  phoneTextTwo={
                    form?.translations['phone-header-cta-label-2'] as string
                  }
                  buttonText={firstFormStep?.submitButtonText as string}
                  brandColor={formValues.brandColor || DEFAULT_BRAND_COLOR}
                />
              </div>
            </motion.div>
          </div>
          <div className="onboarding-four-page-content-right-nav">
            <span
              className={`onboarding-four-page-content-right-nav-item ${
                selectedPreview === 'widget' ? 'active' : ''
              }`}
              onClick={() => {
                setSelectedPreview('widget');
              }}
            >
              <PreviewWidgetIcon className="onboarding-four-page-content-right-nav-item-icon" />
              Widget
            </span>
            <span
              className={`onboarding-four-page-content-right-nav-item ${
                selectedPreview === 'popup' ? 'active' : ''
              }`}
              onClick={() => {
                setSelectedPreview('popup');
              }}
            >
              <PreviewPopupIcon className="onboarding-four-page-content-right-nav-item-icon" />
              Popup
            </span>
            <span
              className={`onboarding-four-page-content-right-nav-item ${
                selectedPreview === 'form' ? 'active' : ''
              }`}
              onClick={() => {
                setSelectedPreview('form');
              }}
            >
              <PreviewFormIcon className="onboarding-four-page-content-right-nav-item-icon" />
              Form
            </span>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default OnboardingFourPage;

import { CheckmarkIcon } from '../../assets/images/svg';
import './index.scss';
import React from 'react';

export type TextAreaPropsError = string[];

export interface TextAreaProps {
  defaultValue?: string;
  placeholder?: string;
  resize?: 'none' | 'both' | 'horizontal' | 'vertical';
  className?: string;
  name?: string;
  For?: string;
  errors?: TextAreaPropsError;
  valid?: boolean;
  onChange?: (value: string) => void;
  value?: string;
  tooltip?: string;
  autocomplete?: string;
}

function TextArea({
  defaultValue,
  placeholder,
  className = '',
  name,
  resize,
  errors = [],
  valid = false,
  value = undefined,
  tooltip = '',
  onChange,
  autocomplete,
}: TextAreaProps) {
  const isValid = valid && !errors.length;

  return (
    <div className="textarea-component-container">
      <textarea
        data-toggle="tooltip"
        data-placement="top"
        title={tooltip}
        defaultValue={value === undefined ? defaultValue || '' : undefined}
        placeholder={placeholder || 'Type here...'}
        onChange={(event) => {
          if (onChange) {
            onChange(event.target.value);
          }
        }}
        className={`textarea-component-input ${
          errors.length ? 'error' : ''
        } ${className}`}
        name={name}
        value={value}
        autoComplete={autocomplete}
        style={{ resize }}
      />
      <span
        className={`textarea-component-valid ${
          isValid ? 'active' : 'inactive'
        }`}
      >
        <CheckmarkIcon />
      </span>
    </div>
  );
}

export default TextArea;

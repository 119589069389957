import React from 'react';
import { motion, useMotionValue } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { RouterPaths } from '../../router/paths.d';
import './index.scss';
import { SIMPLE_FRAMER_TRANSITION } from '../../constants/framer';
import { useSelector } from 'react-redux';
import { useUserState } from '../../store/selectors';

export const PROGRESS_LOCATIONS_VISIBLE = [
  RouterPaths.SIGNUP,
  RouterPaths.VERIFY,
  RouterPaths.ONBOARDING_ONE,
  RouterPaths.ONBOARDING_TWO,
  RouterPaths.ONBOARDING_THREE,
  RouterPaths.ONBOARDING_AI_ONE,
  RouterPaths.ONBOARDING_FOUR,
];
const MAX_PROGRESS = 7;

function HeaderProgressBar() {
  const location = useLocation();
  const { onboardedWithAI } = useSelector(useUserState);

  const colorClass = React.useMemo(() => {
    if (location.pathname === RouterPaths.ONBOARDING_AI_ONE) {
      return 'ai';
    }
    return 'default';
  }, [location.pathname]);

  const progress = React.useMemo(() => {
    if (location.pathname === RouterPaths.ONBOARDING_AI_ONE) {
      return 5.5;
    }
    if (location.pathname === RouterPaths.ONBOARDING_FOUR) {
      return 6;
    }
    return PROGRESS_LOCATIONS_VISIBLE.indexOf(location.pathname as any) + 1;
  }, [location.pathname]);

  return (
    <motion.div
      initial={{ y: -5 }}
      animate={{ y: 0 }}
      exit={{ y: -5 }}
      style={{
        marginTop:
          location.pathname === RouterPaths.ONBOARDING_AI_ONE && onboardedWithAI
            ? -5
            : 0,
      }}
      transition={SIMPLE_FRAMER_TRANSITION({})}
      className={`header-progress-bar-container ${colorClass}`}
    >
      <span
        className={`header-progress-bar-progress ${colorClass}`}
        style={{ maxWidth: `${(progress / MAX_PROGRESS) * 100}%` }}
      />
    </motion.div>
  );
}

export default HeaderProgressBar;

import React from 'react';
import { motion } from 'framer-motion';
import './index.scss';
import FormInput from '../../components/FormInput';
import Button from '../../components/Button';
import { useForm } from 'react-hook-form';
import APIService from '../../services/api/index.api';
import { useDispatch, useSelector } from 'react-redux';
import { useUserState } from '../../store/selectors';
import { RouterPaths } from '../../router/paths.d';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ActionVerifyChangeEmail } from '../../store/actions/user.actions';
import TapformLogoWithLabel from '../../components/TapformLogoWithLabel';
import { validateEmail } from '../../validators/index.validator';

interface VerifyChangeEmailFormValues {
  email: string;
}

function VerifyChangeEmailPage() {
  const { handleSubmit, control } = useForm<VerifyChangeEmailFormValues>({
    defaultValues: {},
    reValidateMode: 'onChange',
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { visitorType, verify } = useSelector(useUserState);

  const onSubmit = React.useCallback(
    async (data: VerifyChangeEmailFormValues) => {
      if (verify?.resendToken) {
        try {
          const res = await APIService.registrationsService.verifyChangeEmail(
            data.email,
            verify.resendToken,
          );
          if (res?.data?.success) {
            dispatch(ActionVerifyChangeEmail({ email: data.email }));
          } else {
            message.error('Something went wrong! Please contact support!');
          }
          navigate(RouterPaths.VERIFY);
        } catch (e: any) {
          message.error(e.message);
        }
      }
    },
    [verify],
  );

  React.useEffect(() => {
    if (visitorType !== 'verify') {
      navigate(RouterPaths.LOGIN);
    }
  }, [visitorType]);

  return (
    <div className="verify-change-email-page-container">
      <TapformLogoWithLabel label="Onboarding" />
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{
          y: -100,
          opacity: 0,
          transition: { duration: 0.3, ease: 'anticipate' },
        }}
        transition={{
          delay: 0.1,
          duration: 0.55,
          ease: 'anticipate',
        }}
        className="verify-change-email-page-content"
      >
        <h1 className="verify-change-email-page-content-title">Change email</h1>
        <p className="verify-change-email-page-content-description">
          Here you can change email address used for your Tapform registration.
          Once you submit, we will send you new verify email to your new desired
          email address.
        </p>
        <form
          className="verify-change-email-page-content-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormInput
            control={control}
            defaultFocused
            type="email"
            name="email"
            label="Email"
            autoComplete="email"
            rules={{
              validate: (v) => validateEmail(v) || 'Must be valid email!',
              required: 'Must be valid email address!',
            }}
            className="verify-change-email-page-content-form-input"
            motionDelay={0.35}
          />
          <Button
            type="submit"
            text="Change Email"
            className="verify-change-email-page-content-form-submit"
          />
          <button
            className="verify-change-email-page-content-close"
            onClick={() => {
              navigate(RouterPaths.VERIFY);
            }}
          >
            Go back
          </button>
        </form>
      </motion.div>
    </div>
  );
}

export default VerifyChangeEmailPage;

import React from 'react';
import { FeedbackAnswers } from '../../types';
import './index.scss';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

export interface FeedbackStepProps {
  label: string;
  title: string;
  items: {
    icon?: string;
    text: string;
  }[];
  notes?: {
    question: string;
    tag: string;
  };
  type: 'single-select' | 'card';
  onContinue: (answers: FeedbackAnswers) => void;
}

function FeedbackStep({
  label,
  title,
  items,
  notes,
  type,
  onContinue,
}: FeedbackStepProps) {
  const [selectedItem, setSelectedItem] = React.useState<number | null>(null);
  const [note, setNote] = React.useState<string | null>(null);
  const scrollableRef =
    React.useRef() as React.MutableRefObject<HTMLDivElement>;

  React.useEffect(() => {
    disablePageScroll(scrollableRef.current);
    return () => {
      enablePageScroll(scrollableRef.current);
    };
  }, []);

  return (
    <div className="feedback-step-holder" ref={scrollableRef}>
      <div className="feedback-step-label">{label}</div>
      <div className="feedback-step-title">{title}</div>
      <div className={`feedback-step-answers ${type}`}>
        {items.map((item, index) => (
          <div
            key={`${item.text}_${index}`}
            className={`feedback-step-answers-item ${type} ${
              selectedItem === index ? 'active' : ''
            }`}
            onClick={() => {
              setSelectedItem(index);
            }}
          >
            {item.icon ? (
              <div className="feedback-step-answers-item-icon">{item.icon}</div>
            ) : null}
            <div className={`feedback-step-answers-item-text ${type}`}>
              {item.text}
            </div>
          </div>
        ))}
      </div>
      {notes ? (
        <div className="feedback-step-note">
          <div className="feedback-step-note-title">{notes.question}</div>
          <textarea
            value={note || ''}
            className="feedback-step-note-input"
            placeholder="Enter here"
            onChange={(e: any) => {
              if (e.target.value.length < 511) {
                setNote(e.target.value);
              }
            }}
          />
        </div>
      ) : null}
      <button
        disabled={selectedItem === null}
        className={`feedback-step-submit ${
          selectedItem !== null ? 'active' : 'disabled'
        }`}
        onClick={() => {
          if (selectedItem !== null && items[selectedItem]) {
            const answersNew: FeedbackAnswers = [
              { question: title, answer: items[selectedItem].text },
            ];
            if (note && notes) {
              answersNew.push({
                question: notes.tag,
                answer: note,
              });
            }
            setSelectedItem(null);
            setNote(null);
            onContinue(answersNew);
          }
        }}
      >
        Continue
      </button>
    </div>
  );
}

export default FeedbackStep;

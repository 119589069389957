import React from 'react';
import Graphic from './submit.svg';
import './index.scss';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

export interface FeedbackSubmittedProps {
  onClose: () => void;
}

function FeedbackSubmitted({ onClose }: FeedbackSubmittedProps) {
  const scrollableRef =
    React.useRef() as React.MutableRefObject<HTMLDivElement>;
  React.useEffect(() => {
    disablePageScroll(scrollableRef.current);
    return () => {
      enablePageScroll(scrollableRef.current);
    };
  }, []);
  return (
    <div className="feedback-submitted-holder" ref={scrollableRef}>
      <img className="feedback-submitted-graphic" src={Graphic} />
      <div className="feedback-submitted-label">Thank you!</div>
      <div className="feedback-submitted-title">
        By providing feedback you help us create best experience possible.
      </div>
      <button onClick={onClose} className="feedback-submitted-close">
        Close
      </button>
    </div>
  );
}

export default FeedbackSubmitted;

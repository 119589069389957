/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import PopupCloseButton from '../PopupCloseButton';
import { FormTemplatePopupData } from './constants';
import React from 'react';
import './index.scss';
import { ArrowRight } from '../../assets/images/svg';
import { openCalendly } from '../../constants/functions';
import ArrowButton from '../ArrowButton';
import { motion } from 'framer-motion';
import { SIMPLE_FRAMER_TRANSITION } from '../../constants/framer';
import { FormTemplates } from '../../services/api/types/registrations.response.d';

export interface FormTemplatePopupProps {
  template?: FormTemplates | null;
  onExit?: () => void;
  onProceed?: () => void;
}

function FormTemplatePopup({
  template,
  onExit,
  onProceed,
}: FormTemplatePopupProps) {
  const formData = React.useMemo(() => {
    if (template) {
      return FormTemplatePopupData[template];
    }
    return FormTemplatePopupData[
      Object.keys(FormTemplatePopupData)[0] as FormTemplates
    ];
  }, []);

  const [ONE_CAROUSEL_WIDTH, setCarouselWidth] = React.useState(
    Math.min(window.innerWidth - 45 + 25, 432),
  );

  const [carousel, setCarousel] = React.useState<{
    index: number;
    left: number;
  }>({ left: 0, index: 0 });

  const carouselLeft = React.useCallback(() => {
    if (carousel.index > 0) {
      const newInd = carousel.index - 1;
      setCarousel({
        index: newInd,
        left: newInd * ONE_CAROUSEL_WIDTH,
      });
    }
  }, [carousel, formData, ONE_CAROUSEL_WIDTH]);

  const carouselRight = React.useCallback(() => {
    const newInd = carousel.index + 1;
    if (newInd < formData.images.length) {
      setCarousel({
        index: newInd,
        left: newInd * ONE_CAROUSEL_WIDTH,
      });
    }
  }, [carousel, formData]);

  const carouselSet = React.useCallback(
    (ind: number) => {
      if (carousel.index !== ind) {
        setCarousel({
          index: ind,
          left: ind * ONE_CAROUSEL_WIDTH,
        });
      }
    },
    [carousel, ONE_CAROUSEL_WIDTH],
  );

  React.useEffect(() => {
    const onResize = () => {
      const width = Math.min(window.innerWidth - 45 + 25, 432);
      setCarouselWidth(width);
      setCarousel({
        index: carousel.index,
        left: carousel.index * width,
      });
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <div className="form-template-popup-component-container">
      <div className="form-template-popup-component-left">
        <div className="form-template-popup-component-left-top">
          <div className="form-template-popup-component-images-slider">
            <motion.div
              animate={{
                x: carousel.left * -1,
                transition: SIMPLE_FRAMER_TRANSITION({}),
              }}
              className="form-template-popup-component-images-slider-items"
            >
              {formData.images.map((fdi, ind) => (
                <div
                  key={`img_${ind}`}
                  className="form-template-popup-component-images-slider-item"
                >
                  <img
                    className="form-template-popup-component-images-slider-item-image"
                    src={fdi}
                    onLoad={(e) => (e.target as any).classList.add('loaded')}
                  />
                </div>
              ))}
            </motion.div>
          </div>
          <div className="form-template-popup-component-images-nav">
            {formData.images.map((fdi, ind) => (
              <span
                key={`nav_${ind}`}
                className={`form-template-popup-component-images-nav-item ${
                  ind === carousel.index ? 'active' : ''
                }`}
                onClick={() => {
                  carouselSet(ind);
                }}
              />
            ))}
          </div>
          <ArrowButton
            className="form-template-popup-component-images-arrow-left"
            type="left"
            onClick={carouselLeft}
            disabled={carousel.index === 0}
          />
          <ArrowButton
            className="form-template-popup-component-images-arrow-right"
            type="right"
            onClick={carouselRight}
            disabled={carousel.index === formData.images.length - 1}
          />
        </div>
        <div className="form-template-popup-component-left-bottom">
          <div className="form-template-popup-component-left-bottom-disclaimer">
            <span className="form-template-popup-component-left-bottom-disclaimer-icon">
              📱
            </span>
            <span className="form-template-popup-component-left-bottom-disclaimer-text">
              Do you need a custom form or just make a few changes?{' '}
              <span onClick={openCalendly}>Schedule a call with us.</span>
            </span>
          </div>
        </div>
      </div>
      <div className="form-template-popup-component-right">
        <h1 className="form-template-popup-component-right-title">
          {formData.title}
        </h1>
        <p className="form-template-popup-component-right-description">
          {formData.description}
        </p>
        <span className="form-template-popup-component-right-includes-title">
          Template includes:
        </span>
        <div className="form-template-popup-component-right-includes-items">
          {formData.includes.map((include) => (
            <div
              key={`include_${include.text}`}
              className="form-template-popup-component-right-includes-item"
            >
              {include.icon({
                className:
                  'form-template-popup-component-right-includes-item-icon',
              })}
              {include.text}
            </div>
          ))}
        </div>
        <button
          className="form-template-popup-component-right-submit"
          onClick={onProceed}
        >
          Start with this template{' '}
          <ArrowRight className="form-template-popup-component-right-submit-icon" />
        </button>
      </div>
      <PopupCloseButton
        className="form-template-popup-component-close"
        onClick={onExit}
      />
    </div>
  );
}

export default FormTemplatePopup;

import React from 'react';
import { motion } from 'framer-motion';
import './index.scss';
import FormInput from '../../components/FormInput';
import { useForm } from 'react-hook-form';
import { SignupFormValues } from './types';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../router/paths.d';
import {
  validateEmail,
  validatePassword,
} from '../../validators/index.validator';
import APIService from '../../services/api/index.api';
import { useDispatch, useSelector } from 'react-redux';
import { actionRegisterLogin } from '../../store/actions/user.actions';
import { message } from 'antd';
import { useUserState } from '../../store/selectors';
import TapformLogoWithLabel from '../../components/TapformLogoWithLabel';
import {
  OnboardingFeatureOne,
  OnboardingFeatureThree,
  OnboardingFeatureTwo,
  TapformLogo,
} from '../../assets/images/svg';
import Spinner from '../../components/Spinner';
import OAuthLoginButton from '../../components/OAuthLoginButton';
import { AuthType } from '../../constants/auth';

function SignupPage() {
  const { handleSubmit, control } = useForm<SignupFormValues>({
    defaultValues: {},
    reValidateMode: 'onChange',
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { visitorType } = useSelector(useUserState);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (visitorType !== 'guest') {
      navigate('/');
    } else {
      setTimeout(() => {
        window.scrollTo({ top: 0 });
      }, 100);
    }
  }, []);

  const onSubmit = React.useCallback(async (data: SignupFormValues) => {
    setLoading(true);
    if (!window?.grecaptcha) {
      message.error('Please refresh the page and try again');
      return;
    }
    try {
      window?.grecaptcha?.ready(function () {
        window?.grecaptcha
          ?.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
            action: 'signup',
          })
          .then(async function (token: string) {
            try {
              const res = await APIService.registrationsService.sendRegister(
                data.email,
                data.password,
                token,
              );
              // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
              if (res && res?.data?.resendToken) {
                dispatch(
                  actionRegisterLogin({
                    resendToken: res.data.resendToken,
                    email: data.email,
                  }),
                );
                try {
                  window.dataLayer?.push({ event: 'create-account' });
                } catch (e) {}
                navigate(RouterPaths.VERIFY);
              }
              setLoading(false);
            } catch (e: any) {
              control.setError(
                'email',
                { message: e.message },
                { shouldFocus: true },
              );
              message.error(e.message);
              setLoading(false);
            }
          });
      });
    } catch (e: any) {
      message.error('Captcha error. Please refresh the page and try again.');
      setLoading(false);
    }
  }, []);

  return (
    <motion.div
      className="signup-page-container"
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{
        y: -100,
        opacity: 0,
        transition: { duration: 0.3, ease: 'anticipate' },
      }}
      transition={{
        delay: 0,
        duration: 0.55,
        ease: 'anticipate',
      }}
    >
      <TapformLogoWithLabel className="signup-page-logo" label="Signup" />
      <motion.div
        className="signup-page-left"
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{
          y: -100,
          opacity: 0,
          transition: { duration: 0.3, ease: 'anticipate' },
        }}
        transition={{
          delay: 0.2,
          duration: 0.55,
          ease: 'anticipate',
        }}
      >
        <TapformLogo type="blue" className="signup-page-left-logo" />
        <motion.h1
          className="signup-page-left-title"
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            delay: 0.2,
            duration: 0.55,
            ease: 'anticipate',
          }}
        >
          Supercharge your website with <strong>lead generation forms</strong>
        </motion.h1>
        <motion.p
          className="signup-page-left-description"
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            delay: 0.3,
            duration: 0.55,
            ease: 'anticipate',
          }}
        >
          Make forms and collect more leads for your business instantly.
        </motion.p>
        <div className="signup-page-left-features">
          <motion.div
            className="signup-page-left-feature"
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              delay: 0.35,
              duration: 0.55,
              ease: 'anticipate',
            }}
          >
            <OnboardingFeatureOne className="signup-page-left-feature-icon" />
            <div className="signup-page-left-feature-right">
              <h2 className="signup-page-left-feature-title">
                Better Leads From Your Website
              </h2>
              <p className="signup-page-left-feature-desc">
                Discover how to attract, engage, and convert high-quality leads
                with fun Quiz-like forms.
              </p>
            </div>
          </motion.div>
          <motion.div
            className="signup-page-left-feature"
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              delay: 0.45,
              duration: 0.55,
              ease: 'anticipate',
            }}
          >
            <OnboardingFeatureTwo className="signup-page-left-feature-icon" />
            <div className="signup-page-left-feature-right">
              <h2 className="signup-page-left-feature-title">
                Fully Free Early access
              </h2>
              <p className="signup-page-left-feature-desc">
                Be among the first to experience our revolutionary platform.
                Gain full access for free.
              </p>
            </div>
          </motion.div>
          <motion.div
            className="signup-page-left-feature ai"
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              delay: 0.55,
              duration: 0.55,
              ease: 'anticipate',
            }}
          >
            <OnboardingFeatureThree className="signup-page-left-feature-icon" />
            <div className="signup-page-left-feature-right">
              <h2 className="signup-page-left-feature-title">
                AI Powered Form Creator
              </h2>
              <p className="signup-page-left-feature-desc">
                Describe your business and the services you offer, and watch as
                our AI generates a lead gen form in no time.
              </p>
            </div>
          </motion.div>
        </div>
        <span className="signup-page-left-decoration">⚡</span>
      </motion.div>
      <div className="signup-page-right">
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{
            y: -100,
            opacity: 0,
            transition: { duration: 0.3, ease: 'anticipate' },
          }}
          transition={{
            delay: 0.2,
            duration: 0.55,
            ease: 'anticipate',
          }}
          className="signup-page-holder"
        >
          <h1 className="signup-page-holder-title">
            Let’s create your account
          </h1>
          <p className="signup-page-holder-description">
            Create a free account now. Make forms and collect more leads for
            your business instantly.
          </p>
          <div className="signup-page-holder-oauth">
            <OAuthLoginButton
              className="signup-page-holder-oauth-button"
              type={AuthType.GOOGLE}
              label="Google"
            />
            {/* <OAuthLoginButton
              className="signup-page-holder-oauth-button"
              type={AuthType.MICROSOFT}
              label="Sign up with Microsoft"
            /> */}
          </div>
          <span className="signup-page-holder-oauth-separator">
            or continue with email
          </span>
          <form
            className="signup-page-holder-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormInput
              className="signup-page-holder-form-input"
              control={control}
              label="Email"
              type="email"
              name="email"
              autoComplete="email"
              rules={{
                validate: (v) => validateEmail(v) || 'Must be valid email!',
                required: 'Must be valid email address!',
              }}
              motionDelay={0.35}
            />
            <FormInput
              className="signup-page-holder-form-input password"
              control={control}
              label="Password"
              type="password"
              name="password"
              autoComplete="new-password"
              rules={{
                validate: (v) =>
                  validatePassword(v) || 'Must be strong password!',
                required: 'Must not be empty!',
              }}
              motionDelay={0.45}
            />
            <motion.span
              initial={{ opacity: 0, y: '30px' }}
              animate={{ opacity: 1, y: '0%' }}
              exit={{ opacity: 0, transition: { delay: 0 } }}
              transition={{
                duration: 0.45,
                delay: 0.45,
                ease: 'anticipate',
              }}
              className="signup-page-holder-form-input-password-label"
            >
              Password must be atleast 8 characters
            </motion.span>
            <div
              className="signup-page-holder-form-captcha"
              id="signup-page-holder-form-captcha"
            ></div>
            <Button
              text={
                !loading ? (
                  'Create Account'
                ) : (
                  <Spinner className="signup-page-holder-form-submit-loader" />
                )
              }
              type="submit"
              className={`signup-page-holder-form-submit ${
                loading ? 'loading' : ''
              }`}
              // disabled={!formState.isValid}
              disabled={loading}
            />
          </form>
          <span className="signup-page-holder-login-holder">
            Already have an account?{' '}
            <span
              className="signup-page-holder-login-link"
              onClick={() => {
                navigate(RouterPaths.LOGIN);
              }}
            >
              Login
            </span>
          </span>
        </motion.div>
      </div>
    </motion.div>
  );
}

export default SignupPage;

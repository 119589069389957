import { CheckmarkIcon } from '../../assets/images/svg';
import './index.scss';
import React, { HTMLInputTypeAttribute } from 'react';

export type InputPropsError = string[];

export interface InputProps {
  defaultValue?: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  className?: string;
  name?: string;
  For?: string;
  errors?: InputPropsError;
  valid?: boolean;
  onChange?: (value: string) => void;
  value?: string;
  tooltip?: string;
  autocomplete?: string;
}

function Input({
  defaultValue,
  placeholder,
  type,
  className = '',
  name,
  errors = [],
  valid = false,
  value = undefined,
  tooltip = '',
  onChange,
  autocomplete,
}: InputProps) {
  const isValid = valid && !errors.length;

  return (
    <div className="input-component-container">
      <input
        data-toggle="tooltip"
        data-placement="top"
        title={tooltip}
        defaultValue={value === undefined ? defaultValue || '' : undefined}
        placeholder={placeholder || 'Type here...'}
        type={type || 'text'}
        onChange={(event) => {
          if (onChange) {
            onChange(event.target.value);
          }
        }}
        className={`input-component-input ${
          errors.length ? 'error' : ''
        } ${className}`}
        name={name}
        value={value}
        autoComplete={autocomplete}
      />
      <span
        className={`input-component-valid ${isValid ? 'active' : 'inactive'}`}
      >
        <CheckmarkIcon />
      </span>
    </div>
  );
}

export default Input;

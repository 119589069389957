import { RootState } from './reducers/types/root';

export function useUserState(state: RootState) {
  return state.user;
}

export function useIsFormLinkable(state: RootState) {
  return !!state.user?.form?.settings?.linkable;
}

export function useFormSlug(state: RootState) {
  return state.user?.form?.slug;
}

export function selectVerify(state: RootState) {
  return state.user.verify;
}

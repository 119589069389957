import React from 'react';
import './index.scss';
import { motion } from 'framer-motion';
import { TapformLogo } from '../../assets/images/svg';

export interface TapformLogoWithLabelProps {
  label: string;
  color?: 'white' | 'black';
  className?: string;
}

function TapformLogoWithLabel({
  label,
  color = 'black',
  className = '',
}: TapformLogoWithLabelProps) {
  return (
    <motion.div
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{
        y: -100,
        opacity: 0,
        transition: { duration: 0.3, ease: 'anticipate' },
      }}
      transition={{
        delay: 0.1,
        duration: 0.55,
        ease: 'anticipate',
      }}
      className={`tapform-logo-with-label-component-holder ${className}`}
    >
      <TapformLogo
        className="tapform-logo-with-label-component-image"
        type={color === 'white' ? 'white' : 'blue'}
      />
      <span className="tapform-logo-with-label-component-middle" />
      <span className={`tapform-logo-with-label-component-right ${color}`}>
        {label}
      </span>
    </motion.div>
  );
}

export default TapformLogoWithLabel;

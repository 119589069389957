import React from 'react';
import './index.scss';

export interface TogglerProps {
  defaultActive?: number;
  items?: string[];
  className?: string;
  onChange?: (index: number) => void;
  onClick?: (index: number) => void;
  active?: number;
}

function Toggler({
  defaultActive = 0,
  items = [],
  className,
  onChange,
  onClick,
  active,
}: TogglerProps) {
  const [activeState, setActiveState] = React.useState(
    defaultActive && defaultActive >= 0 ? defaultActive : 0,
  );

  const isFirstTimeRef = React.useRef(true);
  const backgroundRef =
    React.useRef() as React.MutableRefObject<HTMLSpanElement>;
  const containerRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  React.useEffect(() => {
    if (isFirstTimeRef.current) {
      isFirstTimeRef.current = false;
    } else {
      if (onChange && !onClick) {
        onChange(activeState);
      }
    }
    let leftOffset = 0;
    containerRef.current.childNodes.forEach((element, index) => {
      if (index > 0) {
        const widthOfElement = (element as any).clientWidth as number;
        if (index - 1 === activeState) {
          backgroundRef.current.style.width = `${widthOfElement}px`;
          backgroundRef.current.style.left = `${leftOffset}px`;
        }
        leftOffset += widthOfElement + 10;
      }
    });
    setTimeout(() => {
      backgroundRef.current.classList.add('add-transition');
    }, 100);
  }, [activeState, onChange]);

  React.useEffect(() => {
    if (active !== undefined) {
      setActiveState(active);
    }
  }, [active]);

  return (
    <div
      ref={containerRef}
      className={`toggler-component-container ${className || ''}`}
    >
      <span ref={backgroundRef} className={`toggler-component-background`} />
      {items.map((item, index) => (
        <button
          key={`${index}_toggler-component-item`}
          className={`toggler-component-item ${
            activeState === index ? 'active' : ''
          }`}
          onClick={() => {
            if (onClick) {
              onClick(index);
            }
          }}
        >
          {item}
        </button>
      ))}
    </div>
  );
}

export default Toggler;

import React from 'react';
import './index.scss';
import { motion } from 'framer-motion';
import { SIMPLE_FRAMER_TRANSITION } from '../../constants/framer';

export interface SpinnerProps {
  className?: string;
  variant?: 'dots' | 'circle';
}

function Spinner({ className = '', variant = 'dots' }: SpinnerProps) {
  if (variant === 'dots') {
    return (
      <div className={`spinner-component-dot ${className}`}>
        <div className="spinner-component-dot-bounce1"></div>
        <div className="spinner-component-dot-bounce2"></div>
        <div className="spinner-component-dot-bounce3"></div>
      </div>
    );
  }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={SIMPLE_FRAMER_TRANSITION({ duration: 0.4 })}
      className={`spinner-component-circle ${className}`}
    />
  );
}

export default Spinner;

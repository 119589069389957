import React from 'react';
import styles from './index.module.scss';
import BackgroundDesk from './integrations.webp';
import BackgroundMobile from './integrations-mobile.webp';
import { ArrowRight, XIcon } from '../../assets/images/svg';
import useWindowSize from '../../hooks/use-windows-size';
import APIService from '../../services/api/index.api';
import { useUserState } from '../../store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { actionOpenIntegrationWizard } from '../../store/actions/user.actions';
import IndicatorLabel from '../IndicatorLabel';

export default function FormIntegrationCard() {
  const [visible, setVisible] = React.useState(false);
  const size = useWindowSize();
  const { token, defaultForm } = useSelector(useUserState);
  const [imageDeskLoaded, setImageDeskLoaded] = React.useState(false);
  const [imageMobileLoaded, setImageMobileLoaded] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (token && defaultForm) {
      (async () => {
        const integrated = await APIService.statsService.checkFormIntegrated(
          token,
          defaultForm,
        );
        if (integrated?.data?.integrated === false) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      })();
    }
  }, [token, defaultForm]);

  return (
    <div
      className={`${styles.container} ${
        visible ? styles.containerVisible : ''
      }`}
    >
      {visible ? (
        <div className={styles.holder}>
          <div className={styles.left}>
            <h1 className={styles.title}>Integrate on website</h1>
            <p className={styles.description}>
              Start collecting leads using your own website with Tapform easy
              integration!
            </p>
            <div className={styles.cta}>
              <IndicatorLabel className={styles.ctaLabel} color="red">
                <XIcon style={{ marginRight: 8 }} /> Not integrated
              </IndicatorLabel>{' '}
              <button
                className={styles.button}
                onClick={() => {
                  // navigate(RouterPaths.SETTINGS);
                  dispatch(actionOpenIntegrationWizard());
                }}
              >
                Add to website <ArrowRight className={styles.buttonIcon} />
              </button>
            </div>
          </div>
          <div className={styles.right}>
            {(size?.width as any) <= 882 ? (
              <img
                onLoad={() => {
                  setImageMobileLoaded(true);
                }}
                className={`${styles.imageMobile} ${
                  imageMobileLoaded ? styles.loaded : ''
                }`}
                src={BackgroundMobile}
              />
            ) : (
              <img
                onLoad={() => {
                  setImageDeskLoaded(true);
                }}
                className={`${styles.imageDesk} ${
                  imageDeskLoaded ? styles.loaded : ''
                }`}
                src={BackgroundDesk}
              />
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

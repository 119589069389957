import React from 'react';
import './index.scss';
import { CopyToClipboard, PreviewInfoIcon } from '../../assets/images/svg';
import { Tooltip, message } from 'antd';
import APIService from '../../services/api/index.api';
import { useUserState } from '../../store/selectors';
import { useSelector } from 'react-redux';

function TapformCodeSnippet() {
  const { token, defaultForm } = useSelector(useUserState);
  const [integrationCode, setIntegrationCode] = React.useState<string | null>(
    null,
  );

  React.useEffect(() => {
    (async () => {
      if (token && defaultForm) {
        const response = await APIService.settingsService.getUserFormSettings(
          token,
          defaultForm,
        );
        if (response !== null) {
          setIntegrationCode(response.data.settings.integrationCode);
        } else {
          message.error('Could not load integration code!');
        }
      }
    })();
  }, [token, defaultForm]);

  function copyCode() {
    if (navigator.clipboard && integrationCode) {
      message.success('Snippet copied!');
      navigator.clipboard.writeText(integrationCode.replace(/\n/g, ''));
    }
  }

  return (
    <div className="tapform-code-snippet-component-container">
      <h1 className="tapform-code-snippet-component-title">
        Integration code{' '}
        <Tooltip title="Copy this code to the bottom of <body> element (before </body>).">
          <PreviewInfoIcon className="tapform-code-snippet-component-title-icon" />
        </Tooltip>
      </h1>
      <div className="tapform-code-snippet-component-code-holder">
        <span className="tapform-code-snippet-component-code">
          {integrationCode}
        </span>
      </div>
      <button
        className="tapform-code-snippet-component-copy"
        onClick={copyCode}
      >
        <CopyToClipboard className="tapform-code-snippet-component-copy-icon" />{' '}
        Copy code
      </button>
    </div>
  );
}

export default TapformCodeSnippet;

import Timezone from 'timezone-enum';
import { ApiBaseService } from './base.api';
import {
  RegistrationsIsVerifiedResponse,
  RegistrationsOnboardingFourResponse,
  RegistrationsOnboardingOneResponse,
  RegistrationsOnboardingThreeRequest,
  RegistrationsOnboardingThreeResponse,
  RegistrationsOnboardingTwoResponse,
  RegistrationsRegisterResponse,
  RegistrationsResendTokenResponse,
  RegistrationsVerifyChangeEmailResponse,
  RegistrationsVerifyResponse,
} from './types/registrations.response';

export class ApiRegistrationsService extends ApiBaseService {
  static async sendRegister(
    email: string,
    password: string,
    recaptchaToken: string,
  ) {
    const url = new URL(`${ApiBaseService.apiUrl}/api/registrations/register`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
        recaptchaToken,
      }),
    }).catch((e) => {
      throw new Error('Something went wrong! Please contact support!');
    });
    if (response.status === 403) {
      throw new Error('User with that email already exists');
    }
    if (response.status === 401) {
      throw new Error('ReCaptcha expired! Please solve again!');
    }
    if (response.status !== 201) {
      throw new Error('Something went wrong! Please contact support!');
    }
    return (await response.json()) as RegistrationsRegisterResponse;
  }

  static async resendEmail(token: string) {
    const url = new URL(
      `${ApiBaseService.apiUrl}/api/registrations/resend-token`,
    );
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token,
      }),
    }).catch((e) => {
      throw new Error('Something went wrong! Please contact support!');
    });
    if (response.status !== 201) {
      throw new Error('Something went wrong! Please contact support!');
    }
    return (await response.json()) as RegistrationsResendTokenResponse;
  }

  static async verify(email: string, token: string) {
    const url = new URL(`${ApiBaseService.apiUrl}/api/registrations/verify`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        token,
      }),
    }).catch((e) => {
      throw new Error('Something went wrong! Please contact support!');
    });
    if (response.status !== 201) {
      throw new Error('Something went wrong! Please contact support!');
    }
    return (await response.json()) as RegistrationsVerifyResponse;
  }

  static async verifyChangeEmail(email: string, resendToken: string) {
    const url = new URL(
      `${ApiBaseService.apiUrl}/api/registrations/verify-change-email`,
    );
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        resendToken,
      }),
    }).catch((e) => {
      throw new Error('Something went wrong! Please contact support!');
    });
    if (response.status !== 201) {
      throw new Error('Something went wrong! Please contact support!');
    }
    return (await response.json()) as RegistrationsVerifyChangeEmailResponse;
  }

  static async isVerified(
    email: string,
    resendToken?: string,
    registrationToken?: string,
  ) {
    const url = new URL(
      `${ApiBaseService.apiUrl}/api/registrations/is-verified`,
    );
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        resendToken,
        registrationToken,
      }),
    }).catch((e) => {
      throw new Error('Something went wrong! Please contact support!');
    });
    if (response.status !== 201) {
      throw new Error('Something went wrong! Please contact support!');
    }
    return (await response.json()) as RegistrationsIsVerifiedResponse;
  }

  // ONBOARDING
  static async onboardingOne(
    firstName: string,
    lastName: string,
    timezone: Timezone,
    token: string,
  ) {
    const url = new URL(`${ApiBaseService.apiUrl}/api/registrations/ob-one`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        firstName,
        lastName,
        timezone,
      }),
    }).catch((e) => {
      throw new Error('Something went wrong! Please contact support!');
    });
    if (response.status === 401) {
      throw new Error('Not authorized!');
    }
    if (response.status !== 201) {
      throw new Error('Something went wrong! Please contact support!');
    }
    return (await response.json()) as RegistrationsOnboardingOneResponse;
  }

  static async onboardingTwo(
    businessName: string,
    origin: string,
    phoneNumber: string,
    token: string,
  ) {
    const url = new URL(`${ApiBaseService.apiUrl}/api/registrations/ob-two`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        businessName,
        origin,
        phoneNumber,
      }),
    }).catch((e) => {
      throw new Error('Something went wrong! Please contact support!');
    });
    if (response.status === 401) {
      throw new Error('Not authorized!');
    }
    if (response.status !== 201) {
      throw new Error('Something went wrong! Please contact support!');
    }
    return (await response.json()) as RegistrationsOnboardingTwoResponse;
  }

  static async onboardingThree(
    body: RegistrationsOnboardingThreeRequest,
    token: string,
  ) {
    const url = new URL(`${ApiBaseService.apiUrl}/api/registrations/ob-three`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    }).catch((e) => {
      throw new Error('Something went wrong! Please contact support!');
    });
    if (response.status === 401) {
      throw new Error('Not authorized!');
    }
    if (response.status !== 201) {
      throw new Error('Something went wrong! Please contact support!');
    }
    return (await response.json()) as RegistrationsOnboardingThreeResponse;
  }

  static async onboardingFour(
    token: string,
    brandColor: string,
    logoImageId?: number,
  ) {
    const url = new URL(`${ApiBaseService.apiUrl}/api/registrations/ob-four`);
    const body = logoImageId
      ? JSON.stringify({
          brandColor,
          logoImageId,
        })
      : JSON.stringify({ brandColor });
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body,
    }).catch((e) => {
      throw new Error('Something went wrong! Please contact support!');
    });
    if (response.status === 401) {
      throw new Error('Not authorized!');
    }
    if (response.status !== 201) {
      throw new Error('Something went wrong! Please contact support!');
    }
    return (await response.json()) as RegistrationsOnboardingFourResponse;
  }
}

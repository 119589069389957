import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import {
  GoogleAuthParams,
  useGoogleAuthParams,
} from '../../hooks/useGoogleAuthParams';
import ScreenLoading from '../../components/ScreenLoading';
import { RouterPaths } from '../../router/paths.d';
import APIService from '../../services/api/index.api';
import { useDispatch } from 'react-redux';
import {
  actionRegisterLogin,
  actionUserLogin,
} from '../../store/actions/user.actions';
import { message } from 'antd';

function LoginGooglePage() {
  const googleParams = useGoogleAuthParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!googleParams) {
      navigate(RouterPaths.SIGNUP);
    } else {
      const loginAction = async (_googleParams: GoogleAuthParams) => {
        try {
          const res = await APIService.authService.loginUserWithGoogle(
            _googleParams,
          );
          if (res) {
            if (res.resend_token) {
              dispatch(
                actionRegisterLogin({
                  resendToken: res.resend_token,
                  email: res.email as string,
                }),
              );
              navigate(RouterPaths.VERIFY);
            } else if (res.access_token) {
              dispatch(
                actionUserLogin({
                  token: res.access_token,
                  isOnboarding: res.isOnboarding,
                  type: res.type,
                }),
              );
              if (res.isOnboarding) {
                navigate(RouterPaths.ONBOARDING_ONE);
              } else {
                navigate(RouterPaths.LEADS);
              }
            }
          }
        } catch (e) {
          console.error(e);
          message.info('Could not login with Google. Please try again.');
          navigate(RouterPaths.SIGNUP);
        }
      };
      loginAction(googleParams);
    }
  }, []);

  return <ScreenLoading />;
}

export default LoginGooglePage;

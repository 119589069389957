/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import React from 'react';
import './index.scss';
import { motion } from 'framer-motion';
import {
  FieldValues,
  UseControllerProps,
  UseFormReturn,
  useController,
} from 'react-hook-form';
import { Tooltip, ColorPicker } from 'antd';
import { validateRgbHex } from '../../validators/index.validator';
import { useInputFocused } from '../../hooks/use-input-focused';

export interface InputProps<FormValues extends FieldValues>
  extends UseControllerProps<FormValues> {
  className?: string;
  style?: React.CSSProperties;
  motionDelay?: number;
  motionDuration?: number;
  autoComplete?: string;
  form: UseFormReturn<FormValues>;
}

function ColorInput<FormValues extends FieldValues>(
  props: InputProps<FormValues>,
) {
  const {
    className = '',
    name,
    style,
    motionDelay = 0,
    motionDuration = 0.45,
    form,
    rules = {
      validate: (v) => validateRgbHex(v) || 'Must be valid hex color!',
      required: 'Brand color is required!',
    },
  } = props;

  const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const focusedInput = useInputFocused(inputRef);
  const [isPickerOpened, setIsPickerOpened] = React.useState<boolean>(false);

  const { field, fieldState } = useController({ ...props, rules });

  const isFocused = focusedInput || isPickerOpened;

  return (
    <Tooltip
      open={!!fieldState.error?.message}
      title={fieldState.error?.message || 'Invalid'}
      placement="topRight"
    >
      <motion.div
        className={`color-input-container ${className} `}
        initial={{ opacity: 0, y: '30px' }}
        animate={{ opacity: 1, y: '0%' }}
        exit={{ opacity: 0, transition: { delay: 0 } }}
        transition={{
          duration: motionDuration,
          delay: motionDelay,
          ease: 'anticipate',
        }}
        style={style}
      >
        <div
          className={`color-input-holder ${isFocused ? 'focused' : ''} ${
            fieldState.error ? 'error' : ''
          }`}
        >
          <ColorPicker
            value={field.value}
            style={{
              border: 'none',
              boxShadow: 'none',
              outline: 'none',
              width: '38px',
              height: '38px',
            }}
            onChange={(value) => {
              form?.setValue(name, value.toHexString() as any);
            }}
            onOpenChange={setIsPickerOpened}
          />
          <div className="color-input-right">
            <label
              className={`color-input-right-label ${
                isFocused ? 'focused' : ''
              } ${fieldState.error ? 'error' : ''}`}
              htmlFor={name}
            >
              Choose color
            </label>
            <input
              className="color-input-right-input"
              type="text"
              id={name}
              autoComplete="none"
              {...field}
              ref={(e) => {
                field.ref(e);
                inputRef.current = e as any;
              }}
            />
          </div>
        </div>
      </motion.div>
    </Tooltip>
  );
}

export default ColorInput;

import React from 'react';
import styles from './index.module.scss';
import TapformLogoWithLabel from '../../../../components/TapformLogoWithLabel';
import { AnimatePresence, motion } from 'framer-motion';
import OnboardingAIBusinessForm from './BusinessForm';
import OnboardingAIServicesForm from './ServicesForm';
import { SIMPLE_FRAMER_TRANSITION } from '../../../../constants/framer';
import APIService from '../../../../services/api/index.api';
import { useDispatch, useSelector } from 'react-redux';
import { useUserState } from '../../../../store/selectors';
import { message } from 'antd';
import {
  actionLoadDefaultForm,
  actionLoadOnboardingThree,
} from '../../../../store/actions/user.actions';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../../components/Spinner';
import {
  AIBusinessIcon,
  AICardBackground,
  AIGeneratingIcon,
  AIServicesIcon,
} from './assets/icons';
import Button from '../../../../components/Button';
import BackgroundImage from './assets/ai-background.webp';
import { ArrowRight } from '../../../../assets/images/svg';
import { RouterPaths } from '../../../../router/paths.d';

interface AIData {
  business: string | null;
  services: string[] | null;
}

export default function OnboardingAIOne() {
  const [data, setData] = React.useState<AIData>({
    business: null,
    services: null,
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingTitle, setLoadingTitle] = React.useState(
    'Please wait while AI is generating your form...',
  );
  const [formSlug, setFormSlug] = React.useState<string | null>(null);
  const [backgroundLoaded, setBackgroundLoaded] =
    React.useState<boolean>(false);
  const { token, userData } = useSelector(useUserState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (userData?.onboardingStep !== 3) {
      navigate(RouterPaths.ONBOARDING_FOUR);
    } else {
      window.scrollTo({ top: 0 });
    }
  }, []);

  const submitApi = React.useCallback(
    async (values: AIData) => {
      try {
        if (values?.business?.length && values.services && token) {
          setLoading(true);
          setTimeout(() => {
            setLoadingTitle('Generating personalized content...');
          }, 7000);
          setTimeout(() => {
            setLoadingTitle('Connecting form logic...');
          }, 16000);
          setTimeout(() => {
            setLoadingTitle('Creating unique responses...');
          }, 21000);
          setTimeout(() => {
            setLoadingTitle('Almost there...');
          }, 32000);
          const res = await APIService.registrationsService.onboardingThree(
            {
              ai: {
                business: values.business,
                services: values.services,
              },
            },
            token,
          );
          if (res?.data?.success) {
            setFormSlug(res.data.formSlug);
            const responseForms = await APIService.formsService.getMyForms(
              token,
            );
            if (responseForms.data.forms[0]) {
              dispatch(
                actionLoadDefaultForm({
                  formId: responseForms.data.forms[0].id,
                  form: responseForms.data.forms[0],
                  availableForms: responseForms.data.forms,
                }),
              );
            }
            dispatch(actionLoadOnboardingThree(0, true));
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      } catch (e: any) {
        message.error(e.message);
      }
    },
    [token],
  );
  return (
    <motion.div
      initial={{
        backgroundImage:
          'radial-gradient(circle at 50% 70%, #f6f8fa, #f6f8fa 98%)',
      }}
      animate={{
        backgroundImage:
          'radial-gradient(circle at 50% 70%, #6236ff, #091d68 98%)',
      }}
      exit={{
        backgroundImage:
          'radial-gradient(circle at 50% 70%, #f6f8fa, #f6f8fa 98%)',
        transition: { duration: 0.35, ease: 'anticipate' },
      }}
      transition={{
        delay: 0,
        duration: 0.45,
        ease: 'anticipate',
      }}
      className={`${styles.container} ${
        formSlug !== null ? styles.previewContainer : ''
      }`}
    >
      <img
        src={BackgroundImage}
        className={`${styles.backgroundContainer} ${
          backgroundLoaded ? styles.loaded : ''
        }`}
        onLoad={() => {
          setBackgroundLoaded(true);
        }}
      />
      {formSlug === null || window.innerWidth > 1024 ? (
        <TapformLogoWithLabel label="AI Onboarding" color="white" />
      ) : null}
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{
          y: -100,
          opacity: 0,
          transition: { duration: 0.3, ease: 'anticipate' },
        }}
        transition={{
          delay: 0.1,
          duration: 0.55,
          ease: 'anticipate',
        }}
        className={`${styles.content} ${
          formSlug !== null ? styles.previewContent : ''
        }`}
        key={formSlug ? 'previewContent' : 'content'}
      >
        <div
          className={`${styles.top} ${
            formSlug !== null ? styles.previewTop : ''
          }`}
        >
          {formSlug === null ? (
            <AICardBackground
              className={styles.background}
              turnOnAnimation={loading}
            />
          ) : null}
          {formSlug !== null ? (
            <>
              <div className={styles.topLeft}>
                <span className={styles.topLeftTitle}>Test your form</span>
                <span className={styles.topLeftDescription}>
                  See how it works and click different responses
                </span>
              </div>
              <Button
                className={styles.topButton}
                variant="ai"
                onClick={() => {
                  navigate(RouterPaths.ONBOARDING_FOUR);
                }}
                text={
                  <>
                    Apply branding
                    <ArrowRight className={styles.topButtonIcon} />
                  </>
                }
              />
            </>
          ) : null}
          <AnimatePresence mode="wait">
            {!data.business && !data.services ? (
              <AIBusinessIcon className={styles.icon} key="businessIcon" />
            ) : null}
            {!data.services && data.business ? (
              <AIServicesIcon className={styles.icon} key="servicesIcon" />
            ) : null}
            {loading ? (
              <AIGeneratingIcon className={styles.icon} key="generatingIcon" />
            ) : null}
          </AnimatePresence>
        </div>
        <div
          className={`${styles.bottom} ${
            formSlug !== null ? styles.previewBottom : ''
          }`}
        >
          <AnimatePresence mode="wait">
            {!data.business ? (
              <motion.h1
                initial={{ translateY: '100%', opacity: 0 }}
                animate={{ translateY: '0%', opacity: 1 }}
                exit={{ translateY: '-100%', opacity: 0 }}
                transition={SIMPLE_FRAMER_TRANSITION({})}
                className={styles.title}
                key="businessTitle"
              >
                What type of business do you run?
              </motion.h1>
            ) : null}
            {!data.services && data.business ? (
              <motion.h1
                initial={{ translateY: '100%', opacity: 0 }}
                animate={{ translateY: '0%', opacity: 1 }}
                exit={{ translateY: '-100%', opacity: 0 }}
                transition={SIMPLE_FRAMER_TRANSITION({})}
                className={styles.title}
                key="servicesTitle"
              >
                What services do you provide?
              </motion.h1>
            ) : null}
            {!data.business ? (
              <OnboardingAIBusinessForm
                onSubmit={(business) => {
                  setData({ business, services: null });
                }}
                key="businessForm"
              />
            ) : null}
            {!data.services && data.business ? (
              <OnboardingAIServicesForm
                onSubmit={(services) => {
                  setData({ business: data.business, services });
                  submitApi({ business: data.business, services });
                }}
                key="servicesForm"
              />
            ) : null}
            {loading ? (
              <motion.h1
                initial={{ translateY: '100%', opacity: 0 }}
                animate={{ translateY: '0%', opacity: 1 }}
                exit={{ translateY: '-100%', opacity: 0 }}
                transition={SIMPLE_FRAMER_TRANSITION({})}
                className={styles.titleLoading}
                key={loadingTitle}
              >
                {loadingTitle}
              </motion.h1>
            ) : null}
            {loading ? <Spinner className={styles.spinner} /> : null}
            {formSlug !== null ? (
              <iframe
                className={styles.iframe}
                src={`${
                  process.env.REACT_APP_FORM_DOMAIN_URL as string
                }${formSlug}`}
              />
            ) : null}
          </AnimatePresence>
        </div>
      </motion.div>
    </motion.div>
  );
}

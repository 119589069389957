import { ApiBaseService } from './base.api';
import { ApiCalendlyAuthResponse } from './types/calendly.response';

export class ApiCalendlyService extends ApiBaseService {
  static async authorizeCode(code: string, formId: number, token: string) {
    const url = new URL(`${ApiBaseService.apiUrl}/api/calendly/auth`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        code,
        formId,
      }),
    }).catch((e) => {
      throw new Error('Something went wrong! Please contact support!');
    });
    if (response.status !== 201) {
      throw new Error('Something went wrong! Please contact support!');
    }
    return (await response.json()) as ApiCalendlyAuthResponse;
  }
}

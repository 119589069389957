import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUserState } from '../../store/selectors';
import APIService from '../../services/api/index.api';
import { DropdownArrow } from '../../assets/images/svg';
import { AnimatePresence, motion } from 'framer-motion';
import { SIMPLE_FRAMER_TRANSITION } from '../../constants/framer';
import { openCalendly } from '../../constants/functions';
import useClickOutside from '../../hooks/use-click-outside';
import './index.scss';
import {
  actionLogoutUser,
  actionOpenFormEdit,
  actionSetDefaultForm,
  actionStartWaitingScreen,
} from '../../store/actions/user.actions';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../router/paths.d';
import useWindowSize from '../../hooks/use-windows-size';

export interface FormChangerProps {
  className?: string;
}

function FormChanger({ className = '' }: FormChangerProps) {
  const { defaultForm, form, forms } = useSelector(useUserState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropdownRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const [isOpen, setIsOpen] = React.useState(false);
  useClickOutside(dropdownRef, () => {
    setIsOpen(false);
  });
  const size = useWindowSize();

  const onFormItemClick = (formId: number) => {
    setIsOpen(false);
    dispatch(actionStartWaitingScreen(3000));
    setTimeout(() => {
      dispatch(actionSetDefaultForm({ formId }));
      setTimeout(() => {
        navigate(RouterPaths.LEADS);
      });
    }, 500);
  };

  return (
    <div className={`form-changer-component ${className}`} ref={dropdownRef}>
      <div
        className={`form-changer-component-holder ${isOpen ? 'active' : ''}`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {form?.style?.logoImage?.id ? (
          <img
            className="form-changer-component-form-icon"
            src={APIService.imageService.createImageUrl(
              form?.style?.logoImage?.id,
            )}
          />
        ) : null}
        <span className="form-changer-component-form-name">{form?.name}</span>
        <DropdownArrow
          className={`form-changer-component-dropdown-icon ${
            isOpen ? 'active' : ''
          }`}
        />
      </div>
      <AnimatePresence mode="wait">
        {isOpen ? (
          <motion.div
            className="form-changer-component-dropdown-holder"
            transition={SIMPLE_FRAMER_TRANSITION({})}
            initial={{ opacity: 0, translateY: -30 }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: -30 }}
          >
            {forms
              ? forms.map((form) => (
                  <div
                    key={form.id}
                    className={`form-changer-component-dropdown-item ${
                      form.id === defaultForm ? 'active' : ''
                    }`}
                    onClick={() => {
                      onFormItemClick(form.id);
                    }}
                  >
                    {form?.style?.logoImage?.id ? (
                      <img
                        className="form-changer-component-form-icon"
                        src={APIService.imageService.createImageUrl(
                          form?.style?.logoImage?.id,
                        )}
                      />
                    ) : null}
                    <span className="form-changer-component-form-name">
                      {form?.name}
                    </span>
                  </div>
                ))
              : null}
            <button
              className="form-changer-component-dropdown-item-create"
              onClick={openCalendly}
            >
              Create new
            </button>
            {(size?.width as any) <= 1024 ? (
              <button
                className="form-changer-component-dropdown-item-settings"
                onClick={() => {
                  setIsOpen(false);
                  navigate(RouterPaths.SETTINGS);
                }}
              >
                Settings
              </button>
            ) : null}
            {(size?.width as any) <= 1024 ? (
              <button
                className="form-changer-component-dropdown-item-settings"
                onClick={() => {
                  setIsOpen(false);
                  dispatch(actionOpenFormEdit());
                }}
              >
                Form Edit
              </button>
            ) : null}
            {(size?.width as any) <= 1024 ? (
              <button
                className="form-changer-component-dropdown-item-logout"
                onClick={() => {
                  setIsOpen(false);
                  dispatch(actionLogoutUser());
                  navigate('/');
                }}
              >
                Logout
              </button>
            ) : null}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
}

export default FormChanger;

/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { motion } from 'framer-motion';
import styles from './index.module.scss';
import FormInput from '../../components/FormInput';
import { useUserState } from '../../store/selectors';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { RouterPaths } from '../../router/paths.d';
import { message } from 'antd';
import APIService from '../../services/api/index.api';
import Spinner from '../../components/Spinner';

interface RequestChangeState {
  change: string;
}

export default function RequestChangesPage() {
  const { visitorType, token, defaultForm } = useSelector(useUserState);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const { handleSubmit, control } = useForm<RequestChangeState>({
    defaultValues: {},
    reValidateMode: 'onChange',
  });

  React.useEffect(() => {
    if (visitorType !== 'user') {
      navigate('/');
    }
  }, []);

  const onSubmit = async (values: RequestChangeState) => {
    if (values?.change && token && defaultForm) {
      setLoading(true);
      const res = await APIService.formsService.requestChange(
        token,
        defaultForm,
        values.change,
      );
      if (res) {
        message.success('Submitted successfully!');
      } else {
        message.error(
          'Someting went wrong! Please contact support: matej@tapform.io',
        );
      }
      setLoading(false);
      navigate(RouterPaths.LEADS);
    }
  };

  return (
    <div className={styles.container}>
      <motion.div
        className={styles.holder}
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{
          y: -100,
          opacity: 0,
          transition: { duration: 0.3, ease: 'anticipate' },
        }}
        transition={{
          delay: 0.2,
          duration: 0.55,
          ease: 'anticipate',
        }}
      >
        <span className={styles.icon}>🚧</span>
        <h1 className={styles.title}>We're working on easy form builder.</h1>
        <p className={styles.description}>
          Our form builder is taking shape, but it's not quite ready for prime
          time just yet. We're fine-tuning and perfecting the user experience to
          ensure it meets your needs.
        </p>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            className={styles.input}
            element="textarea"
            name="change"
            control={control}
            rules={{ required: 'This is required!' }}
            label="Request"
            type="text"
          />
          <button
            disabled={loading}
            className={`${styles.submit} ${loading ? styles.loading : ''}`}
            type="submit"
          >
            {loading ? (
              <Spinner className={styles.spinner} variant="circle" />
            ) : (
              'Request change'
            )}
          </button>
        </form>
      </motion.div>
    </div>
  );
}

import React from 'react';
import styles from './index.module.scss';
import Spinner from '../Spinner';

function ScreenLoading() {
  return (
    <div className={styles.container}>
      <Spinner />
    </div>
  );
}

export default ScreenLoading;

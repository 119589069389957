import React from 'react';
import { motion } from 'framer-motion';
import './index.scss';
import FormInput from '../../../components/FormInput';
import Button from '../../../components/Button';
import { useForm } from 'react-hook-form';
import APIService from '../../../services/api/index.api';
import { useDispatch, useSelector } from 'react-redux';
import { useUserState } from '../../../store/selectors';
import { RouterPaths } from '../../../router/paths.d';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { actionLoadOnboardingTwo } from '../../../store/actions/user.actions';
import {
  validateDomain,
  validateUrl,
} from '../../../validators/index.validator';
import TapformLogoWithLabel from '../../../components/TapformLogoWithLabel';
import normalizeUrl from 'normalize-url';

interface OnboardingTwoFormValues {
  businessName: string;
  origin: string;
  phoneNumber: string;
}

function OnboardingTwoPage() {
  const { handleSubmit, control } = useForm<OnboardingTwoFormValues>({
    defaultValues: {},
    reValidateMode: 'onChange',
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, userData, isLoggedIn } = useSelector(useUserState);

  const onSubmit = React.useCallback(
    async (data: OnboardingTwoFormValues) => {
      const origin =
        data.origin && normalizeUrl(data.origin, { forceHttps: true });
      const originValid = origin && validateUrl(origin);
      if (token && originValid) {
        try {
          const res = await APIService.registrationsService.onboardingTwo(
            data.businessName,
            origin,
            data.phoneNumber,
            token,
          );
          // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
          if (res && res?.data?.success) {
            dispatch(
              actionLoadOnboardingTwo({
                phoneNumber: data.phoneNumber,
              }),
            );
            navigate(RouterPaths.ONBOARDING_THREE);
          }
        } catch (e: any) {
          message.error(e.message);
        }
      }
    },
    [token],
  );

  React.useEffect(() => {
    if (isLoggedIn) {
      if (userData?.onboardingStep !== 2) {
        navigate(RouterPaths.ONBOARDING_THREE);
      } else {
        window.scrollTo({ top: 0 });
      }
    } else {
      navigate(RouterPaths.ONBOARDING_THREE);
    }
  }, [userData, isLoggedIn]);

  if (userData) {
    return (
      <div className="onboarding-two-page-container">
        <TapformLogoWithLabel label="Onboarding" />
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{
            y: -100,
            opacity: 0,
            transition: { duration: 0.3, ease: 'anticipate' },
          }}
          transition={{
            delay: 0.1,
            duration: 0.55,
            ease: 'anticipate',
          }}
          className="onboarding-two-page-content"
        >
          <h1 className="onboarding-two-page-content-title">
            Hey, {userData.firstName}! Enter your business information
          </h1>
          <p className="onboarding-two-page-content-description">
            Tell us about your business! Help us understand your organization
            better and provide you with right form for your industry.
          </p>
          <form
            className="onboarding-two-page-content-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormInput
              control={control}
              type="text"
              name="businessName"
              label="Business Name"
              autoComplete="organization"
              rules={{
                maxLength: { value: 50, message: 'Must be 50 characters max' },
                required: 'Must not be empty!',
              }}
              className="onboarding-two-page-content-form-input"
              motionDelay={0.35}
            />
            <FormInput
              control={control}
              type="text"
              name="origin"
              label="Website Address"
              autoComplete="url"
              rules={{
                maxLength: {
                  value: 512,
                  message: 'Must be 512 characters max',
                },
                validate: (v) =>
                  validateDomain(v) ||
                  'Must be valid domain name (e.g. www.example.com)',
                required: 'Must not be empty!',
              }}
              className="onboarding-two-page-content-form-input"
              motionDelay={0.45}
            />
            <FormInput
              control={control}
              type="text"
              name="phoneNumber"
              label="Phone Number"
              autoComplete="tel"
              rules={{
                maxLength: {
                  value: 255,
                  message: 'Must be 255 characters max',
                },
                required: 'Must not be empty!',
              }}
              className="onboarding-two-page-content-form-input"
              motionDelay={0.55}
            />
            <Button
              type="submit"
              text="Continue"
              className="onboarding-two-page-content-form-submit"
            />
          </form>
        </motion.div>
      </div>
    );
  }
  return null;
}

export default OnboardingTwoPage;

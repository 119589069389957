/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUserState } from '../../store/selectors';
import { motion, AnimatePresence } from 'framer-motion';
import { SIMPLE_FRAMER_TRANSITION } from '../../constants/framer';
import styles from './index.module.scss';
import { actionCloseFormEdit } from '../../store/actions/user.actions';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../router/paths.d';

export default function FormEditOverlay() {
  const { formEditOpened } = useSelector(useUserState);
  const scrollableTarget =
    React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onRequestChangeClick = () => {
    navigate(RouterPaths.REQUEST_CHANGES, {});
    const tab = window.open(RouterPaths.REQUEST_CHANGES, '_blank');
    tab?.focus();
    dispatch(actionCloseFormEdit());
  };

  React.useEffect(() => {
    if (formEditOpened) {
      if (scrollableTarget.current) {
        disablePageScroll(scrollableTarget.current);
      } else {
        disablePageScroll();
      }
    }
    return () => {
      if (formEditOpened) {
        if (scrollableTarget.current) {
          enablePageScroll(scrollableTarget.current);
        } else {
          enablePageScroll();
        }
      }
    };
  }, [formEditOpened]);

  return (
    <AnimatePresence mode="wait">
      {formEditOpened ? (
        <motion.div
          ref={scrollableTarget}
          initial={{
            opacity: 0,
            backdropFilter: 'blur(0px)',
            transform: 'scale(0.7)',
          }}
          animate={{
            opacity: 1,
            backdropFilter: 'blur(5px)',
            transform: 'scale(1)',
          }}
          exit={{
            opacity: 0,
            backdropFilter: 'blur(0px)',
            transform: 'scale(1)',
          }}
          transition={SIMPLE_FRAMER_TRANSITION({ duration: 0.3 })}
          className={styles.container}
        >
          <div
            className={styles.background}
            onClick={() => {
              dispatch(actionCloseFormEdit());
            }}
          ></div>
          <div className={styles.content}>
            <span className={styles.icon}>🚧</span>
            <h1 className={styles.title}>
              We're working on easy form builder.
            </h1>
            <p className={styles.description}>
              Our form builder is taking shape, but it's not quite ready for
              prime time just yet. We're fine-tuning and perfecting the user
              experience to ensure it meets your needs.
            </p>
            <button
              className={styles.requestChanges}
              onClick={onRequestChangeClick}
            >
              Request changes
            </button>
            <span className={styles.label}>
              You can expect changes in minutes after the request.
            </span>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

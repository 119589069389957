import './index.scss';
import React, { HTMLInputTypeAttribute } from 'react';
import { useInputFocused } from '../../hooks/use-input-focused';
import { motion } from 'framer-motion';
import { CheckmarkIconInput } from '../../assets/images/svg';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { Tooltip } from 'antd';

export type InputPropsError = string[];

export interface InputProps<FormValues extends FieldValues>
  extends UseControllerProps<FormValues> {
  type?: HTMLInputTypeAttribute;
  className?: string;
  label?: string;
  style?: React.CSSProperties;
  motionDelay?: number;
  motionDuration?: number;
  autoComplete?: string;
  defaultFocused?: boolean;
  placeholder?: string;
  color?: 'brand' | 'ai';
  element?: 'input' | 'textarea';
}

function FormInput<FormValues extends FieldValues>(
  props: InputProps<FormValues>,
) {
  const {
    label,
    type,
    className = '',
    name,
    style,
    motionDelay = 0,
    motionDuration = 0.45,
    autoComplete,
    defaultFocused = false,
    placeholder = '',
    color = 'brand',
    element = 'input',
  } = props;

  const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  const { field, fieldState } = useController(props);
  const focused = useInputFocused(inputRef);

  const isValid = !fieldState.invalid && !fieldState.error;

  React.useEffect(() => {
    if (defaultFocused && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Tooltip
      open={!!fieldState.error?.message}
      title={fieldState.error?.message || 'Invalid'}
      placement="topRight"
    >
      <motion.div
        className={`forminput-component-container ${className} ${
          focused ? 'focused' : ''
        }`}
        style={style}
        initial={{ opacity: 0, y: '30px' }}
        animate={{ opacity: 1, y: '0%' }}
        exit={{ opacity: 0, transition: { delay: 0 } }}
        transition={{
          duration: motionDuration,
          delay: motionDelay,
          ease: 'anticipate',
        }}
      >
        {element === 'input' ? (
          <input
            id={name}
            type={type || 'text'}
            autoComplete={autoComplete}
            className={`forminput-component-input ${
              fieldState.error ? 'error' : ''
            } ${color}`}
            {...field}
            ref={(e) => {
              inputRef.current = e as any;
              field.ref(e);
            }}
            placeholder={placeholder}
          />
        ) : (
          <textarea
            id={name}
            autoComplete={autoComplete}
            className={`forminput-component-input ${
              fieldState.error ? 'error' : ''
            } ${color}`}
            {...field}
            ref={(e) => {
              inputRef.current = e as any;
              field.ref(e);
            }}
            placeholder={placeholder}
          />
        )}
        {label ? (
          <label
            className={`forminput-component-label ${focused ? 'focused' : ''} ${
              field.value?.length ? 'has-value' : ''
            } ${fieldState.error ? 'error' : ''} ${color}`}
            htmlFor={name}
          >
            {label}
          </label>
        ) : null}
        <span
          className={`forminput-component-valid ${
            isValid ? 'active' : 'inactive'
          }`}
        >
          <CheckmarkIconInput />
        </span>
      </motion.div>
    </Tooltip>
  );
}

export default FormInput;

import React from 'react';
import { motion } from 'framer-motion';
import Button from '../../components/Button';
import { useUserState } from '../../store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import './index.scss';
import {
  VerifyPageGraphicBackground,
  VerifyPageGraphicMailbox,
} from '../../assets/images/svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import APIService from '../../services/api/index.api';
import { message } from 'antd';
import {
  actionLogoutUser,
  actionUserLogin,
} from '../../store/actions/user.actions';
import { RouterPaths } from '../../router/paths.d';
import TapformLogoWithLabel from '../../components/TapformLogoWithLabel';

function VerifyPage() {
  const { visitorType, verify } = useSelector(useUserState);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  // Handle verify token
  React.useEffect(() => {
    if (searchParams) {
      const email = searchParams.get('email');
      const activationToken = searchParams.get('token');
      if (email && activationToken) {
        (async () => {
          try {
            const res = await APIService.registrationsService.verify(
              email,
              activationToken,
            );
            if (res?.data?.access_token) {
              dispatch(
                actionUserLogin({
                  token: res?.data?.access_token,
                  isOnboarding: true,
                }),
              );

              // try to send dataLayer gtag
              try {
                window.dataLayer?.push({ event: 'verify-account' });
              } catch (e) {}

              navigate(RouterPaths.LEADS);
            }
          } catch (e: any) {
            // Check if already verified
            (async () => {
              try {
                const isVerified =
                  await APIService.registrationsService.isVerified(
                    email,
                    undefined,
                    activationToken,
                  );
                if (isVerified.data.success) {
                  dispatch(actionLogoutUser());
                  navigate(RouterPaths.LOGIN);
                  message.info(
                    'Your account is already activated. Please login again.',
                  );
                } else {
                  dispatch(actionLogoutUser());
                  navigate(RouterPaths.LOGIN);
                  message.info(
                    'Your activation link is expired or malformed. Please login again.',
                  );
                }
              } catch (e: any) {
                dispatch(actionLogoutUser());
                navigate(RouterPaths.LOGIN);
                message.info(
                  'Your activation link is expired or malformed. Please login again.',
                );
              }
            })();
          }
        })();
      } else if (visitorType !== 'verify') {
        navigate('/');
      } else {
        // Check if already verified
        if (verify?.resendToken && verify.email) {
          (async () => {
            const isVerified = await APIService.registrationsService.isVerified(
              verify.email,
              verify.resendToken,
            );
            if (isVerified.data.success) {
              dispatch(actionLogoutUser());
              navigate(RouterPaths.LOGIN);
              message.info('Please login again.');
            }
          })();
        }
      }
    }
  }, [searchParams]);

  const resendEmail = React.useCallback(async () => {
    if (verify?.resendToken) {
      try {
        const res = await APIService.registrationsService.resendEmail(
          verify.resendToken,
        );
        if (res?.data?.success) {
          message.success('Please check your email!');
        } else {
          message.error('Failed to resend email! Please contact support!');
        }
      } catch (e) {
        message.error('Failed to resend email! Please contact support!');
      }
    }
  }, [verify]);

  return (
    <div className="verify-page-container">
      <TapformLogoWithLabel label="Onboarding" />
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{
          y: -100,
          opacity: 0,
          transition: { duration: 0.3, ease: 'anticipate' },
        }}
        transition={{
          delay: 0.1,
          duration: 0.55,
          ease: 'anticipate',
        }}
        className="verify-page-content"
      >
        <div className="verify-page-graphic">
          <VerifyPageGraphicBackground
            className="verify-page-graphic-background"
            initial={{
              scale: 1.2,
            }}
            animate={{ scale: 1 }}
            transition={{ type: 'spring', stiffness: 150, delay: 0.3 }}
          />
          <VerifyPageGraphicMailbox
            className="verify-page-graphic-mailbox"
            initial={{
              rotate: '45deg',
              translateX: '87px',
              translateY: '0px',
            }}
            animate={{ rotate: '0deg', translateX: 0, translateY: 0 }}
            transition={{ type: 'spring', stiffness: 150, delay: 0.3 }}
          />
        </div>
        <h1 className="verify-page-title">Verify your email address</h1>
        <p className="verify-page-description">
          We have sent you an email to{' '}
          <strong>{verify?.email || searchParams?.get('email') || ''}</strong>.
          If you have not received the email after a few minutes, please check
          your spam folder.
        </p>
        <Button
          text="Resend Email"
          className="verify-page-resend"
          onClick={resendEmail}
        />
        <span
          className="verify-page-label"
          onClick={() => {
            navigate(RouterPaths.VERIFY_CHANGE_EMAIL);
          }}
        >
          Didn’t get email?
        </span>
      </motion.div>
    </div>
  );
}

export default VerifyPage;

import React from 'react';
import { ArrowRight } from '../../assets/images/svg';
import './index.scss';

interface ArrowButtonProps {
  className?: string;
  type?: 'right' | 'left';
  disabled?: boolean;
  onClick?: () => void;
}

function ArrowButton({
  className = '',
  type = 'right',
  disabled = false,
  onClick,
}: ArrowButtonProps) {
  return (
    <div
      onClick={onClick}
      className={`arrow-button-component-container ${
        disabled ? 'disabled' : ''
      } ${className}`}
    >
      <ArrowRight className={`arrow-button-component-icon ${type}`} />
    </div>
  );
}

export default ArrowButton;

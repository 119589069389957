/* eslint-disable no-unneeded-ternary */
import { Helmet } from 'react-helmet';
import './index.scss';
import LeadList, { type LeadListLeadProp } from '../../components/LeadList';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { RouterPaths } from '../../router/paths.d';
import Button from '../../components/Button';
import APIService from '../../services/api/index.api';
import { useSelector } from 'react-redux';
import { useUserState } from '../../store/selectors';
import FormIntegrationCard from '../../components/FormIntegrationCard';
import StatsCard from '../../components/StatsCard';

function LeadsPage() {
  const { token, defaultForm, visitorType, walkthrough } =
    useSelector(useUserState);

  const [leads, setLeads] = React.useState<{
    total: number;
    offset: number;
    items: LeadListLeadProp[];
  }>({
    total: 0,
    offset: 0,
    items: [],
  });

  const navigate = useNavigate();

  React.useEffect(() => {
    if (visitorType !== 'user') {
      navigate('/');
    }
  }, []);

  const leadClick = React.useCallback(
    (id: number) => {
      navigate(`${RouterPaths.LEADS}/${id}`);
    },
    [navigate],
  );

  const loadLeads = React.useCallback(
    async (reset = false) => {
      console.log(token, defaultForm);
      if (token && defaultForm) {
        const response = await APIService.leadsService.getMyLeads(
          token,
          defaultForm,
          reset ? 0 : leads.offset,
        );
        setLeads({
          total: response.data.total,
          offset: reset ? 10 : leads.offset + 10,
          items: reset
            ? [
                ...response.data.leads.map((lead) => ({
                  name: `${lead.firstName} ${lead.lastName}`,
                  email: lead.email,
                  phone: lead.phoneNumber || undefined,
                  createdAt: new Date(lead.createdAt),
                  isNew: lead.isNew,
                  id: lead.id,
                })),
              ]
            : [
                ...leads.items,
                ...response.data.leads.map((lead) => ({
                  name: `${lead.firstName} ${lead.lastName}`,
                  email: lead.email,
                  phone: lead.phoneNumber || undefined,
                  createdAt: new Date(lead.createdAt),
                  isNew: lead.isNew,
                  id: lead.id,
                })),
              ],
        });
      }
    },
    [defaultForm, token, leads],
  );

  React.useEffect(() => {
    loadLeads(true);
  }, [token, defaultForm]);

  const isInWalkthrough = walkthrough === 1 ? true : false;

  return (
    <>
      <Helmet>
        <title>Tapform | Leads</title>
      </Helmet>
      <div
        className={`leads-page-container ${
          isInWalkthrough ? 'walkthrough' : ''
        }`}
      >
        <div className="leads-page-holder">
          <FormIntegrationCard />
          <StatsCard />
          <h1 className="leads-page-title">Leads</h1>
          <div className="leads-page-table-header">
            <span className="leads-page-subtitle">
              Here you can find and see all of your leads
            </span>
            <Button
              className="leads-page-export"
              variant="csv-export"
              onClick={async () => {
                if (token && defaultForm) {
                  await APIService.leadsService.export(token, defaultForm);
                }
              }}
            />
          </div>
          <LeadList
            className="leads-page-leads"
            leads={leads.items}
            onClick={leadClick}
          />
          {leads.offset < leads.total ? (
            <Button
              className={'leads-page-load-more'}
              onClick={() => loadLeads()}
              text="Load More"
            />
          ) : null}
        </div>
      </div>
    </>
  );
}

export default LeadsPage;

// Generated by https://quicktype.io

export enum InputType {
  SINGLE_SELECT = 'single-select',
  MULTIPLE_SELECT = 'multiple-select',
  PHONE_INPUT = 'phone-input',
  TEXT_INPUT = 'text-input',
  EMAIL_INPUT = 'email-input',
  DATE_INPUT = 'date-input',
  DATE_RANGE_INPUT = 'date-range-input',
  NUMBER_INPUT = 'number-input',
  ADDRESS_INPUT = 'address-input',
}

export enum DesignType {
  CARDS = 'cards',
  BULLETS = 'bullets',
}

export enum ViewType {
  LIST = 'list',
  GRID = 'grid',
}

export interface AnswersGetUserLeadAnswersResponse {
  data: {
    answers: {
      id: number;
      createdAt: string;
      modifiedAt: string;
      deletedAt: string;
      textAnswer: string;
      input: {
        id: number;
        createdAt: string;
        modifiedAt: string;
        deletedAt: string;
        question: string;
        order: number;
        inputType: InputType;
      };
      options: {
        option: {
          id: number;
          createdAt: string;
          modifiedAt: string;
          deletedAt: string;
          order: number;
          value: string;
          price: {
            id: number;
            createdAt: string;
            modifiedAt: string;
            deletedAt: string;
            minPriceRule: number;
            title: string;
            description: string;
            image: {
              id: number;
              createdAt: string;
              modifiedAt: string;
              deletedAt: null;
              filename: string;
              size: number;
            } | null;
          };
        };
      }[];
    }[];
  };
}

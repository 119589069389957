import { message } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useFormSlug } from '../../store/selectors';
import { CopyFormLinkIcon } from '../../assets/images/svg';
import Button from '../Button';
import './index.scss';
import useWindowSize from '../../hooks/use-windows-size';
import { RouterPaths } from '../../router/paths.d';
import { motion } from 'framer-motion';
import { SIMPLE_FRAMER_TRANSITION } from '../../constants/framer';

export const MOBILE_FLOTAER_LOCATIONS_NOT_VISIBLE = [
  RouterPaths.ONBOARDING_ONE,
  RouterPaths.ONBOARDING_TWO,
  RouterPaths.ONBOARDING_THREE,
  RouterPaths.ONBOARDING_FOUR,
  RouterPaths.LOGIN,
  RouterPaths.SIGNUP,
  RouterPaths.WELCOME,
];

function MobileFloater() {
  const slug = useSelector(useFormSlug);
  const windowSize = useWindowSize();
  function copyFormLink() {
    navigator.clipboard?.writeText(
      `${process.env.REACT_APP_FORM_DOMAIN_URL || 'http://localhost:3004'}${
        slug as string
      }`,
    );
    message.success('Form link successfully copied!');
  }

  if (windowSize?.width && windowSize.width <= 1024) {
    return (
      <motion.div
        transition={SIMPLE_FRAMER_TRANSITION({})}
        initial={{ opacity: 0, translateY: '100%' }}
        animate={{ opacity: 1, translateY: '0%' }}
        exit={{ opacity: 0, translateY: '100%' }}
        className="mobile-floater-component"
      >
        <Button
          variant="copy-link"
          className="mobile-floater-component-copy-link"
          text={
            <>
              <CopyFormLinkIcon style={{ marginRight: 4 }} /> Share Form
            </>
          }
          onClick={copyFormLink}
        />
      </motion.div>
    );
  }

  return null;
}

export default MobileFloater;

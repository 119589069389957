import {
  FormTemplatePopupDevicesBadge,
  FormTemplatePopupQuestionsBadge,
  FormTemplatePopupTimeBadge,
} from '../../../assets/images/svg';
import { SVGProps } from 'react';

// IMAGES
import RoofingOne from '../images/roofing/1.png';
import RoofingTwo from '../images/roofing/2.png';
import RoofingThree from '../images/roofing/3.png';
import RoofingFour from '../images/roofing/4.png';
import RoofingFive from '../images/roofing/5.png';
import RoofingSix from '../images/roofing/6.png';
import RoofingSeven from '../images/roofing/7.png';

import GarageDoorOne from '../images/garage-door/1.png';
import GarageDoorTwo from '../images/garage-door/2.png';
import GarageDoorThree from '../images/garage-door/3.png';
import GarageDoorFour from '../images/garage-door/4.png';
import GarageDoorFive from '../images/garage-door/5.png';

import CleaningOne from '../images/cleaning/1.png';

import Landscaping1 from '../images/landscaping/1.png';
import Landscaping2 from '../images/landscaping/2.png';
import Landscaping3 from '../images/landscaping/3.png';
import Landscaping4 from '../images/landscaping/4.png';

import HVACOne from '../images/hvac/1.png';
import HVACTwo from '../images/hvac/2.png';
import HVACThree from '../images/hvac/3.png';
import HVACFour from '../images/hvac/4.png';

import SolarOne from '../images/solar/1.png';
import SolarTwo from '../images/solar/2.png';
import SolarThree from '../images/solar/3.png';
import SolarFour from '../images/solar/4.png';
import SolarFive from '../images/solar/5.png';

import PaintingOne from '../images/painting/1.png';
import PaintingTwo from '../images/painting/2.png';
import PaintingThree from '../images/painting/3.png';
import PaintingFour from '../images/painting/4.png';
import PaintingFive from '../images/painting/5.png';

import FloorCleaning1 from '../images/floor-cleaning/1.png';
import FloorCleaning2 from '../images/floor-cleaning/2.png';
import FloorCleaning3 from '../images/floor-cleaning/3.png';
import FloorCleaning4 from '../images/floor-cleaning/4.png';
import FloorCleaning5 from '../images/floor-cleaning/5.png';
import FloorCleaning6 from '../images/floor-cleaning/6.png';

import PoolServices1 from '../images/pool-services/1.png';
import PoolServices2 from '../images/pool-services/2.png';
import PoolServices3 from '../images/pool-services/3.png';
import PoolServices4 from '../images/pool-services/4.png';
import PoolServices5 from '../images/pool-services/5.png';

import Concrete1 from '../images/concrete/1.png';
import Concrete2 from '../images/concrete/2.png';
import Concrete3 from '../images/concrete/3.png';
import Concrete4 from '../images/concrete/4.png';

import { FormTemplates } from '../../../services/api/types/registrations.response.d';

export const FormTemplatePopupData: Record<
  FormTemplates,
  {
    title: string;
    description: string;
    includes: {
      icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
      text: string;
    }[];
    images: string[];
  }
> = {
  [FormTemplates.CLEANING]: {
    title: 'Cleaning template',
    description:
      'Helps you collect jobs specification to better understand roofing project, customer contact details for roofing businesses.',
    includes: [
      {
        icon: FormTemplatePopupQuestionsBadge,
        text: '8 questions',
      },
      {
        icon: FormTemplatePopupTimeBadge,
        text: '75 seconds to complete',
      },
      {
        icon: FormTemplatePopupDevicesBadge,
        text: 'Works on all devices',
      },
    ],
    images: [CleaningOne],
  },
  [FormTemplates.ROOFING]: {
    title: 'Roofing template',
    description:
      'Helps you collect jobs specification to better understand roofing project and customer contact details for roofing businesses.',
    includes: [
      {
        icon: FormTemplatePopupQuestionsBadge,
        text: '7 questions',
      },
      {
        icon: FormTemplatePopupTimeBadge,
        text: '75 seconds to complete',
      },
      {
        icon: FormTemplatePopupDevicesBadge,
        text: 'Works on all devices',
      },
    ],
    images: [
      RoofingOne,
      RoofingTwo,
      RoofingThree,
      RoofingFour,
      RoofingFive,
      RoofingSix,
      RoofingSeven,
    ],
  },
  [FormTemplates.GARAGE_DOOR]: {
    title: 'Garage door template',
    description:
      'Helps you collect jobs specification to better understand garage door project and customer contact details for your businesses.',
    includes: [
      {
        icon: FormTemplatePopupQuestionsBadge,
        text: '5 questions',
      },
      {
        icon: FormTemplatePopupTimeBadge,
        text: '75 seconds to complete',
      },
      {
        icon: FormTemplatePopupDevicesBadge,
        text: 'Works on all devices',
      },
    ],
    images: [
      GarageDoorOne,
      GarageDoorTwo,
      GarageDoorThree,
      GarageDoorFour,
      GarageDoorFive,
    ],
  },
  [FormTemplates.HVAC]: {
    title: 'HVAC template',
    description:
      'Helps you collect jobs specification to better understand specific HVAC requirements and customer contact details for HVAC businesses.',
    includes: [
      {
        icon: FormTemplatePopupQuestionsBadge,
        text: '4 questions',
      },
      {
        icon: FormTemplatePopupTimeBadge,
        text: '75 seconds to complete',
      },
      {
        icon: FormTemplatePopupDevicesBadge,
        text: 'Works on all devices',
      },
    ],
    images: [HVACOne, HVACTwo, HVACThree, HVACFour],
  },
  [FormTemplates.LANDSCAPING]: {
    title: 'Landscaping template',
    description:
      'Helps you collect jobs specification to better understand landscaping project, customer contact details for roofing businesses.',
    includes: [
      {
        icon: FormTemplatePopupQuestionsBadge,
        text: '4 questions',
      },
      {
        icon: FormTemplatePopupTimeBadge,
        text: '75 seconds to complete',
      },
      {
        icon: FormTemplatePopupDevicesBadge,
        text: 'Works on all devices',
      },
    ],
    images: [Landscaping1, Landscaping2, Landscaping3, Landscaping4],
  },
  [FormTemplates.SOLAR]: {
    title: 'Solar template',
    description:
      'Helps you collect jobs specification to better understand solar project and customer contact details for solar power businesses.',
    includes: [
      {
        icon: FormTemplatePopupQuestionsBadge,
        text: '5 questions',
      },
      {
        icon: FormTemplatePopupTimeBadge,
        text: '75 seconds to complete',
      },
      {
        icon: FormTemplatePopupDevicesBadge,
        text: 'Works on all devices',
      },
    ],
    images: [SolarOne, SolarTwo, SolarThree, SolarFour, SolarFive],
  },
  [FormTemplates.LAWNCARE]: {
    title: 'Solar template',
    description:
      'Helps you collect jobs specification to better understand solar project and customer contact details for solar power businesses.',
    includes: [
      {
        icon: FormTemplatePopupQuestionsBadge,
        text: '5 questions',
      },
      {
        icon: FormTemplatePopupTimeBadge,
        text: '75 seconds to complete',
      },
      {
        icon: FormTemplatePopupDevicesBadge,
        text: 'Works on all devices',
      },
    ],
    images: [],
  },
  [FormTemplates.FLOOR_CLEANING]: {
    title: 'Floor cleaning template',
    description:
      'Helps you collect jobs specification to better understand floor cleaning project and customer contact details for your businesses.',
    includes: [
      {
        icon: FormTemplatePopupQuestionsBadge,
        text: '6 questions',
      },
      {
        icon: FormTemplatePopupTimeBadge,
        text: '75 seconds to complete',
      },
      {
        icon: FormTemplatePopupDevicesBadge,
        text: 'Works on all devices',
      },
    ],
    images: [
      FloorCleaning1,
      FloorCleaning2,
      FloorCleaning3,
      FloorCleaning4,
      FloorCleaning5,
      FloorCleaning6,
    ],
  },
  [FormTemplates.PAINTING]: {
    title: 'Painting template',
    description:
      'Helps you collect jobs specification to better understand painting project and customer contact details for your businesses.',
    includes: [
      {
        icon: FormTemplatePopupQuestionsBadge,
        text: '5 questions',
      },
      {
        icon: FormTemplatePopupTimeBadge,
        text: '75 seconds to complete',
      },
      {
        icon: FormTemplatePopupDevicesBadge,
        text: 'Works on all devices',
      },
    ],
    images: [
      PaintingOne,
      PaintingTwo,
      PaintingThree,
      PaintingFour,
      PaintingFive,
    ],
  },
  [FormTemplates.PLUMBING]: {
    title: 'Solar template',
    description:
      'Helps you collect jobs specification to better understand solar project and customer contact details for solar power businesses.',
    includes: [
      {
        icon: FormTemplatePopupQuestionsBadge,
        text: '5 questions',
      },
      {
        icon: FormTemplatePopupTimeBadge,
        text: '75 seconds to complete',
      },
      {
        icon: FormTemplatePopupDevicesBadge,
        text: 'Works on all devices',
      },
    ],
    images: [],
  },
  [FormTemplates.JUNK_REMOVAL]: {
    title: 'Solar template',
    description:
      'Helps you collect jobs specification to better understand solar project and customer contact details for solar power businesses.',
    includes: [
      {
        icon: FormTemplatePopupQuestionsBadge,
        text: '5 questions',
      },
      {
        icon: FormTemplatePopupTimeBadge,
        text: '75 seconds to complete',
      },
      {
        icon: FormTemplatePopupDevicesBadge,
        text: 'Works on all devices',
      },
    ],
    images: [],
  },
  [FormTemplates.LOCKSMITH]: {
    title: 'Solar template',
    description:
      'Helps you collect jobs specification to better understand solar project and customer contact details for solar power businesses.',
    includes: [
      {
        icon: FormTemplatePopupQuestionsBadge,
        text: '5 questions',
      },
      {
        icon: FormTemplatePopupTimeBadge,
        text: '75 seconds to complete',
      },
      {
        icon: FormTemplatePopupDevicesBadge,
        text: 'Works on all devices',
      },
    ],
    images: [],
  },
  [FormTemplates.CONCRETE]: {
    title: 'Concrete template',
    description:
      'Helps you collect jobs specification to better understand concrete project and customer contact details for your businesses.',
    includes: [
      {
        icon: FormTemplatePopupQuestionsBadge,
        text: '4 questions',
      },
      {
        icon: FormTemplatePopupTimeBadge,
        text: '75 seconds to complete',
      },
      {
        icon: FormTemplatePopupDevicesBadge,
        text: 'Works on all devices',
      },
    ],
    images: [Concrete1, Concrete2, Concrete3, Concrete4],
  },
  [FormTemplates.PEST_CONTROL]: {
    title: 'Solar template',
    description:
      'Helps you collect jobs specification to better understand solar project and customer contact details for solar power businesses.',
    includes: [
      {
        icon: FormTemplatePopupQuestionsBadge,
        text: '5 questions',
      },
      {
        icon: FormTemplatePopupTimeBadge,
        text: '75 seconds to complete',
      },
      {
        icon: FormTemplatePopupDevicesBadge,
        text: 'Works on all devices',
      },
    ],
    images: [],
  },
  [FormTemplates.POOL_SERVICES]: {
    title: 'Pool services template',
    description:
      'Helps you collect jobs specification to better understand pool service requirements and customer contact details for your businesses.',
    includes: [
      {
        icon: FormTemplatePopupQuestionsBadge,
        text: '5 questions',
      },
      {
        icon: FormTemplatePopupTimeBadge,
        text: '75 seconds to complete',
      },
      {
        icon: FormTemplatePopupDevicesBadge,
        text: 'Works on all devices',
      },
    ],
    images: [
      PoolServices1,
      PoolServices2,
      PoolServices3,
      PoolServices4,
      PoolServices5,
    ],
  },
};

import { ApiBaseService } from './base.api';
import { FeedbacksSubmitFeedbackBody } from './types/feedbacks.request';
import {
  FeedbacksGetActiveFeedbackResponse,
  FeedbacksSubmitFeedbackResponse,
} from './types/feedbacks.response';

export class ApiFeedbacksService extends ApiBaseService {
  static async getActiveFeedbacks(token: string) {
    const url = new URL(
      `${ApiBaseService.apiUrl}/api/feedbacks/get-active-feedback`,
    );
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return (await response.json()) as FeedbacksGetActiveFeedbackResponse;
  }

  static async submitFeedback(
    token: string,
    body: FeedbacksSubmitFeedbackBody,
  ) {
    const url = new URL(
      `${ApiBaseService.apiUrl}/api/feedbacks/submit-feedback`,
    );
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    return (await response.json()) as FeedbacksSubmitFeedbackResponse;
  }
}

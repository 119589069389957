/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  actionLogoutUser,
  actionOpenFormEdit,
} from '../../store/actions/user.actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CopyFormLinkIcon, TapformLogo } from '../../assets/images/svg';
import { RouterPaths } from '../../router/paths.d';
import Button from '../Button';
import Toggler from '../Toggler';
import './index.scss';
import {
  useFormSlug,
  useIsFormLinkable,
  useUserState,
} from '../../store/selectors';
import { message } from 'antd';
import { motion } from 'framer-motion';
import { SIMPLE_FRAMER_TRANSITION } from '../../constants/framer';
import WalkthroughTooltip from '../WalkthroughTooltip';
import FormChanger from '../FormChanger';

export const HEADER_LOCATIONS_NOT_VISIBLE = [
  RouterPaths.ONBOARDING_ONE,
  RouterPaths.ONBOARDING_TWO,
  RouterPaths.ONBOARDING_THREE,
  RouterPaths.ONBOARDING_FOUR,
  RouterPaths.LOGIN,
  RouterPaths.SIGNUP,
  RouterPaths.WELCOME,
];

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentPath = location.pathname as RouterPaths;
  const linkable = useSelector(useIsFormLinkable);
  const slug = useSelector(useFormSlug);
  const { walkthrough, isAgency } = useSelector(useUserState);
  const headerRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  const navItems: {
    href?: RouterPaths;
    text: string;
    callback?: () => void;
  }[] = React.useMemo(
    () => [
      {
        href: RouterPaths.LEADS,
        text: 'Leads',
      },
      {
        callback: () => {
          dispatch(actionOpenFormEdit());
        },
        text: 'Form',
        href: RouterPaths.REQUEST_CHANGES,
      },
      {
        href: RouterPaths.SETTINGS,
        text: 'Settings',
      },
    ],
    [dispatch],
  );

  const onNavChange = React.useCallback(
    (activeIndex: number) => {
      if (navItems[activeIndex] && walkthrough !== 2) {
        if (navItems[activeIndex].href && !navItems[activeIndex].callback) {
          navigate(navItems[activeIndex].href as any);
        } else if (navItems[activeIndex].callback) {
          (navItems[activeIndex].callback as any)();
        }
      }
    },
    [navItems, walkthrough],
  );

  React.useEffect(() => {
    document.body.classList.add('foreground');
    const onScrollCallback = () => {
      const scrollPosition = window.scrollY;
      const percentScrolled = scrollPosition > 45 ? 1 : scrollPosition / 45;
      const percentShadow = percentScrolled * 16;
      headerRef.current.style.boxShadow = `0 7px 15px 0 rgba(120,120,128,${
        percentShadow / 100
      })`;
    };
    window.addEventListener('scroll', onScrollCallback);
    return () => {
      window.removeEventListener('scroll', onScrollCallback);
      document.body.classList.remove('foreground');
    };
  }, []);

  const isWalkthroughForm = walkthrough === 3;
  const isWalkthroughSettings = walkthrough === 2;
  const isWalkthrough = isWalkthroughForm || isWalkthroughSettings;

  function copyFormLink() {
    navigator.clipboard.writeText(
      `${
        process.env.REACT_APP_FORM_DOMAIN_URL || 'http://localhost:3004'
      }${slug}`,
    );
    message.success('Form link successfully copied!');
  }

  console.log(navItems.findIndex((item) => item.href === currentPath));

  return (
    <motion.div
      initial={{ y: '-100%', opacity: 0 }}
      animate={{ y: '0%', opacity: 1 }}
      exit={{ y: '-100%', opacity: 0 }}
      transition={SIMPLE_FRAMER_TRANSITION({ duration: 0.2 })}
      className={`header-component-container ${
        isWalkthrough ? 'walkthrough' : ''
      }`}
      ref={headerRef}
    >
      {isWalkthrough ? (
        <WalkthroughTooltip
          key={walkthrough}
          title={
            isWalkthroughForm
              ? 'Try out your form live'
              : 'Settings and integration code'
          }
          description={
            isWalkthroughForm
              ? 'You can copy link to your form with this button and share it with anyone you would like to fill out and submit your inquiry form.'
              : `Setup all emails that you want to receive notification once your get new lead and copy code to your website to start using Tapform.`
          }
          buttonText={isWalkthroughForm ? 'Try out my form!' : `Got it!`}
          onProceed={() => {
            if (isWalkthroughForm) {
              window.open(
                `${
                  process.env.REACT_APP_FORM_DOMAIN_URL ||
                  'http://localhost:3004'
                }${slug}`,
                '_blank',
              );
            }
          }}
          left={isWalkthroughForm ? 'calc(50% - 285px)' : 'calc(50% - 170px)'}
          pointer={isWalkthroughForm ? 'left' : 'right'}
        />
      ) : null}
      <div className="header-component-holder">
        <div className="header-component-holder-item">
          <FormChanger />
          {linkable && slug ? (
            <Button
              variant="copy-link"
              className="header-component-copy-link"
              text={
                <>
                  <CopyFormLinkIcon style={{ marginRight: 4 }} /> Share Form
                </>
              }
              onClick={copyFormLink}
            />
          ) : null}
        </div>
        <div className="header-component-holder-item">
          <TapformLogo
            type="blue"
            onClick={() => {
              navigate(RouterPaths.LEADS);
            }}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div className="header-component-holder-item">
          <Toggler
            className="header-component-nav"
            items={navItems.map((item) => item.text)}
            defaultActive={navItems.findIndex(
              (item) => item.href === currentPath,
            )}
            onChange={onNavChange}
            onClick={onNavChange}
            active={navItems.findIndex((item) => item.href === currentPath)}
          />
          <button
            className="header-component-logout"
            onClick={() => {
              dispatch(actionLogoutUser());
              navigate('/');
            }}
          >
            Log out
          </button>
        </div>
      </div>
    </motion.div>
  );
}

export default Header;

import { ApiBaseService } from './base.api';
import { UsersChangePasswordResponse } from './types/users.response';

export class ApiAgencyUsersService extends ApiBaseService {
  static async changeUserPassword(
    token: string,
    oldPassword: string,
    newPassword: string,
  ) {
    const url = new URL(
      `${ApiBaseService.apiUrl}/api/agency-users/change-password`,
    );
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ oldPassword, newPassword }),
    });
    return (await response.json()) as UsersChangePasswordResponse;
  }
}

import React from 'react';

export function useBodyHeight() {
  React.useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.classList.add('enable-height');
    }
    return () => {
      if (root) {
        root.classList.remove('enable-height');
      }
    };
  }, []);
}

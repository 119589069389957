import APIService from '../../services/api/index.api';
import moment from 'moment';
import React from 'react';
import './index.scss';

export interface LeadInfoProps {
  name: string;
  createdAt: Date;
  address?: string;
  email: string;
  phone?: string;
  className?: string;
  businessName?: string;
  calendlyUrl?: string;
}

function LeadInfo({
  name,
  createdAt,
  address,
  email,
  phone,
  className = '',
  businessName,
  calendlyUrl,
}: LeadInfoProps) {
  const createdAtMoment = moment(createdAt);
  return (
    <div className={`lead-info-component-container ${className}`}>
      <div className="lead-info-component-headline">
        <div className="lead-info-component-headline-left">
          <div className="lead-info-component-headline-left-avatar">👷</div>
        </div>
        <div className="lead-info-component-headline-right">
          <div className="lead-info-component-headline-right-name">{name}</div>
          <div className="lead-info-component-headline-right-date">
            {createdAtMoment.format('MMMM D, YYYY')} -{' '}
            {createdAtMoment.fromNow()}
          </div>
        </div>
      </div>
      {address ? (
        <div
          className="lead-info-component-fact"
          onClick={() => {
            window.open(APIService.createGoogleMapsUrl(address), '_blank');
          }}
        >
          <span className="lead-info-component-fact-icon">📍</span>
          <span className="lead-info-component-fact-text">{address}</span>
        </div>
      ) : null}
      <div
        className="lead-info-component-fact"
        onClick={() => {
          window.open(`mailto:${email}`, '_self');
        }}
      >
        <span className="lead-info-component-fact-icon">✉️</span>
        <span className="lead-info-component-fact-text">{email}</span>
      </div>
      {phone ? (
        <div
          className="lead-info-component-fact"
          onClick={() => {
            window.open(`tel:${phone}`, '_self');
          }}
        >
          <span className="lead-info-component-fact-icon">☎️</span>
          <span className="lead-info-component-fact-text">{phone}</span>
        </div>
      ) : null}
      {businessName ? (
        <div
          className="lead-info-component-fact"
          onClick={() => {
            try {
              const googleSearchUrl = new URL('https://www.google.com/search');
              googleSearchUrl.searchParams.append('q', businessName);
              window.open(googleSearchUrl, '_blank');
            } catch (e) {}
          }}
        >
          <span className="lead-info-component-fact-icon">🏢</span>
          <span className="lead-info-component-fact-text">{businessName}</span>
        </div>
      ) : null}
      {calendlyUrl ? (
        <a
          className="lead-info-component-fact"
          href={'https://calendly.com/app/scheduled_events/user/me'}
          target="_blank"
          rel="noreferrer"
        >
          <span className="lead-info-component-fact-icon">📅</span>
          <span className="lead-info-component-fact-text">Scheduled Event</span>
        </a>
      ) : null}
    </div>
  );
}
export default LeadInfo;

import { motion } from 'framer-motion';
import styles from './index.module.scss';
import { useForm } from 'react-hook-form';
import Button from '../../../../../components/Button';
import React from 'react';
import FormInput from '../../../../../components/FormInput';
import { SIMPLE_FRAMER_TRANSITION } from '../../../../../constants/framer';
import { AISparks } from '../../../../../assets/images/svg';

interface FormValues {
  services: string;
}

interface OnboardingAIServicesFormProps {
  onSubmit?: (services: string[]) => void;
}

export default function OnboardingAIServicesForm({
  onSubmit,
}: OnboardingAIServicesFormProps) {
  const form = useForm<FormValues>();
  const onSubmitForm = (values: FormValues) => {
    if (onSubmit) {
      if (values.services?.length) {
        onSubmit(values.services.split(',').map((s) => s.trim()));
      } else {
        onSubmit([]);
      }
    }
  };
  return (
    <motion.div
      className={styles.container}
      initial={{ translateY: '100%', opacity: 0 }}
      animate={{ translateY: '0%', opacity: 1 }}
      exit={{ translateY: '-100%', opacity: 0 }}
      transition={SIMPLE_FRAMER_TRANSITION({})}
    >
      <form className={styles.form} onSubmit={form.handleSubmit(onSubmitForm)}>
        <FormInput
          control={form.control}
          label="Services"
          placeholder="eg. Service 1, Service 2"
          className={styles.input}
          name="services"
          type="text"
          color="ai"
          element="textarea"
        />
        <Button
          disabled={!form.formState.isValid}
          className={styles.submit}
          text={
            <>
              <AISparks /> Create form with AI
            </>
          }
          type="submit"
          variant="ai"
        />
      </form>
    </motion.div>
  );
}

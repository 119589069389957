import { ApiAgencyUsersService } from './agency-users.api';
import { ApiAnswersService } from './answers.api';
import { ApiAuthService } from './auth.api';
import { ApiCalendlyService } from './calendly.api';
import { ApiFeedbacksService } from './feedbacks.api';
import { ApiFormsService } from './forms.api';
import { ApiHubSpotService } from './hubspot';
import { ApiImagesService } from './images.api';
import { ApiLeadsService } from './leads.api';
import { ApiRegistrationsService } from './registrations.api';
import { ApiSettingsService } from './settings.api';
import { ApiStatsService } from './stats.api';
import { ApiUsersService } from './users.api';

class APIService {
  static authService = ApiAuthService;
  static usersService = ApiUsersService;
  static agencyUserService = ApiAgencyUsersService;
  static leadsService = ApiLeadsService;
  static formsService = ApiFormsService;
  static answersService = ApiAnswersService;
  static imageService = ApiImagesService;
  static settingsService = ApiSettingsService;
  static feedbacksService = ApiFeedbacksService;
  static registrationsService = ApiRegistrationsService;
  static statsService = ApiStatsService;
  static calendlyService = ApiCalendlyService;
  static hubspotService = ApiHubSpotService;

  static createGoogleMapsUrl(query: string): string {
    return new URL(`https://maps.google.com?q=${query}`).href;
  }
}

export default APIService;

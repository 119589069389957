import { useSearchParams } from 'react-router-dom';

/**
 * Type definition for the Google OAuth redirect parameters.
 * Adjust based on the parameters you expect.
 */
export interface GoogleAuthParams {
  code?: string;
  state?: string;
  error?: string;
  scope?: string;
  prompt?: string;
}

/**
 * Custom hook to extract Google OAuth parameters from the redirect URI.
 * @returns GoogleAuthParams
 */
export const useGoogleAuthParams = (): GoogleAuthParams | null => {
  const [searchParams] = useSearchParams();

  // Extract the relevant params from the URL
  const code = searchParams.get('code') ?? undefined;
  const state = searchParams.get('state') ?? undefined;
  const error = searchParams.get('error') ?? undefined;
  const scope = searchParams.get('scope') ?? undefined;
  const prompt = searchParams.get('prompt') ?? undefined;

  if (!code) {
    return null;
  }

  // Return the extracted values in a typed manner
  return { code, state, error, scope, prompt };
};

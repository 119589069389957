import React from 'react';
import { AnswerCheckmark } from '../../assets/images/svg';
import './index.scss';

export interface LeadAnswersAnswerProp {
  id: number;
  question: string;
  answer: string;
}

export interface LeadAnswersProps {
  answers: LeadAnswersAnswerProp[];
  className?: string;
  additionalNotes?: string;
}

function LeadAnswers({
  answers,
  className,
  additionalNotes,
}: LeadAnswersProps) {
  return (
    <div className={`lead-answers-component-holder ${className || ''}`}>
      <div className={`lead-answers-component-container`}>
        {answers.map((answer, index) => {
          return (
            <div
              key={`${answer.id}_lead-answers-component-answer`}
              className="lead-answers-component-answer"
            >
              <span className="lead-answers-component-answer-order">{`${
                index + 1
              }. Answer`}</span>
              <span className="lead-answers-component-answer-question">
                {answer.question}
              </span>
              <span className="lead-answers-component-answer-answer">
                <AnswerCheckmark />
                {answer.answer}
              </span>
            </div>
          );
        })}
      </div>
      {additionalNotes ? (
        <div className={`lead-answers-component-container ${className || ''}`}>
          <h1 className="lead-answers-component-additional-title">
            Additional Notes
          </h1>
          <p className="lead-answers-component-additional-note">
            {additionalNotes}
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default LeadAnswers;

import React from 'react';
import { motion } from 'framer-motion';
import './index.scss';
import FormInput from '../../components/FormInput';
import { useForm } from 'react-hook-form';
import { LoginFormValues } from './types';
import Button from '../../components/Button';
import { Link, useNavigate } from 'react-router-dom';
import { RouterPaths } from '../../router/paths.d';
import { validateEmail } from '../../validators/index.validator';
import APIService from '../../services/api/index.api';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionRegisterLogin,
  actionUserLogin,
} from '../../store/actions/user.actions';
import { message } from 'antd';
import { useUserState } from '../../store/selectors';
import TapformLogoWithLabel from '../../components/TapformLogoWithLabel';
import Spinner from '../../components/Spinner';
import OAuthLoginButton from '../../components/OAuthLoginButton';
import { AuthType } from '../../constants/auth';

function LoginPage() {
  const { handleSubmit, control } = useForm<LoginFormValues>({
    defaultValues: {},
    reValidateMode: 'onChange',
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { visitorType } = useSelector(useUserState);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (visitorType !== 'guest') {
      navigate('/');
    }
  }, []);

  const onSubmit = React.useCallback(async (data: LoginFormValues) => {
    setLoading(true);
    try {
      const res = await APIService.authService.sendLogin(
        data.email,
        data.password,
      );
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      if (res) {
        if (res.resend_token) {
          dispatch(
            actionRegisterLogin({
              resendToken: res.resend_token,
              email: data.email,
            }),
          );
          navigate(RouterPaths.VERIFY);
        } else if (res.access_token) {
          dispatch(
            actionUserLogin({
              token: res.access_token,
              isOnboarding: res.isOnboarding,
              type: res.type,
            }),
          );
          navigate(RouterPaths.LEADS);
        }
      }
      setLoading(false);
    } catch (e: any) {
      control.setError(
        'password',
        { message: e.message },
        { shouldFocus: true },
      );
      message.error(e.message);
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    if (visitorType !== 'guest') {
      navigate('/');
    } else {
      setTimeout(() => {
        window.scrollTo({ top: 0 });
      }, 100);
    }
  }, []);

  return (
    <div className="login-page-container">
      <TapformLogoWithLabel label="Login" />
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{
          y: -100,
          opacity: 0,
          transition: { duration: 0.3, ease: 'anticipate' },
        }}
        transition={{
          delay: 0.2,
          duration: 0.55,
          ease: 'anticipate',
        }}
        className="login-page-holder"
      >
        <h1 className="login-page-holder-title">Welcome back!</h1>
        <p className="login-page-holder-description">
          Please enter your login details below to access and gain valuable
          insights and manage your leads effectively!
        </p>
        <div className="login-page-holder-oauth">
          <OAuthLoginButton
            className="login-page-holder-oauth-button"
            type={AuthType.GOOGLE}
            label="Google"
          />
          {/* <OAuthLoginButton
              className="signup-page-holder-oauth-button"
              type={AuthType.MICROSOFT}
              label="Sign up with Microsoft"
            /> */}
        </div>
        <span className="login-page-holder-oauth-separator">
          or continue with email
        </span>
        <form
          className="login-page-holder-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormInput
            className="login-page-holder-form-input"
            control={control}
            label="Email"
            type="email"
            name="email"
            autoComplete="email"
            rules={{
              validate: (v) => validateEmail(v) || 'Must be valid email!',
              required: 'Must be valid email address!',
            }}
            motionDelay={0.35}
          />
          <FormInput
            className="login-page-holder-form-input"
            control={control}
            label="Password"
            type="password"
            name="password"
            autoComplete="password"
            rules={{
              validate: (v) => !!v.length || 'Must be strong password!',
              required: 'Must not be empty!',
            }}
            motionDelay={0.45}
          />
          <Button
            text={
              !loading ? (
                'Login'
              ) : (
                <Spinner className="login-page-holder-form-submit-loader" />
              )
            }
            type="submit"
            className="login-page-holder-form-submit"
            // disabled={!formState.isValid}
            disabled={loading}
          />
          <Link
            to={RouterPaths.RESET_PASSWORD_REQUEST}
            className="login-page-holder-form-forgot-password"
          >
            Forgot your password? <strong>Reset here.</strong>
          </Link>
        </form>
        <span className="login-page-holder-login-holder">
          Don&apos;t have an account?{' '}
          <span
            className="login-page-holder-login-link"
            onClick={() => {
              navigate(RouterPaths.SIGNUP);
            }}
          >
            Sign Up Today!
          </span>
        </span>
      </motion.div>
    </div>
  );
}

export default LoginPage;

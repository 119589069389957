import React from 'react';
import styles from './index.module.scss';

interface IndicatorLabelProps {
  className?: string;
  color?: 'blue' | 'red';
  children: React.ReactNode;
}

export default function IndicatorLabel({
  className = '',
  color = 'blue',
  children,
}: IndicatorLabelProps) {
  return (
    <div className={`${styles.container} ${styles[color]} ${className}`}>
      {children}
    </div>
  );
}

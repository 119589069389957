import React from 'react';
import styles from './index.module.scss';
import { ClientUTM } from '../../services/api/types/client.response.d';

export interface LeadUTMInfoProps {
  utm: ClientUTM[];
  className?: string;
}

function LeadUTMInfo({ utm, className = '' }: LeadUTMInfoProps) {
  if (!utm.length) {
    return null;
  }
  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.headline}>UTM Data</div>
      {utm.map((utmData) => {
        return (
          <div className={styles.utmItem} key={utmData.aliasId}>
            <span className={styles.utmItemParam}>{utmData.parameter}:</span>
            <span className={styles.utmItemText}>{utmData.value}</span>
          </div>
        );
      })}
    </div>
  );
}

export default LeadUTMInfo;

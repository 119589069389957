import { Transition } from 'framer-motion';

export function SIMPLE_FRAMER_TRANSITION({
  duration = 0.2,
  delay = 0,
  repeat = undefined,
}: {
  duration?: number;
  delay?: number;
  repeat?: number;
}): Transition {
  return {
    delay,
    duration,
    ease: 'anticipate',
    repeat,
  };
}

import { ApiBaseService } from './base.api';
import {
  HubSpotAuthorizeAppRequest,
  HubSpotAuthorizeAppResponse,
} from './types/hubspot.response.d';

export class ApiHubSpotService extends ApiBaseService {
  static getInstallUrl() {
    const url = new URL(`${ApiBaseService.apiUrl}/api/hubspot/get-install`);
    return url.href;
  }

  static async authorizeApp(
    token: string,
    body: HubSpotAuthorizeAppRequest,
  ): Promise<HubSpotAuthorizeAppResponse> {
    const url = new URL(`${ApiBaseService.apiUrl}/api/hubspot/authorize-app`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    if (response.status !== 201) {
      return { success: false };
    }
    return (await response.json()) as HubSpotAuthorizeAppResponse;
  }

  static async deactivateHubSpotIntegration(token: string, formId: number) {
    const url = new URL(`${ApiBaseService.apiUrl}/api/hubspot/deactivate`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ formId }),
    });
    if (response.status !== 201) {
      return { success: false };
    }
    return { success: true };
  }
}

/* eslint-disable @typescript-eslint/consistent-type-assertions */
import React from 'react';
import './index.scss';
import APIService from '../../services/api/index.api';
import hexRgb from 'hex-rgb';
import { validateRgbHex } from '../../validators/index.validator';

interface TapformWidgetProps {
  label: string;
  title: string;
  buttonText: string;
  brandColor: string;
  logoId: number;
}

function TapformWidget({
  label,
  title,
  buttonText,
  brandColor,
  logoId,
}: TapformWidgetProps) {
  const rgb =
    brandColor && validateRgbHex(brandColor)
      ? hexRgb(brandColor)
      : {
          red: 0,
          green: 0,
          blue: 0,
        };
  return (
    <div
      className="tapform-widget-component-container"
      style={
        {
          '--tf-brand-color': `#${brandColor}`,
          '--tf-brand-color-r': `${rgb.red}`,
          '--tf-brand-color-g': `${rgb.green}`,
          '--tf-brand-color-b': `${rgb.blue}`,
          '--tf-button-shadow-i': `0 0 0 0px rgba(${rgb.red}, ${rgb.green}, ${rgb.blue}, 0.9)`,
          '--tf-button-shadow-s': `0 0 0 20px rgba(${rgb.red}, ${rgb.green}, ${rgb.blue}, 0)`,
          '--tf-button-shadow-e': `0 0 0 0px rgba(${rgb.red}, ${rgb.green}, ${rgb.blue}, 0)`,
        } as React.CSSProperties
      }
    >
      <div className="tapform-widget-component-top">
        <img
          className="tapform-widget-component-top-logo"
          src={APIService.imageService.createImageUrl(logoId)}
        />
        <div className="tapform-widget-component-top-right">
          <span className="tapform-widget-component-top-label">{label}</span>
          <span className="tapform-widget-component-top-title">{title}</span>
        </div>
      </div>
      <button
        className="tapform-widget-component-button"
        style={{ backgroundColor: brandColor }}
      >
        {buttonText}
      </button>
    </div>
  );
}

export default TapformWidget;

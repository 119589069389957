import { ExportCSVIcon } from '../../assets/images/svg';
import './index.scss';
import React from 'react';

export interface InputProps {
  text?: string | React.ReactElement;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  disabled?: boolean;
  variant?:
    | 'primary'
    | 'phone'
    | 'print'
    | 'back'
    | 'csv-export'
    | 'copy-link'
    | 'ai';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function Button({
  text,
  type,
  className = '',
  variant = 'primary',
  disabled,
  onClick,
}: InputProps) {
  return (
    <button
      onClick={onClick}
      className={`button-component ${variant} ${className}`}
      type={type}
      disabled={disabled}
    >
      {variant === 'csv-export' ? (
        <>
          <ExportCSVIcon className={`button-component-icon ${variant}`} />
          Export CSV
        </>
      ) : (
        text
      )}
    </button>
  );
}

export default Button;

import React from 'react';
import './index.scss';
import { motion } from 'framer-motion';
import AICard from './aicard.webp';
import AIBackground from './template-ai.webp';
import { SIMPLE_FRAMER_TRANSITION } from '../../constants/framer';
import { AISparks, ClickHereLabel } from '../../assets/images/svg';

interface ArrowButtonProps {
  onClick?: () => void;
  motionDelay?: number;
}

function TemplateAICard({ onClick, motionDelay = 0 }: ArrowButtonProps) {
  return (
    <motion.div
      className={`template-ai-card-component-container`}
      initial={{ opacity: 0, translateY: 180 }}
      animate={{ opacity: 1, translateY: 0 }}
      transition={SIMPLE_FRAMER_TRANSITION({
        delay: motionDelay,
        duration: 0.5,
      })}
    >
      <div className="template-ai-card-component-left">
        <span className="template-ai-card-component-label">
          AI Form Builder
        </span>
        <h1 className="template-ai-card-component-title">
          Create your form faster with one simple step
        </h1>
        <p className="template-ai-card-component-description">
          Create perfect lead-generating form tailored for you in seconds using
          AI.
        </p>
        <div className="template-ai-card-component-actions">
          <button
            onClick={onClick}
            className="template-ai-card-component-button"
          >
            <AISparks /> Create form with AI
          </button>
          <ClickHereLabel className="template-ai-card-component-button-label" />
        </div>
      </div>
      <div className="template-ai-card-component-right">
        <img src={AICard} className="template-ai-card-component-image" />
        <img
          src={AIBackground}
          className="template-ai-card-component-image-background"
        />
      </div>
    </motion.div>
  );
}

export default TemplateAICard;

import React from 'react';
import { motion } from 'framer-motion';
import './index.scss';
import { useDispatch } from 'react-redux';
import { actionIncrementWalkthrough } from '../../store/actions/user.actions';
import { SIMPLE_FRAMER_TRANSITION } from '../../constants/framer';
import Button from '../Button';

export interface WalkthroughTooltipProps {
  motionDelay?: number;
  title: string;
  description: string;
  className?: string;
  buttonText?: string;
  onProceed?: () => void;
  left?: number | string;
  top?: number | string;
  bottom?: number | string;
  right?: number | string;
  pointer?: 'left' | 'right';
}

function WalkthroughTooltip({
  title,
  description,
  className = '',
  motionDelay = 0,
  buttonText = 'Got It!',
  left,
  top,
  bottom,
  right,
  onProceed,
  pointer = 'left',
}: WalkthroughTooltipProps) {
  const dispatch = useDispatch();
  return (
    <motion.div
      initial={{ y: '100%', opacity: 0 }}
      animate={{ y: '0%', opacity: 1 }}
      exit={{ y: '100%', opacity: 0 }}
      transition={SIMPLE_FRAMER_TRANSITION({
        duration: 0.4,
        delay: motionDelay,
      })}
      className="walkthrough-tooltip-component-container"
      style={{
        left,
        top,
        bottom,
        right,
      }}
    >
      <div className="walkthrough-tooltip-component-holder">
        <div className={`walkthrough-tooltip-component-pointer ${pointer}`} />
        <div className="walkthrough-tooltip-component-icon-left">✨</div>
        <div className="walkthrough-tooltip-component-icon-right">✨</div>
        <div className="walkthrough-tooltip-component-title">{title}</div>
        <div className="walkthrough-tooltip-component-description">
          {description}
        </div>
        <Button
          className="walkthrough-tooltip-component-submit"
          onClick={() => {
            dispatch(actionIncrementWalkthrough());
            if (onProceed) {
              onProceed();
            }
          }}
          text={buttonText}
        />
      </div>
    </motion.div>
  );
}

export default WalkthroughTooltip;

import React from 'react';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useUserState } from '../../store/selectors';
import APIService from '../../services/api/index.api';
import { openFeedbackForm } from '../../constants/functions';
import { Tooltip } from 'antd';
import { DateIcon } from '../../assets/images/svg';
import { actionLoadSummaryStats } from '../../store/actions/user.actions';

export interface SCardProps {
  value: number;
  type: 'number' | 'percent';
  icon: string;
  title: string;
  description: string;
}

export function SCard({ value, type, icon, title, description }: SCardProps) {
  const renderV =
    type === 'number'
      ? value.toString()
      : new Intl.NumberFormat('default', {
          style: 'percent',
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }).format(value);
  return (
    <div className={styles.card}>
      <span className={styles.cardValue}>{renderV}</span>
      <span className={styles.cardTitle}>{title}</span>
      <span className={styles.cardDesc}>{description}</span>
      <span className={styles.cardIcon}>{icon}</span>
    </div>
  );
}

export default function StatsCard() {
  const { defaultForm, token, statsSummary } = useSelector(useUserState);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (defaultForm && token) {
      (async () => {
        const res = await APIService.statsService.summary(token, defaultForm);
        dispatch(actionLoadSummaryStats(res));
      })();
    } else {
      dispatch(actionLoadSummaryStats(null));
    }
  }, [defaultForm, token]);

  if (statsSummary?.integrated) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <h2 className={styles.headerTitle}>Statistics</h2>
            <p className={styles.headerDesc}>
              Check how your website is performing in last 7 days
            </p>
          </div>
          <div className={styles.headerRight}>
            <Tooltip title="Date feature coming soon!">
              <button className={styles.headerDate}>
                <DateIcon />
                Last 7 days
              </button>
            </Tooltip>
          </div>
        </div>
        <div className={styles.holder}>
          <div className={styles.left}>
            <SCard
              title="New Leads"
              value={statsSummary.newLeads}
              type="number"
              description="Total number of leads you got"
              icon="🧑"
            />
            <SCard
              title="Conversion Rate"
              value={statsSummary.conversionRate}
              type="percent"
              description="Percent of visitors that submitted your form"
              icon="📈"
            />
            <SCard
              title="Form Opens"
              value={statsSummary.opens}
              type="number"
              description="Total number of opens you got"
              icon="👆"
            />
            <SCard
              title="Calls started"
              value={statsSummary.callsStarted}
              type="number"
              description="Total number of calls you got"
              icon="☎️"
            />
          </div>
          <div className={styles.right}>
            <span className={styles.rightIcon}>💡</span>
            <h3 className={styles.rightTitle}>Have idea or feedback?</h3>
            <p className={styles.rightDescription}>
              We would love to hear your side of story. Whatever it is Issue,
              Idea or anything in between.
            </p>
            <button
              className={styles.submitFeedback}
              onClick={openFeedbackForm}
            >
              Submit feedback
            </button>
          </div>
        </div>
      </div>
    );
  }

  return null;
}

declare const Calendly: any;

export function openCalendly() {
  if (Calendly) {
    Calendly.initPopupWidget({
      url: 'https://calendly.com/tapform/tapform-integration-call',
    });
  }
}

export function openRequestChangeForm() {
  if (window) {
    window.open('https://forms.gle/wAbWtwG3MCnQGCYS7', '_blank');
  }
}

export function openFeedbackForm() {
  if (window) {
    window.open('https://client.tapform.io/tapform-feedback', '_blank');
  }
}

export function hexToRgb(hex: string) {
  // Remove the "#" from the beginning of the string
  hex = hex.replace(/^#/, '');

  // Check if the hex string is valid
  if (hex.length !== 3 && hex.length !== 6) {
    return null;
  }

  // Convert 3-digit hex values to 6-digit by doubling each digit
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(function (char) {
        return char + char;
      })
      .join('');
  }

  // Convert hex values to RGB values
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // Return the RGB values as an array
  return [r, g, b];
}

export function openFacebookChat() {
  if (window?.FB?.CustomerChat?.show) {
    window?.FB?.CustomerChat?.show();
  }
}

export function hideFacebookChat() {
  if (window?.FB?.CustomerChat?.hide) {
    window?.FB?.CustomerChat?.hide();
  }
}

export enum ActionTypes {
  // USER
  USER_LOGIN = 'USER_LOGIN',
  USER_REGISTER_LOGIN = 'USER_REGISTER_LOGIN',
  USER_LOGOUT = 'USER_LOGOUT',
  LOAD_USER_DATA = 'LOAD_USER_DATA',
  LOAD_DEFAULT_FORM = 'LOAD_DEFAULT_FORM',
  LOAD_ONBOARDING_ONE = 'LOAD_ONBOARDING_ONE',
  LOAD_ONBOARDING_TWO = 'LOAD_ONBOARDING_TWO',
  LOAD_ONBOARDING_THREE = 'LOAD_ONBOARDING_THREE',
  LOAD_ONBOARDING_FOUR = 'LOAD_ONBOARDING_FOUR',
  SET_DEFAULT_FORM = 'SET_DEFAULT_FORM',
  START_WAITING_SCREEN = 'START_WAITING_SCREEN',
  STOP_WAITING_SCREEN = 'STOP_WAITING_SCREEN',
  LOAD_LOGO_IMAGE = 'LOAD_LOGO_IMAGE',
  END_WELCOME_SCREEN = 'END_WELCOME_SCREEN',
  INCREMENT_WALKTHROUGH = 'INCREMENT_WALKTHROUGH',
  FINISH_WALKTHROUGH = 'FINISH_WALKTHROUGH',
  CHANGE_VERIFY_EMAIL = 'CHANGE_VERIFY_EMAIL',
  OPEN_FORM_EDIT = 'OPEN_FORM_EDIT',
  CLOSE_FORM_EDIT = 'CLOSE_FORM_EDIT',
  LOAD_STATS_SUMMARY = 'LOAD_STATS_SUMMARY',
  OPEN_INTEGRATION_WIZARD = 'OPEN_INTEGRATION_WIZARD',
  CLOSE_INTEGRATION_WIZARD = 'CLOSE_INTEGRATION_WIZARD',
  SET_CALENDLY_AUTH_CODE = 'SET_CALENDLY_AUTH_CODE',
}

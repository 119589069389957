/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { motion } from 'framer-motion';
import './index.scss';
import FormInput from '../../components/FormInput';
import { useForm } from 'react-hook-form';
import { ResetPasswordFormValues } from './types';
import Button from '../../components/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RouterPaths } from '../../router/paths.d';
import APIService from '../../services/api/index.api';
import { useSelector } from 'react-redux';
import { message } from 'antd';
import { useUserState } from '../../store/selectors';
import TapformLogoWithLabel from '../../components/TapformLogoWithLabel';

function ResetPasswordPage() {
  const { handleSubmit, control } = useForm<ResetPasswordFormValues>({
    defaultValues: {},
    reValidateMode: 'onChange',
  });
  const navigate = useNavigate();
  const { visitorType } = useSelector(useUserState);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  React.useEffect(() => {
    if (visitorType !== 'guest' || !token?.length) {
      navigate('/');
    }
  }, []);

  const onSubmit = React.useCallback(
    async (data: ResetPasswordFormValues) => {
      if (token) {
        try {
          const res = await APIService.usersService.resetPassword(
            token,
            data.password,
          );
          // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
          if (res?.data?.success) {
            message.success('Password reset successfully. Please login again.');
          } else {
            message.error('Link expired! Please reset your password again.');
          }
          navigate('/login');
        } catch (e: any) {
          message.error(e.message);
        }
      }
    },
    [token],
  );

  React.useEffect(() => {
    if (visitorType !== 'guest') {
      navigate('/');
    } else {
      setTimeout(() => {
        window.scrollTo({ top: 0 });
      }, 100);
    }
  }, []);

  return (
    <div className="reset-password-page-container">
      <TapformLogoWithLabel label="Reset Password" />
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{
          y: -100,
          opacity: 0,
          transition: { duration: 0.3, ease: 'anticipate' },
        }}
        transition={{
          delay: 0.2,
          duration: 0.55,
          ease: 'anticipate',
        }}
        className="reset-password-page-holder"
      >
        <h1 className="reset-password-page-holder-title">
          Enter your new password.
        </h1>
        <p className="reset-password-page-holder-description">
          Please enter your new password and start using your Tapform account!
        </p>
        <form
          className="reset-password-page-holder-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormInput
            className="login-page-holder-form-input"
            control={control}
            label="Password"
            type="password"
            name="password"
            autoComplete="password"
            rules={{
              validate: (v) => !!v.length || 'Must be strong password!',
              required: 'Must not be empty!',
            }}
            motionDelay={0.45}
          />
          <Button
            text="Reset Password"
            type="submit"
            className="reset-password-page-holder-form-submit"
            // disabled={!formState.isValid}
          />
        </form>
        <span className="reset-password-page-holder-login-holder">
          Don&apos;t have an account?{' '}
          <span
            className="reset-password-page-holder-login-link"
            onClick={() => {
              navigate(RouterPaths.SIGNUP);
            }}
          >
            Sign Up Today!
          </span>
        </span>
      </motion.div>
    </div>
  );
}

export default ResetPasswordPage;

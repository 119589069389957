/* eslint-disable @typescript-eslint/consistent-type-assertions */
import React from 'react';
import APIService from '../../services/api/index.api';
import { TapformPopupArrow } from '../../assets/images/svg';
import './index.scss';

interface TapformPopupProps {
  logoId: number;
  label: string;
  title: string;
  buttonText: string;
  disclaimer: string;
  brandColor: string;
}

function TapformPopup({
  logoId,
  label,
  title,
  buttonText,
  disclaimer,
  brandColor,
}: TapformPopupProps) {
  return (
    <div
      className="tapform-popup-component-container"
      style={
        {
          '--brand-color': `${brandColor}`,
        } as React.CSSProperties
      }
    >
      <span className="tapform-popup-component-top-drag" />
      <img
        className="tapform-popup-component-image"
        src={APIService.imageService.createImageUrl(logoId)}
      />
      <span
        className="tapform-popup-component-label"
        style={{ color: brandColor }}
      >
        {label}
      </span>
      <h1 className="tapform-popup-component-title">{title}</h1>
      <button
        className="tapform-popup-component-button"
        style={{ backgroundColor: brandColor }}
      >
        {buttonText}
        <span className="tapform-popup-component-button-icon">
          <TapformPopupArrow className="tapform-popup-component-button-icon-svg" />
        </span>
      </button>
      <p className="tapform-popup-component-disclaimer">{disclaimer}</p>
    </div>
  );
}

export default TapformPopup;

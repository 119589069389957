import { ApiBaseService } from './base.api';
import {
  FormsRequestChangeResponse,
  FormsTestWebhookResponse,
  FormsUserFormsResponse,
} from './types/forms.response';

export class ApiFormsService extends ApiBaseService {
  static async getMyForms(token: string): Promise<FormsUserFormsResponse> {
    const url = new URL(`${ApiBaseService.apiUrl}/api/forms`);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      return { data: { forms: [] } };
    }
    return (await response.json()) as FormsUserFormsResponse;
  }

  static async requestChange(token: string, formId: number, message: string) {
    const url = new URL(`${ApiBaseService.apiUrl}/api/forms/request-change`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        formId,
        message,
      }),
    });
    if (response.status !== 201) {
      return null;
    }
    return (await response.json()) as FormsRequestChangeResponse;
  }

  static async testWebhook(token: string, formId: number) {
    const url = new URL(`${ApiBaseService.apiUrl}/api/forms/test-webhook`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        formId,
      }),
    });
    if (response.status !== 201) {
      return null;
    }
    return (await response.json()) as FormsTestWebhookResponse;
  }
}

import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useUserState } from '../../store/selectors';
import { actionSetCalendlyAuthCode } from '../../store/actions/user.actions';
import { RouterPaths } from '../../router/paths.d';
import { message } from 'antd';

export default function AuthCalendly() {
  const { visitorType } = useSelector(useUserState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    const calendlyAuthCode = searchParams.get('code');
    if (calendlyAuthCode?.length && typeof calendlyAuthCode === 'string') {
      dispatch(actionSetCalendlyAuthCode({ code: calendlyAuthCode }));
    }
    if (visitorType === 'guest') {
      message.error('Please login to authorize your Calendly integration!');
      navigate(RouterPaths.LOGIN);
    } else {
      navigate(RouterPaths.LEADS);
    }
  }, [searchParams]);

  return null;
}

import { StatsSummaryResponse } from '../../services/api/types/stats.response';
import { ReduxActionPayload } from './types';
import { ActionTypes } from './types/type.actions.d';
import {
  ActionLoadDefaultFormPayload,
  ActionLoadLogoImagePayload,
  ActionLoadOnboardingOnePayload,
  ActionLoadOnboardingTwoPayload,
  ActionLoadUserDataPayload,
  ActionRegisterLoginPayload,
  ActionSetCalendlyAuthCode,
  ActionSetDefaultFormPayload,
  ActionUserLoginPayload,
  ActionVerifyChangeEmailPayload,
} from './types/user';

export function actionUserLogin(
  payload: ActionUserLoginPayload,
): ReduxActionPayload {
  return {
    type: ActionTypes.USER_LOGIN,
    payload,
  };
}

export function actionRegisterLogin(
  payload: ActionRegisterLoginPayload,
): ReduxActionPayload {
  return { type: ActionTypes.USER_REGISTER_LOGIN, payload };
}

export function ActionVerifyChangeEmail(
  payload: ActionVerifyChangeEmailPayload,
): ReduxActionPayload {
  return { type: ActionTypes.CHANGE_VERIFY_EMAIL, payload };
}

export function actionLoadUserData(
  payload: ActionLoadUserDataPayload,
): ReduxActionPayload {
  return {
    type: ActionTypes.LOAD_USER_DATA,
    payload,
  };
}

export function actionLogoutUser(): ReduxActionPayload {
  return {
    type: ActionTypes.USER_LOGOUT,
    payload: null,
  };
}

export function actionLoadDefaultForm(
  payload: ActionLoadDefaultFormPayload,
): ReduxActionPayload {
  return {
    type: ActionTypes.LOAD_DEFAULT_FORM,
    payload,
  };
}

export function actionLoadOnboardingOne(
  payload: ActionLoadOnboardingOnePayload,
): ReduxActionPayload {
  return {
    type: ActionTypes.LOAD_ONBOARDING_ONE,
    payload,
  };
}

export function actionLoadOnboardingTwo(
  payload: ActionLoadOnboardingTwoPayload,
): ReduxActionPayload {
  return {
    type: ActionTypes.LOAD_ONBOARDING_TWO,
    payload,
  };
}

export function actionLoadOnboardingThree(
  duration = 5000,
  onboardedWithAI = false,
): ReduxActionPayload {
  return {
    type: ActionTypes.LOAD_ONBOARDING_THREE,
    payload: {
      duration,
      onboardedWithAI,
    },
  };
}

export function actionLoadOnboardingFour(): ReduxActionPayload {
  return {
    type: ActionTypes.LOAD_ONBOARDING_FOUR,
  };
}

export function actionStartWaitingScreen(duration = 5000): ReduxActionPayload {
  return {
    type: ActionTypes.START_WAITING_SCREEN,
    payload: {
      duration,
    },
  };
}

export function actionEndWaitingScreen(): ReduxActionPayload {
  return {
    type: ActionTypes.STOP_WAITING_SCREEN,
    payload: {},
  };
}

export function actionLoadLogoImage(
  payload: ActionLoadLogoImagePayload,
): ReduxActionPayload {
  return {
    type: ActionTypes.LOAD_LOGO_IMAGE,
    payload,
  };
}

export function actionEndWelcomeScreen(): ReduxActionPayload {
  return {
    type: ActionTypes.END_WELCOME_SCREEN,
    payload: {},
  };
}

export function actionIncrementWalkthrough(): ReduxActionPayload {
  return {
    type: ActionTypes.INCREMENT_WALKTHROUGH,
    payload: {},
  };
}

export function actionFinishWalkthrough(): ReduxActionPayload {
  return {
    type: ActionTypes.FINISH_WALKTHROUGH,
    payload: {},
  };
}

export function actionSetDefaultForm(
  payload: ActionSetDefaultFormPayload,
): ReduxActionPayload {
  return {
    type: ActionTypes.SET_DEFAULT_FORM,
    payload,
  };
}

export function actionOpenFormEdit(): ReduxActionPayload {
  return {
    type: ActionTypes.OPEN_FORM_EDIT,
    payload: {},
  };
}

export function actionCloseFormEdit(): ReduxActionPayload {
  return {
    type: ActionTypes.CLOSE_FORM_EDIT,
    payload: {},
  };
}

export function actionLoadSummaryStats(payload: StatsSummaryResponse | null) {
  return {
    type: ActionTypes.LOAD_STATS_SUMMARY,
    payload,
  };
}

export function actionOpenIntegrationWizard(): ReduxActionPayload {
  return {
    type: ActionTypes.OPEN_INTEGRATION_WIZARD,
  };
}

export function actionCloseOpenIntegrationWizard(): ReduxActionPayload {
  return {
    type: ActionTypes.CLOSE_INTEGRATION_WIZARD,
  };
}

export function actionSetCalendlyAuthCode(
  payload: ActionSetCalendlyAuthCode,
): ReduxActionPayload {
  return {
    type: ActionTypes.SET_CALENDLY_AUTH_CODE,
    payload,
  };
}

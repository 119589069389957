import {
  actionLoadDefaultForm,
  actionLoadUserData,
  actionLogoutUser,
  actionSetCalendlyAuthCode,
} from '../store/actions/user.actions';
import APIService from '../services/api/index.api';
import { useUserState } from '../store/selectors';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import RoutesAnimated from './routes';
import { message } from 'antd';
import WaitingScreen from '../components/WaitingScreen';
import { hideFacebookChat, openFacebookChat } from '../constants/functions';
import Feedbacks from '../components/Feedbacks';
import FormEditOverlay from '../components/FormEditOverlay';
import IntegrationWizard from '../components/IntegrationWizard';

function AppRouter() {
  const { token, defaultForm, calendlyAuthCode } = useSelector(useUserState);
  const dispatch = useDispatch();

  const [appReady, setAppReady] = React.useState(false);

  React.useEffect(() => {
    if (calendlyAuthCode?.length && token && defaultForm) {
      // Send authorize code to backend
      (async () => {
        const response = await APIService.calendlyService.authorizeCode(
          calendlyAuthCode,
          defaultForm,
          token,
        );
        if (response.data.success) {
          message.info('Calendly successfully integrated!');
          dispatch(actionSetCalendlyAuthCode({ code: null }));
        } else {
          message.error('Calendly auth failed! Please contact support!');
        }
      })();
    }
  }, [calendlyAuthCode, token, defaultForm]);

  React.useEffect(() => {
    (async () => {
      if (token) {
        const response = await APIService.authService.verifyToken(token);
        if (response === false) {
          dispatch(actionLogoutUser());
          message.info('Please login again.');
          window.location.replace('/');
        } else {
          const responseForms = await APIService.formsService.getMyForms(token);
          const selectedForm = defaultForm
            ? responseForms.data.forms.find((f) => f.id === defaultForm) ||
              responseForms.data.forms[0]
            : responseForms.data.forms[0];
          const formId =
            response.data.type === 'agency' ? selectedForm.id : undefined;
          if (selectedForm) {
            dispatch(
              actionLoadDefaultForm({
                formId: selectedForm.id,
                form: selectedForm,
                availableForms: responseForms.data.forms,
              }),
            );
          }
          // Load user data
          const res = await APIService.usersService.getUserDetails(
            token,
            formId,
          );
          if (res?.data) {
            dispatch(actionLoadUserData({ user: res.data }));
          }
          openFacebookChat();
        }
      } else {
        hideFacebookChat();
      }
      if (!appReady) {
        setAppReady(true);
      }
    })();
  }, [token, dispatch]);

  if (!appReady) {
    return null;
  }

  return (
    <BrowserRouter>
      <RoutesAnimated />
      <WaitingScreen />
      <Feedbacks />
      <FormEditOverlay />
      <IntegrationWizard />
    </BrowserRouter>
  );
}

export default AppRouter;

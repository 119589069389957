import moment from 'moment';
import NewBadge from '../NewBadge';
import './index.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import { useUserState } from '../../store/selectors';
import WalkthroughTooltip from '../WalkthroughTooltip';

export interface LeadListLeadProp {
  name: string;
  createdAt: Date;
  isNew: boolean;
  email: string;
  phone?: string;
  id: number;
}

export interface LeadListProps {
  leads: LeadListLeadProp[];
  onClick?: (id: number) => void;
  className?: string;
}

function LeadList({ leads, className, onClick }: LeadListProps) {
  const { walkthrough } = useSelector(useUserState);

  return (
    <div className={`lead-list-container ${className || ''}`}>
      {leads.map((lead, ind) => {
        const momentDate = moment(lead.createdAt);
        const isWalkthroughItem = walkthrough === 1 && ind === 0;
        return (
          <div
            key={`${lead.id}_lead-list-item`}
            className={`lead-list-item ${
              isWalkthroughItem ? 'walkthrough' : ''
            }`}
            onClick={
              onClick && !isWalkthroughItem
                ? () => {
                    onClick(lead.id);
                  }
                : undefined
            }
          >
            {isWalkthroughItem ? (
              <WalkthroughTooltip
                title="Meet your leads right here"
                description="Here is the list of all leads you got trough the Tapform. Click on it to see key informations."
              />
            ) : null}
            <div className="lead-list-item-left">
              <span className="lead-list-item-left-avatar">👷</span>
              <div className="lead-list-item-left-info">
                <span className="lead-list-item-left-info-name">
                  {lead.name}
                  {lead.isNew ? (
                    <NewBadge className="lead-list-item-left-info-name-new" />
                  ) : null}
                </span>
                <span className="lead-list-item-left-info-date">
                  {momentDate.format('MMMM D, YYYY')} - {momentDate.fromNow()}
                </span>
              </div>
            </div>
            <div className="lead-list-item-center">{lead.email}</div>
            <div className="lead-list-item-right">{lead.phone || ''}</div>
          </div>
        );
      })}
    </div>
  );
}

export default LeadList;

import { ApiBaseService } from './base.api';
import { AnswersGetUserLeadAnswersResponse } from './types/answers.response';

export class ApiAnswersService extends ApiBaseService {
  static async getUserLeadAnswers(
    token: string,
    leadId: number,
    formId?: number,
  ): Promise<AnswersGetUserLeadAnswersResponse | false> {
    const url = new URL(
      `${ApiBaseService.apiUrl}/api/answers?leadId=${leadId}`,
    );
    if (formId) {
      url.searchParams.append('formId', formId.toString());
    }
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      return false;
    }
    return (await response.json()) as AnswersGetUserLeadAnswersResponse;
  }
}

import React from 'react';
import { FeedbackType } from '../../constants';
import { FeedbackAnswers } from '../../types';
import FeedbackStats from '../Stats';
import FeedbackStep, { FeedbackStepProps } from '../Step';
import './index.scss';

export interface FeedbackEveryDayUsageProps {
  onSubmit: (answers: FeedbackAnswers) => void;
  numberOfLeads: number;
  numberOfCalls: number;
}

function FeedbackEveryDayUsage({
  onSubmit,
  numberOfLeads,
  numberOfCalls,
}: FeedbackEveryDayUsageProps) {
  const [step, setStep] = React.useState(0);
  const answers = React.useRef<FeedbackAnswers>([]);

  function updateAnswers(newAns: FeedbackAnswers, stepAdd = true) {
    const otherParts = answers.current.filter(
      (a) => !newAns.find((as) => as.question === a.question),
    );
    answers.current = [...newAns, ...otherParts];
    if (stepAdd) {
      setStep(step + 1);
    }
  }

  function submitAnswers(newAns: FeedbackAnswers) {
    updateAnswers(newAns, false);
    onSubmit(answers.current);
  }

  const stepsConfig: FeedbackStepProps[] = [
    {
      title: 'Has Tapform met your expectations?',
      label: 'Quick survey',
      items: [
        {
          icon: '😍',
          text: 'Very satisfied',
        },
        {
          icon: '😐',
          text: 'It is alright',
        },
        {
          icon: '😒',
          text: 'Not satisfied',
        },
      ],
      type: 'card',
      onContinue: updateAnswers,
    },
    {
      title: 'Is there anything we can do better or you would like to have?',
      label: 'Quick survey',
      items: [
        {
          icon: '✅',
          text: 'Yes',
        },
        {
          icon: '❌',
          text: 'No',
        },
      ],
      notes: {
        tag: 'What feature you want?',
        question: 'Please describe what:',
      },
      type: 'card',
      onContinue: submitAnswers,
    },
  ];

  return (
    <div className="feedback-fe-holder">
      {step === 0 ? (
        <>
          <FeedbackStats
            type={FeedbackType.EVERY_DAY_USAGE}
            numberOfCalls={numberOfCalls}
            numberOfLeads={numberOfLeads}
            onContinue={() => {
              setStep(step + 1);
            }}
          />
        </>
      ) : (
        <FeedbackStep {...stepsConfig[step]} />
      )}
    </div>
  );
}

export default FeedbackEveryDayUsage;

import React from 'react';
import './index.scss';

export interface CheckboxTogglerProps {
  defaultValue?: boolean;
  className?: string;
  onChange?: (value: boolean) => void;
  value?: boolean;
}

function CheckboxToggler({
  defaultValue,
  className,
  onChange,
  value,
}: CheckboxTogglerProps) {
  const [active, setActive] =
    value !== undefined ? [value, null] : React.useState(defaultValue || false);

  return (
    <div
      className={`checkbox-toggler-component-container ${
        active ? 'active' : ''
      } ${className || ''}`}
      onClick={() => {
        if (setActive) {
          setActive(!active);
        }
        if (onChange) {
          onChange(!active);
        }
      }}
    >
      <span
        className={`checkbox-toggler-component-block ${active ? 'active' : ''}`}
      />
    </div>
  );
}

export default CheckboxToggler;

import React from 'react';
import { motion } from 'framer-motion';
import { FormTemplateData } from './constants';
import './index.scss';
import { SIMPLE_FRAMER_TRANSITION } from '../../constants/framer';
import { ArrowRight } from '../../assets/images/svg';
import { FormTemplates } from '../../services/api/types/registrations.response.d';

export interface FormTemplateCardProps {
  className?: string;
  template: FormTemplates;
  motionDelay?: number;
  onClick?: () => void;
}

function FormTemplateCard({
  template,
  onClick,
  motionDelay = 0,
  className = '',
}: FormTemplateCardProps) {
  const formTemplateData = FormTemplateData[template];

  return (
    <motion.div
      initial={{ opacity: 0, y: '80px' }}
      animate={{ opacity: 1, y: '0%' }}
      transition={SIMPLE_FRAMER_TRANSITION({
        delay: motionDelay,
        duration: 0.5,
      })}
      className={`template-card-component-container ${template} ${className}`}
      onClick={onClick}
    >
      <div className="template-card-component-text-holder">
        <span className="template-card-component-label">
          {formTemplateData.label}
        </span>
        <span className="template-card-component-title">
          {formTemplateData.title}
        </span>
      </div>
      <div className={`template-card-component-image ${template}`}>
        <img
          src={formTemplateData.image}
          className="template-card-component-image-image"
        />
      </div>
      <button className={`template-card-component-button ${template}`}>
        Start with this template{' '}
        <ArrowRight
          className={`template-card-component-button-arrow ${template}`}
        />
      </button>
      <div className={`template-card-component-overlay ${template}`} />
    </motion.div>
  );
}

export default FormTemplateCard;

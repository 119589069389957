import React from 'react';
import './index.scss';

export interface SimpleSelectProps {
  options: {
    text: string;
    value: string;
  }[];
  placeholder?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  value?: number;
  name: string;
  className?: string;
}

function SimpleSelect({
  options,
  placeholder = 'Select...',
  defaultValue,
  className = '',
  value,
  onChange,
  name,
}: SimpleSelectProps) {
  return (
    <select
      name={name}
      className={`simple-select-component ${className}`}
      placeholder={placeholder}
      onChange={(e) => {
        if (onChange) {
          onChange(e.target.value);
        }
      }}
      defaultValue={defaultValue}
    >
      {Object.values(options).map((option, index) => (
        <option key={`${index}_${name}_${option.value}`} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
}

export default SimpleSelect;

export class ApiBaseService {
  static apiUrl = process.env.REACT_APP_API_URL as string;

  static async handleErrorResponse(response: Response): Promise<Error> {
    const res = await response.json();
    if (typeof res === 'object' && res.message) {
      throw new Error(res.message);
    }
    throw new Error('Please contact support!');
  }
}

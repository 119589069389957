import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { SIMPLE_FRAMER_TRANSITION } from '../../constants/framer';
import './index.scss';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

export interface PopupProps extends React.PropsWithChildren {
  onExit?: () => void;
  active?: boolean;
}

export function Popup({ onExit, children, active = false }: PopupProps) {
  const contentRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  const closePopup = React.useCallback(() => {
    if (onExit) {
      onExit();
    }
  }, [onExit]);

  React.useEffect(() => {
    if (active) {
      setTimeout(() => {
        disablePageScroll(contentRef.current);
      }, 0);
    }

    return () => {
      if (active) {
        setTimeout(() => {
          enablePageScroll(contentRef.current);
        }, 400);
      }
    };
  }, [active]);

  return (
    <AnimatePresence mode="wait">
      {active ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: SIMPLE_FRAMER_TRANSITION({}) }}
          transition={SIMPLE_FRAMER_TRANSITION({ duration: 0.4 })}
          className="popup-component-container"
        >
          <div className="popup-component-background" onClick={closePopup} />
          <motion.div
            initial={{ opacity: 0, y: '100vh' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{
              opacity: 0,
              y: '-100vh',
              transition: SIMPLE_FRAMER_TRANSITION({}),
            }}
            transition={SIMPLE_FRAMER_TRANSITION({ delay: 0.1 })}
            className="popup-component-content"
            ref={contentRef}
          >
            {children}
          </motion.div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

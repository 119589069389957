import { message } from 'antd';
import { ApiBaseService } from './base.api';
import {
  StatsCheckFormIntegratedResponse,
  StatsSummaryResponse,
} from './types/stats.response';

export class ApiStatsService extends ApiBaseService {
  static async checkFormIntegrated(token: string, formId: number) {
    const url = new URL(
      `${ApiBaseService.apiUrl}/api/stats/check-form-integrated?formId=${formId}`,
    );
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      message.error(
        'Someting went wrong! Please contact support: matej@tapform.io',
      );
      return null;
    }
    return (await response.json()) as StatsCheckFormIntegratedResponse;
  }

  static async summary(token: string, formId: number) {
    const url = new URL(
      `${ApiBaseService.apiUrl}/api/stats/summary?formId=${formId}`,
    );
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      message.error(
        'Someting went wrong! Please contact support: matej@tapform.io',
      );
      return null;
    }
    return (await response.json()) as StatsSummaryResponse;
  }
}

import { motion } from 'framer-motion';
import styles from './index.module.scss';
import { useForm } from 'react-hook-form';
import Button from '../../../../../components/Button';
import React from 'react';
import FormInput from '../../../../../components/FormInput';
import { SIMPLE_FRAMER_TRANSITION } from '../../../../../constants/framer';

interface FormValues {
  business: string;
}

interface OnboardingAIBusinessFormProps {
  onSubmit?: (business: string) => void;
}

export default function OnboardingAIBusinessForm({
  onSubmit,
}: OnboardingAIBusinessFormProps) {
  const form = useForm<FormValues>();
  const onSubmitForm = (values: FormValues) => {
    if (onSubmit && values.business?.length) {
      onSubmit(values.business);
    }
  };
  return (
    <motion.div
      className={styles.container}
      initial={{ translateY: '100%', opacity: 0 }}
      animate={{ translateY: '0%', opacity: 1 }}
      exit={{ translateY: '-100%', opacity: 0 }}
      transition={SIMPLE_FRAMER_TRANSITION({})}
    >
      <form className={styles.form} onSubmit={form.handleSubmit(onSubmitForm)}>
        <FormInput
          control={form.control}
          label="Business"
          placeholder="Enter here eg. Roofing, Insurance... "
          className={styles.input}
          name="business"
          type="text"
          rules={{
            required: 'Must not be empty!',
            maxLength: 512,
          }}
          color="ai"
        />
        <Button
          disabled={!form.formState.isValid}
          className={styles.submit}
          text="Next"
          type="submit"
          variant="ai"
        />
      </form>
    </motion.div>
  );
}

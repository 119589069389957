import isValidDomain from 'is-valid-domain';
import validator from 'validator';

export function validateEmail(email: string): boolean {
  return validator.isEmail(email);
}

export function validatePassword(password: string): boolean {
  return validator.isStrongPassword(password, {
    minLowercase: 0,
    minNumbers: 0,
    minSymbols: 0,
    minUppercase: 0,
  });
}

export function validateUrl(url: string): boolean {
  return validator.isURL(url, { require_protocol: true, protocols: ['https'] });
}

export function validateDomain(url: string): boolean {
  return isValidDomain(url);
}

export function validateFirstName(password: string): boolean {
  return !!password.length;
}

export function validateLastName(password: string): boolean {
  return !!password.length;
}

export function validatePhoneNumber(phoneNumber: string): boolean {
  return (
    validator.isMobilePhone(phoneNumber) ||
    validator.isMobilePhone(phoneNumber, 'any', { strictMode: true })
  );
}

export function validateRgbHex(hex: string): boolean {
  return validator.isHexColor(hex);
}
